//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect, useRef} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../Section/AdminHeader';
import {
  insert_news_event,
  get_news_event_one,
  base_url_export,
  update_news_event,
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';
//이미지를 사용하고 싶을때 아래와 같이 이미지 경로를 입력하여 사용
import no_image from '../../Images/Etc/no_image.gif';
//에디터 사용을 위해 @tinymce/tinymce-react사용할 것임
//npm install@tinymce/tinymce-react으로 설치했음
//공식 홈페이지는 https://www.tiny.cloud/
import { Editor } from '@tinymce/tinymce-react';
//editor 데이터를 가져오고 업데이트하고
//사진을 저장하기 위한 Axios 호출 자세한건
//Axios.js 참고
import {
  editor_init_data,
  editor_init_data_mo,
} from "../../Variable";


export function AdminNewsEventManagementAdd() {  
  //파라미터 데이터
  //파라미터 데이터는 AdminAdminNewsEventManagement여기에서
  //체크박스 누르고 수정을 누르면
  //http://localhost:3000/AdminNewsEventManagementAdd?news_event_ID=2
  //이런식으로 주소를 이동하는데 여기서 아래와 같이 쓰면
  //news_event_ID=2를 쓸 수 있다.
  //즉 news_event_ID의 고유값인 2를 쓸 수 있는 것이다.
  const params = new URLSearchParams(window.location.search);
  const news_event_ID_param = params.get("news_event_ID");
  
  // useRef는 React에서 제공하는 hook인데 밑에 HTML을 참고하기 위해 useRef를 사용합니다.
  // editorRef 아래 <Editor 이거를  참고하기 위해 사용했으며
  // <Editor 가면 editorRef를 사용한것을 볼 수 있습니다.  
  const editorRef = useRef(null);

  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //입력 데이터
  const [newsEventTitle, setNewsEventTitle] = useState('');
  const [newsEventSubTitle, setNewsEventSubTitle] = useState('');
  const [newsEventMainImage, setNewsEventMainImage] = useState('');
  const [newsEventStatus, setNewsEventStatus] = useState('1');
  const [htmlString, setHtmlString] = useState("");
  const [initialValue, setInitialValue] = useState("");
  const [htmlStringMobile, setHtmlStringMobile] = useState("");
  const [initialValueMobile, setInitialValueMobile] = useState("");

  //컨트롤 데이터
  const [newsEventMainImageURL, setNewsEventMainImageURL] = useState('');

  //에러 출력 데이터
  const [newsEventTitleError, setNewsEventTitleError] = useState('');
  const [newsEventSubTitleError, setNewsEventSubTitleError] = useState('');
  const [newsEventMainImageError, setNewsEventMainImageError] = useState('');

  //컨트롤 함수
  const news_event_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    if(newsEventTitle === ''){setNewsEventTitleError('메인타이틀을 등록해 주세요.'); continue_add = false;}else{setNewsEventTitleError('');}
    if(newsEventSubTitle === ''){setNewsEventSubTitleError('서브타이틀을 등록해 주세요.'); continue_add = false;}else{setNewsEventSubTitleError('');}
    if(newsEventMainImageURL === ''){setNewsEventMainImageError('뉴스 & 이벤트 메인이미지를 등록해 주세요.'); continue_add = false;}else{setNewsEventMainImageError('');}
    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      //파라미터가 있으면 저장하고
      if(news_event_ID_param === null){
        insert_news_event(
          newsEventTitle,
          newsEventSubTitle,
          newsEventMainImage,
          htmlString.replace( /'/gi, '"'),
          htmlStringMobile.replace( /'/gi, '"'),
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/AdminNewsEventManagement";
        });
      }else{
        //파라미터가 없으면 업데이트 한다.
        update_news_event(
          news_event_ID_param,
          newsEventTitle,
          newsEventSubTitle,
          newsEventMainImage,
          newsEventStatus,
          htmlString.replace( /'/gi, '"'),
          htmlStringMobile.replace( /'/gi, '"'),
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/AdminNewsEventManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해 주세요.');
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //만약에 파라미터를 보내지 않으면 news_event_ID값은 null(빈값으로 오기 떄문에)
    //아래 if조건문에서 null이 아니라면 데이터를 가져올 것이다.
    if(news_event_ID_param !== null){
      //특정 ID의 뉴스 & 이벤트 데이터를 가져오기 위한 함수
      get_news_event_one(news_event_ID_param).then((result) => {
        //데이터를 가져오면 데이터를 집어넣어준다.
        setNewsEventTitle(result[0].news_event_title);
        setNewsEventSubTitle(result[0].news_event_sub_title);
        setInitialValue(result[0].news_event_editor);
        setInitialValueMobile(result[0].news_event_editor_mobile);
        setNewsEventStatus(result[0].news_event_status);
        //여기서 base_url_export는 백엔드 기본주소이며 Axios.js에서
        //export로 선언하여 사용하는 것이다.
        setNewsEventMainImageURL(base_url_export + result[0].news_event_main_image);
      })
    }
  },[news_event_ID_param]);

  return (
    <H.admin_MainWrap>
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>메뉴 관리 - 뉴스 & 이벤트 {news_event_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div>
              <H.admin_addBtn
              //onClick event에 대한 내용은 src/Admin/Login.js 참고
              onClick={()=>{news_event_check();}}>
              {/* 뉴스 & 이벤트 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
              {news_event_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>



        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>

            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='news_event_title'>메인 타이틀<span> * </span></label>
              <input 
                type='text' 
                id = 'news_event_title' 
                maxLength={50} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setNewsEventTitle(event_data.target.value);
                }} 
                placeholder = "메인 타이틀을 입력해 주세요(최대 50자)"
                value={newsEventTitle}
              />
              <H.admin_errorBox className="marginLeft150">{newsEventTitleError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='news_event_sub_title'>서브 타이틀<span> * </span></label>
              <input 
                type='text' 
                id = 'news_event_sub_title' 
                maxLength={70} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setNewsEventSubTitle(event_data.target.value);
                }} 
                placeholder = "서브 타이틀을 입력해 주세요(최대 70자)"
                value={newsEventSubTitle}
              />
              <H.admin_errorBox className="marginLeft150">{newsEventSubTitleError}</H.admin_errorBox>
            </H.admin_labelBox>


            {/* 파라미터에 따라 등록인지 수정인지 판단한다.
            수정일때는 사용 여부를 노출하고 아니면 노출하지 않는다. */}

            {/* 여기 어디서 나오는지 확인해볼것 */}
            {news_event_ID_param !== null &&  
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='news_event_status'>사용 여부</label>
              <select
                value={newsEventStatus}
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setNewsEventStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
              
            </H.admin_labelBox>
            }

            <H.admin_labelImg>
              <p className="labelImgTit">뉴스 & 이벤트 메인 이미지 </p>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>메인 이미지<span> * </span></p>
                    <p className="labelImgInfo">사이즈 630 x 630px</p>
                    <label htmlFor='news_event_main_image'>
                      {/* 뉴스 & 이벤트 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                      {news_event_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'news_event_main_image'
                    //이건 input type='file'일 경우만 가능한건데
                    //accept해서 image의 gif, jpeg, png 확장자만 받겠다는 뜻입니다.                             
                    accept='image/gif, image/jpeg, image/png'
                    //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                    onChange={(event_data)=>{
                      //if문을 사용하게 되는데 if문은 조건이 맞는지 안맞는지에 따라 아래 자바스크립트가 사용이 됩니다.
                      //아래 if문을 해석하면 event_data.target.files[0] !== undefiend 라는 것은
                      //사진파일이 정의되지 되지 않은게 아니라면 아래 자바스크립트를 실행해라 뜻입니다.
                      if(event_data.target.files[0] !== undefined){
                        //setNewsEventMainImage를 통해서 사진파일 원본자체를 useState를 통해서 저장시키고
                        //나중에 백엔드에 사진데이터를 보낼겁니다.
                        setNewsEventMainImage(event_data.target.files[0]);
                        //또 Filereader라는 함수를 통해
                        const reader = new FileReader();
                        //받아온 사진의 URL을 읽어서 
                        reader.readAsDataURL(event_data.target.files[0]);
                        //그리고 onloadend라고 사진파일을 다 읽었다면
                        reader.onloadend = () => {
                          //이미지 URL에 그 결과를 넣으면 실시간으로 사진이 바뀝니다.
                          setNewsEventMainImageURL(reader.result);
                          };
                      }
                    }} 
                  />
                  <img className="admin_newsImg" 
                    src={
                      //이미지URL이 비어있다면 no_image를 가져오는데
                      //no_image는 위에 import해서 가져온 사진입니다.
                      newsEventMainImageURL === '' ?
                      no_image : newsEventMainImageURL
                    } 
                    alt="뉴스 & 이벤트 메인이미지"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{newsEventMainImageError}</H.admin_errorBox>
              </div>
            </H.admin_labelImg>


            <H.listInfoText className="marginTop50">
              <label htmlFor='news_event_editor' className="listInfoTextBold">
                {/* 뉴스 & 이벤트 파라미터가 있는지 없는지에 따라 추가인지 변경인지 판단 */}
                뉴스 & 이벤트 세부내용 사용 방법 안내<span> * </span>
              </label>
              <p>1. 보안 관계로 ''(작은따옴표)는 사용할 수 없습니다.</p>
              <p>2. ''(작은따옴표)를 사용한 경우에는 자동으로 ""(큰따옴표)로 변경됩니다.</p>
              <p>3. 이미지 파일명은 반드시 영문명으로 지정해야 오류가 나지 않습니다.</p>
              <p>4. 서식 적용이 되지 않을 경우 "서식 - 서식지우기" 아이콘을 활용하여 서식을 초기화 한 후 작성해주세요.</p>
              <p>5. 이미지 등록 시 PC는 최대 가로길이 1200px로 맞춰야 이미지 잘림 없이 볼 수 있습니다.</p>
            </H.listInfoText>
            <H.admin_editorBoxTit>
              PC용 에디터
              <span>(스크린 최소 사이즈 기준 1200px)</span>
            </H.admin_editorBoxTit>
            <Editor
              //tiny를 쓰기위한 고유 apiKey 전송
              apiKey='kzj2jdtduql44vtgurqnrj4otlx3bkvkiznzk18tvm41yrcj'
              //처음 실행 시 위 선언했던 useRef를 참고함
              onInit={(evt, editor) => editorRef.current = editor}
              //초기 HTML 적용
              initialValue={initialValue}
              //init은 Varibale.js에서 가져왔으며 폰트추가나 플러그인 툴바 등등 에디터에 관한
              //내용을 추가 시키고 있으면 저기서 추가하면 됨
              init={editor_init_data}
              onEditorChange={(newValue, editor) => {
                setHtmlString(newValue);
              }}
            />
            <H.admin_editorBoxTit>
              모바일용 에디터
              <span>(스크린 최소 사이즈 기준 480px)</span>
            </H.admin_editorBoxTit>
            <Editor
              //tiny를 쓰기위한 고유 apiKey 전송
              apiKey='kzj2jdtduql44vtgurqnrj4otlx3bkvkiznzk18tvm41yrcj'
              //처음 실행 시 위 선언했던 useRef를 참고함
              onInit={(evt, editor) => editorRef.current = editor}
              //초기 HTML 적용
              initialValue={initialValueMobile}
              //init은 Varibale.js에서 가져왔으며 폰트추가나 플러그인 툴바 등등 에디터에 관한
              //내용을 추가 시키고 있으면 저기서 추가하면 됨
              init={editor_init_data_mo}
              onEditorChange={(newValue, editor) => {
                setHtmlStringMobile(newValue);
              }}
            />
        
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>

      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default AdminNewsEventManagementAdd;
