import React, {useEffect, useState, useRef} from 'react';
import AdminHeader from '../Section/AdminHeader';
import { ReactTabulator } from 'react-tabulator';
import * as H from '../../Styles/StyleComponentHan';
import {
  get_customer,
  get_customer_search,
} from "../../Axios";
import detail_icon from '../../Images/Etc/detail_icon.png';
import {
  removeWhitespacePhone,
} from "../../Validation";


export function AdminCustomerManagement() {  

  const [customerData, setCustomerData] = useState([]); 

  //검색 데이터
  const [customerNameSearch, setCustomerNameSearch] = useState('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');

  //테이블 데이터
  const tabulatorRef = useRef();

  //엑셀 다운로드
  const download_excel = () => {
    tabulatorRef.current.download("xlsx", "회원 현황.xlsx", {
      sheetName: "회원 현황"
    });
  }; 

  // 테이블에 사용하는 아이콘
  const detailIcon = function(){
    return `
<img 
  src=${detail_icon} 
  alt="디테일 이미지"
  style="width:15px;heihgt:15px"
/>`
  };

  //컨트롤 함수
  const form_check = () =>{
    get_customer_search(
      customerNameSearch,
      customerPhoneNumber,
    ).then((result)=>{
      if(result.length === 0){
        alert('검색된 데이터가 없습니다.');
      }else{
        setCustomerData(result);
      }
    });
  }

  useEffect(() => {
    get_customer().then(result_data=>{
      setCustomerData(result_data);
    })
  },[]);

  return (
    <H.admin_MainWrap>
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>회원 관리 - 회원 현황</p>
            <div>
              <H.admin_deleteBtn
                //수정 버튼 눌렀을 시 실행되는 함수 위에 정의함
                onClick={()=>{download_excel();}}>엑셀 다운
              </H.admin_deleteBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>

        <H.admin_topSearch>
          <div className="admin_topSearchInner">
            <div className="admin_topSearchLabel">
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='customer_name'>고객명</label>
              <input 
                type='text' 
                id = 'customer_name' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setCustomerNameSearch(event_data.target.value);
                }} 
                placeholder = "고객명을 입력해 주세요."
                value={customerNameSearch}
              />
            </div>
          
            <div className="admin_topSearchLabel">
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='customer_phone_number'>전화번호</label>
              <input 
                type='text' 
                id = 'customer_phone_number' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setCustomerPhoneNumber(removeWhitespacePhone(event_data.target.value));
                }} 
                placeholder = "전화번호를 입력해 주세요."
                value={customerPhoneNumber}
              />
            </div>
            <button
              //onClick event에 대한 내용은 src/Admin/Login.js 참고
              onClick={()=>{form_check(); }}>
              {/* 보증서 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
              검색
            </button>
          </div>
        </H.admin_topSearch>
          
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.listInfoText>
            </H.listInfoText>
            {
            customerData.length !== 0 ? 
            <ReactTabulator
              onRef={(r) => (tabulatorRef.current = r.current)}
              data={customerData}
              columns={[
                {formatter:"rowSelection", titleFormatter:"rowSelection", headerSort:false, 
                  cellClick:function(e, cell){
                    cell.getRow().toggleSelect();
                  }
                },
                { title: "상세보기", formatter:detailIcon, hozAlign:"center", headerSort:false,
                cellClick:function(e, cell){
                  window.location.href = `/AdminCustomerManagementAdd?customer_ID=${cell.getRow().getData().customer_ID}`;
                }},
                { title: "ID", field: "customer_ID", headerSort:false},
                { title: "가입구분", field: "customer_division_name", headerSort:false},
                { title: "고객명", field: "customer_name", headerSort:false},
                { title: "고객 전화번호", field: "customer_phone_number", headerSort:false},
                { title: "고객 이메일", field: "customer_email", headerSort:false},
                { title: "성별", field: "customer_gender_name", headerSort:false},
                { title: "등록 일자", field: "customer_registe", headerSort:false},
                { title: "수정 일자", field: "customer_modified", headerSort:false},
              ]}
              options={{ 
                paginationSize:30,
                pagination:'local',
                paginationButtonCount:5, // 밑에 표시될 페이지 갯수
              }}
              layout={"fitData"}
              />
            : 
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            }
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>

      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

export default AdminCustomerManagement;
