import React from "react";
import * as Styles from '../Styles/StyleComponentRyu';
import { SpinnerInfinity } from 'spinners-react';

function Spinner(probs) {
  return (
    probs.status === true ? (
    <Styles.SpinnerBackgroundDiv>
      <Styles.SpinnerDiv>
        <SpinnerInfinity 
          enabled={true} 
          size="200"
          thickness="100"
          color="rgba(0,0,0,1)"
          speed="50"
        />
      </Styles.SpinnerDiv>
    </Styles.SpinnerBackgroundDiv>
    ):(
      <></>
    )

  );
}

export default Spinner;
