

import React, {useState, useEffect, useCallback} from 'react';
import headerMainLogo from '../../Images/pt_logo_phone.png';
import sideMenuMore from '../../Images/Etc/sideMenu_more.png';
import sideMenuMoreClose from '../../Images/Etc/sideMenu_moreClose.png';
import * as H from '../../Styles/StyleComponentHan';
import {
  get_session_data_homepage,
  delete_session_data_homepage
} from "../../Axios";

export function Header() {  

  //메뉴상태에 따라 보여질지 말지를 판단하기 위해 사용
  const [menuStatus, setMenuStatus] = useState(false);
  const [serviceMenuStatus, setServiceMenuStatus] = useState(false);
  const [funeralMenuStatus, setFuneralMenuStatus] = useState(false);
  const [ptMenuStatus, setPtMenuStatus] = useState(false);
  const [customerID, setCustomerID] = useState('');

  useEffect(() => {
    if(menuStatus) {
      lockScroll();
    } else {
      openScroll();
    }
    get_session_data_homepage().then((result)=>{
      if(result.customer_ID !== '' || result.customer_ID !== undefined){
        setCustomerID(result.customer_ID);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuStatus])

  // 헤더 팝업시 body 스크롤 막기
  const lockScroll = useCallback(() => {
    document.body.addEventListener('touchmove', touchScroll, {passive : false}); 
    document.body.style.overflowY = 'hidden';
  }, []);
  const openScroll = useCallback(() => {
    document.body.removeEventListener('touchmove', touchScroll, {passive : false});
    document.body.style.overflowY = 'scroll';
  }, []);

  const touchScroll = e => e.preventDefault();

  return (
    //여기는 헤더입니다. 일단 크게 div로 잡아났는데
    //볼보에서는 좌측메뉴에서 숨기고 나오고 작업으로 헤더작업을 했는데
    //과장님이 판단하셔서 프론트 작업 부탁합니다~~
    //참고로 export를 선언하면 다른 파일에서 사용이 가능합니다.
    //이게 파일하나하나를 리엑트에서는 컴포넌트라고 하는데
    //즉 다른 컴포넌터에서 헤더파일을 import해서 가져올 수 있습니다.
    //즉 헤더는 모든 메뉴에 들어가기 떄문에 Header라는 명칭으로 컴포넌트를 하나 생성했고
    //다른데에서 import해서 가져와서 쓸겁니다.
    //자세한건 src/Homepage/Section/Main.js를 참고하세요.
  
    <H.headerDiv>
      <H.headerInner>
        <H.headerMainLogo>
          <a href='/'>
            <img src={headerMainLogo} alt="PT상조로고" />
          </a>
        </H.headerMainLogo>
        <button 
          /* 메뉴클릭 시 보여줄지 말지 판단 */
          onClick={()=>{setMenuStatus(!menuStatus)}}
        >메뉴</button>
        {/* 메뉴 버튼 클릭 후 사이드메뉴 css에서 display:none; 처리해둠 */}
        <div 
            //메뉴상태에 따라 메뉴를 보여줄지 말지를 판단
            className={menuStatus === true ? 'blackScream': ' visibility_hidden'}
            //클릭 시 메뉴상태를 역으로 변경
          >
        </div>
        <div 
          //메뉴상태에 따라 메뉴를 보여줄지 말지를 판단
          className={menuStatus === true ? 'sideMenu': 'sideMenuOpen'}
          //클릭 시 메뉴상태를 역으로 변경
        >
          <div className={menuStatus === true ? 'sideMenuInner': 'display_none'}>
            <div className='sideMenuTop'>
              <a href='/'>
                <img src={headerMainLogo} alt="PT상조로고" />
              </a>
              <button
                /* 닫기 클릭 시 보여줄지 말지 판단 */
                onClick={()=>{setMenuStatus(!menuStatus)}}
              >닫기</button>
            </div>

            <H.gnbMenu>

              <div>
                <div className="sideMenuFlex" onClick={()=>{setServiceMenuStatus(!serviceMenuStatus)}}>
                  <p>서비스</p>
                  <img src={serviceMenuStatus ? sideMenuMoreClose:sideMenuMore} alt="열기닫기"></img>
                </div>
                {/* 서비스메뉴상태에 따라 서비스메뉴를 보여줄지 말지를 판단 */}
                <div className={serviceMenuStatus === true ? 'sideMenuSub': 'display_none'}>
                  <a href='/OurStory'>우리들 이야기</a>
                  <a href='/ServiceIntroduce'>서비스 소개</a>
                </div>
              </div>

              <div>
                <div className="sideMenuFlex" onClick={()=>{setFuneralMenuStatus(!funeralMenuStatus)}}>
                  <p>장례정보</p>
                  <img src={funeralMenuStatus ? sideMenuMoreClose:sideMenuMore} alt="열기닫기"></img>
                </div>
                {/* 서비스메뉴상태에 따라 서비스메뉴를 보여줄지 말지를 판단 */}
                <div className={funeralMenuStatus === true ? 'sideMenuSub': 'display_none'}>
                  <a href='/FuneralEasy'>장례식 이제는 PT 받는 시대</a>
                  <a href='/FuneralService'>상조비교</a>
                  <a href='/FuneralDom'>장례진행 절차</a>
                  <a href='/OurDo'>우리가 하는 일</a>
                  {/* <a href='/FuneralAfter'>장례후 행정 절차</a> */}
                </div>
              </div>

              <div>
                <a href='/Estimate'>견적내기</a>
              </div>

              <div>
                <div className="sideMenuFlex" onClick={()=>{setPtMenuStatus(!ptMenuStatus)}}>
                  <p>Why PT</p>
                  <img src={ptMenuStatus ? sideMenuMoreClose:sideMenuMore} alt="열기닫기"></img>
                </div>
                {/* 서비스메뉴상태에 따라 서비스메뉴를 보여줄지 말지를 판단 */}
                <div className={ptMenuStatus === true ? 'sideMenuSub': 'display_none'}>
                  <a href='/WhyPtLife'>Why? PT상조</a>
                  <a href='/Continue'>지속가능성과 차별점</a>
                </div>
              </div>

              <div>
                <a href='/NewsEvent'>뉴스 & 이벤트</a>
              </div>


              <div className="gnbMenuJoin">
                {(customerID === '' || customerID === undefined) && 
                <>
                <a href='/Join'>회원가입</a>
                <a href='/Login'>로그인</a>
                </>
                }
                {(customerID !== '' && customerID !== undefined) && 
                <a
                  href="/"
                  onClick={()=>{
                    delete_session_data_homepage();
                    setCustomerID('');
                  }}
                >
                  로그아웃
                </a>
                }
              </div>

              <div className="gnbMenuSns">
                <a href="/" target="blank" title="facebook">
                  <img width="24" height="24" src="https://www.volvocars.com/static/shared/icons/v2/thirdparty-facebook-24.svg#light-primary" role="presentation" alt="" className="_SN-cc _SN-cf _SN-cg _SN-ch"/>
                </a>   
                <a href="/" target="blank" title="instagram">
                  <img width="24" height="24" src="https://www.volvocars.com/static/shared/icons/v2/thirdparty-instagram-24.svg#light-primary" role="presentation" alt="" className="_SN-cc _SN-cf _SN-cg _SN-ch"/>
                </a> 
                <a href="/" target="blank" title="youtube">
                  <img width="24" height="24" src="https://www.volvocars.com/static/shared/icons/v2/thirdparty-youtube-24.svg#light-primary" role="presentation" alt="" className="_SN-cc _SN-cf _SN-cg _SN-ch"/>
                </a>               
              </div>
            </H.gnbMenu>

            
          </div>
        </div>
      </H.headerInner>
    </H.headerDiv>

  );
}

export default Header;
