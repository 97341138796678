import React, {useState, useEffect} from 'react';
import AdminHeader from '../Section/AdminHeader';
import {
  insert_urn,
  get_urn_one,
  update_urn,
  base_url_export,
} from "../../Axios";
import {validateNum} from "../../Validation";
import * as H from '../../Styles/StyleComponentHan';
import no_image from '../../Images/Etc/no_image.gif';

export function AdminUrnManagementAdd() {  
  const params = new URLSearchParams(window.location.search);
  const urn_ID_param = params.get("urn_ID");

  const [urnName, setUrnName] = useState('');
  const [urnDivision, setUrnDivision] = useState('1');
  const [urnStatus, setUrnStatus] = useState('1');
  const [urnPrice, setUrnPrice] = useState('');
  const [urnImage, setUrnImage] = useState('');
  const [urnImageURL, setUrnImageURL] = useState('');
  const [urnImageDetail, setUrnImageDetail] = useState('');
  const [urnImageDetailURL, setUrnImageDetailURL] = useState('');
  const [urnImageDetailMobile, setUrnImageDetailMobile] = useState('');
  const [urnImageDetailMobileURL, setUrnImageDetailMobileURL] = useState('');
  const [urnImageError, setUrnImageError] = useState('');
  const [urnImageDetailError, setUrnImageDetailError] = useState('');
  const [urnImageDetailMobileError, setUrnImageDetailMobileError] = useState('');
  const [urnNameError, setUrnNameError] = useState('');
  const [urnPriceError, setUrnPriceError] = useState('');


  const urn_check = () =>{
    let continue_add = true;
    if(urnName === ''){setUrnNameError('유골함 명칭을 입력해 주세요.'); continue_add = false;}else{setUrnNameError('');}
    if(urnPrice === ''){setUrnPriceError('유골함 가격을 입력해 주세요.'); continue_add = false;}else{setUrnPriceError('');}
    if(urnImageURL === ''){setUrnImageError('유골함 이미지를 등록해 주세요.'); continue_add = false;}else{setUrnImageError('');}
    if(urnImageDetailURL === ''){setUrnImageDetailError('유골함 상세이미지(PC)를 등록해 주세요.'); continue_add = false;}else{setUrnImageDetailError('');}
    if(urnImageDetailMobileURL === ''){setUrnImageDetailMobileError('유골함 상세이미지(모바일)를 등록해 주세요.'); continue_add = false;}else{setUrnImageDetailMobileError('');}

    if(continue_add){
      if(urn_ID_param === null){
        insert_urn(
          urnName,
          urnDivision,
          urnPrice.replace(/,/g, ""),
          urnImage,
          urnImageDetail,
          urnImageDetailMobile
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/AdminUrnManagement";
        });
      }else{
        update_urn(
          urn_ID_param,
          urnName,
          urnDivision,
          urnPrice.replace(/,/g, ""),
          urnImage,
          urnImageDetail,
          urnImageDetailMobile,
          urnStatus,
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/AdminUrnManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  useEffect(() => {
    if(urn_ID_param !== null){
      get_urn_one(urn_ID_param).then((result) => {
        setUrnName(result[0].urn_name);
        setUrnDivision(result[0].urn_division);
        setUrnStatus(result[0].urn_status);
        setUrnPrice(result[0].urn_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        setUrnImageURL(base_url_export + result[0].urn_image);
        setUrnImageDetailURL(base_url_export + result[0].urn_image_detail);
        setUrnImageDetailMobileURL(base_url_export + result[0].urn_image_detail_mobile);
      })
    }
  },[urn_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>견적 메뉴 관리 - 유골함 {urn_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> 
              <H.admin_addBtn
                onClick={()=>{
                  urn_check();
                }}
              >
                {urn_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>

          <H.admin_labelBox>
              <label htmlFor='urn_division'>유골함 구분<span> * </span></label>
              <select
                value={urnDivision}
                onChange={(event_data)=>{
                  setUrnDivision(event_data.target.value);
                }}
              >
                <option value='1'>기능성 진공함</option>
                <option value='2'>일반함</option>
              </select>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='urn_name'>유골함 명칭<span> * </span></label>
              <input 
                type='text' 
                id = 'urn_name' 
                maxLength={10} 
                onChange={(event_data)=>{
                  setUrnName(event_data.target.value);
                }} 
                placeholder = "유골함 명칭을 입력해 주세요.(최대 10자)"
                value={urnName}
              />
              <H.admin_errorBox className="marginLeft150">{urnNameError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='urn_price'>유골함 가격<span> * </span></label>
              <input 
                type='text' 
                id = 'urn_price' 
                maxLength={10} 
                onChange={(e)=>{
                  if(!validateNum(e.target.value.replace(/,/g, ""))){
                    setUrnPrice(
                      e.target.value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  }} 
                }
                placeholder = "유골함 가격을 입력해 주세요."
                value={urnPrice}
              />
              <H.admin_errorBox className="marginLeft150">{urnPriceError}</H.admin_errorBox>
            </H.admin_labelBox>

            {urn_ID_param !== null &&  
            <H.admin_labelBox>
              <label htmlFor='urn_status'>사용 여부</label>
              <select
                value={urnStatus}
                onChange={(event_data)=>{
                  setUrnStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
            </H.admin_labelBox>
            }        

            <H.admin_labelImg>
              <p className="labelImgTit">유골함 이미지</p>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>이미지<span> * </span></p>
                    <p className="labelImgInfo">사이즈 630 x 630px</p>
                    <label htmlFor='urn_image'>
                      {urn_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'urn_image'                         
                    accept='image/gif, image/jpeg, image/png'
                    onChange={(event_data)=>{
                      if(event_data.target.files[0] !== undefined){
                        setUrnImage(event_data.target.files[0]);
                        const reader = new FileReader();
                        reader.readAsDataURL(event_data.target.files[0]);
                        reader.onloadend = () => {
                          setUrnImageURL(reader.result);
                        };
                      }
                    }} 
                  />
                  <img className="admin_newsImg" 
                    src={
                      urnImageURL === '' ?
                      no_image : urnImageURL
                    } 
                    alt="유골함 이미지"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{urnImageError}</H.admin_errorBox>
              </div>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>상세 이미지(PC)<span> * </span></p>
                    <p className="labelImgInfo">사이즈 1200 x 1200px</p>
                    <label htmlFor='urn_image_detail'>
                      {urn_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'urn_image_detail'                         
                    accept='image/gif, image/jpeg, image/png'
                    onChange={(event_data)=>{
                      if(event_data.target.files[0] !== undefined){
                        setUrnImageDetail(event_data.target.files[0]);
                        const reader = new FileReader();
                        reader.readAsDataURL(event_data.target.files[0]);
                        reader.onloadend = () => {
                          setUrnImageDetailURL(reader.result);
                        };
                      }
                    }} 
                  />
                  <img className="admin_estimateDetailPc" 
                    src={
                      urnImageDetailURL === '' ?
                      no_image : urnImageDetailURL
                    } 
                    alt="유골함 상세이미지(PC)"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{urnImageDetailError}</H.admin_errorBox>
              </div>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>상세 이미지(모바일)<span> * </span></p>
                    <p className="labelImgInfo">사이즈 900 x 1500px</p>
                    <label htmlFor='urn_image_detail_mobile'>
                      {urn_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'urn_image_detail_mobile'                         
                    accept='image/gif, image/jpeg, image/png'
                    onChange={(event_data)=>{
                      if(event_data.target.files[0] !== undefined){
                        setUrnImageDetailMobile(event_data.target.files[0]);
                        const reader = new FileReader();
                        reader.readAsDataURL(event_data.target.files[0]);
                        reader.onloadend = () => {
                          setUrnImageDetailMobileURL(reader.result);
                        };
                      }
                    }} 
                  />
                  <img className="admin_estimateDetailMobile" 
                    src={
                      urnImageDetailMobileURL === '' ?
                      no_image : urnImageDetailMobileURL
                    } 
                    alt="유골함 상세이미지(모바일)"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{urnImageDetailMobileError}</H.admin_errorBox>
              </div>
            </H.admin_labelImg>

          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default AdminUrnManagementAdd;
