import React, {useState, useEffect} from 'react';
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import {
  get_customer_login_ID_check,
  insert_customer,
  update_customer,
} from "../../Axios";
import {privacyTerm, marketingTerm, serviceAgreementTerm} from "../../Terms";
import * as H from '../../Styles/StyleComponentHan';
import {
  removeWhitespace,
  removeWhitespacePhone,
  validatePhone,
  validateEngNum,
  validatePassword,
  validateEmail
} from '../../Validation';
import DaumPostcode from 'react-daum-postcode';

export function Join() {  
  const params = new URLSearchParams(window.location.search);
  const customer_ID_param = params.get("customer_ID");

  //입력 데이터
  const [customerLoginID, setCustomerLoginID] = useState('');
  const [customerPassword, setCustomerPassword] = useState('');
  const [customerPasswordCheck, setCustomerPasswordCheck] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerGender, setCustomerGender] = useState(1);
  const [customerZipCode, setCustomerZipCode] = useState(''); //우편번호
  const [customerRoadAddress, setCustomerRoadAddress] = useState(''); //집 주소
  const [customerDetailAddress, setCustomerDetailAddress] = useState(''); //상세 집주소
  const [customerBigArea, setCustomerBigArea] = useState(''); //
  const [customerSmallArea, setCustomerSmallArea] = useState('');
  const [customerPrivacyStatus, setCustomerPrivacyStatus] = useState(false); // 개인정보 동의
  const [customerMarketingStatus, setCustomerMarketingStatus] = useState(false); // 마케팅 이용 동의
  const [customerServiceAgreementStatus, setCustomerServiceAgreementStatus] = useState(false); // 마케팅 이용 동의

  //에러 출력 데이터
  const [customerLoginIDError, setCustomerLoginIDError] = useState('');
  const [customerPasswordError, setCustomerPasswordError] = useState('');
  const [customerPasswordCheckError, setCustomerPasswordCheckError] = useState('');
  const [customerNameError, setCustomerNameError] = useState('');
  const [customerPhoneNumberError, setCustomerPhoneNumberError] = useState('');
  const [customerEmailError, setCustomerEmailError] = useState('');
  const [customerZipCodeError, setCustomerZipCodeError] = useState('');
  const [customerPrivacyStatusError, setCustomerPrivacyStatusError] = useState(''); //
  const [customerServiceAgreementStatusError, setCustomerServiceAgreementStatusError] = useState(''); //

  // 상태 데이터
  const [viewStatus, setViewStatus] = useState(true); //

  //Address Controll
  const [openPostcode, setOpenPostcode] = useState(false);

  //다음 주소 활용
  const addressHandle = {
    // 버튼 클릭 이벤트
    clickButton: () => {
      setOpenPostcode(current => !current);
    },

    // 주소 선택 이벤트
    selectAddress: (data) => {
      setCustomerZipCode(data.zonecode);
      setCustomerRoadAddress(data.roadAddress === '' ? data.autoRoadAddress : data.roadAddress);
      setCustomerDetailAddress(data.buildingName);
      setCustomerBigArea(data.sido);
      setCustomerSmallArea(data.sigungu);
      setCustomerZipCodeError('');
      setOpenPostcode(false);
    },
  }

  const form_check = () =>{
    let continue_add = true;
    if(customer_ID_param === null){
      if(customerLoginID === ''){
        setCustomerLoginIDError('아이디를 필수로 입력하세요.');
        continue_add = false;
      }
      if(customerPassword === ''){
        setCustomerPasswordError('비밀번호를 필수로 입력하세요.');
        continue_add = false;
      }
      if(customerPasswordCheck === ''){
        setCustomerPasswordCheckError('비밀번호 확인을 필수로 입력하세요.');
        continue_add = false;
      }
      if(customerName === ''){
        setCustomerNameError('고객명을 필수로 입력하세요.');
        continue_add = false;
      }
    }
    if(customerPhoneNumber === ''){
      setCustomerPhoneNumberError('고객 전화번호를 필수로 입력하세요.');
      continue_add = false;
    }
    if(customerPrivacyStatus === false){
      setCustomerPrivacyStatusError('개인정보 수집동의를 필수로 체크하세요.');
      continue_add = false;
    }
    if(customerServiceAgreementStatus === false){
      setCustomerServiceAgreementStatusError('서비스 이용동의를 필수로 체크하세요.');
      continue_add = false;
    }
    if(
      (
        (customer_ID_param === null ? customerLoginIDError === '' : true) &&
        (customer_ID_param === null ? customerPasswordError === '' : true) &&
        (customer_ID_param === null ? customerPasswordCheckError === '' : true) &&
        (customer_ID_param === null ? customerNameError === '' : true) &&
        (customer_ID_param === null ? customerEmailError === '' : true) &&
        customerPhoneNumberError === '' && 
        customerPrivacyStatusError === '' && 
        customerServiceAgreementStatusError === ''
      ) && continue_add
    ){
      if(customer_ID_param === null){
        insert_customer(
          customerLoginID,
          customerPassword,
          customerName,
          customerPhoneNumber,
          customerEmail,
          customerGender,
          customerZipCode,
          customerRoadAddress,
          customerDetailAddress,
          customerBigArea,
          customerSmallArea,
          customerPrivacyStatus,
          customerMarketingStatus,
          customerServiceAgreementStatus,
        ).then(()=>{
          alert('등록이 완료되었습니다. 로그인화면에서 로그인을 해주세요.');
          window.location.href = "/Login";
        });
      }else{
        update_customer(
          customer_ID_param,
          customerPhoneNumber,
          customerEmail,
          customerGender,
          customerZipCode,
          customerRoadAddress,
          customerDetailAddress,
          customerBigArea,
          customerSmallArea,
          customerPrivacyStatus,
          customerMarketingStatus,
          customerServiceAgreementStatus,
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = '/';
        });
      }
    }else{
      alert('입력값을 확인해주세요.');      
    }
  }

  useEffect(() => {
    if(customer_ID_param !== null){
      setViewStatus(false);
    };
  },[customer_ID_param]);

  return (
    <> 
      <Header/>

      <H.main_sectionBox>
      <div className="main_sectionBoxInner">
        <H.main_joinTitBox>
          <h1 className="printMainTit">회원가입</h1>
          <p className="printMainSubTit">로그인 화면에서 간편로그인 회원가입도 가능합니다.</p>
        </H.main_joinTitBox>

        <H.main_joinInfoWrap>
          {viewStatus === true && 
          <>
          <H.main_joinInfoAdd>
            <label htmlFor='customer_login_ID'>아이디<span> * </span></label>
            <input 
              type='text' 
              id = 'customer_login_ID' 
              maxLength={50} 
              onChange={(event_data)=>{
                setCustomerLoginID(removeWhitespace(event_data.target.value));
                get_customer_login_ID_check(
                  event_data.target.value
                ).then((result)=>{
                  if(!validateEngNum(event_data.target.value)){
                    setCustomerLoginIDError("영문 + 숫자만 입력해 주세요.");
                  }else if(result === 0){
                    setCustomerLoginIDError('');
                  }else{
                    setCustomerLoginIDError("중복된 아이디가 있습니다.");
                  }
                })
              }}
              onBlur={(event_data)=>{
                if(event_data.target.value.length < 5){
                  setCustomerLoginIDError("최소 5자 이상의 아이디를 입력해주세요.");
                }
              }}
              placeholder = "로그인할 아이디를 입력해주세요."
              value={customerLoginID}
            />
            <H.main_joinInfo_errorBox>{customerLoginIDError}</H.main_joinInfo_errorBox>
          </H.main_joinInfoAdd>

          <H.main_joinInfoAdd>
            <label htmlFor='customer_password'>비밀번호<span> * </span></label>
            <input 
              type='password' 
              id = 'customer_password' 
              maxLength={50} 
              onChange={(event_data)=>{
                setCustomerPassword(removeWhitespace(event_data.target.value));
                if(!validatePassword(event_data.target.value)){
                  setCustomerPasswordError("최소 8자리 / 숫자, 특수문자 각 1회 이상");
                }else{
                  setCustomerPasswordError('');
                }
              }}
              placeholder = "비밀번호를 입력해주세요."
              value={customerPassword}
            />
            <H.main_joinInfo_errorBox>{customerPasswordError}</H.main_joinInfo_errorBox>
          </H.main_joinInfoAdd>

          <H.main_joinInfoAdd>
            <label htmlFor='customer_password_check'>비밀번호 확인<span> * </span></label>
            <input 
              type='password' 
              id = 'customer_password_check' 
              maxLength={50} 
              onChange={(event_data)=>{
                setCustomerPasswordCheck(removeWhitespace(event_data.target.value));    
                if(event_data.target.value !== customerPassword){
                  setCustomerPasswordCheckError("비밀번호가 일치하지 않습니다.");
                }else{
                  setCustomerPasswordCheckError('');
                }
              }}
              placeholder = "위 비밀번호와 동일하게 입력해주세요."
              value={customerPasswordCheck}
            />
            <H.main_joinInfo_errorBox>{customerPasswordCheckError}</H.main_joinInfo_errorBox>
          </H.main_joinInfoAdd>

          <H.main_joinInfoAdd>
            <label htmlFor='customer_name'>이름<span> * </span></label>
            <input 
              type='text' 
              id = 'customer_name' 
              maxLength={50} 
              onChange={(event_data)=>{
                setCustomerName(event_data.target.value);
                setCustomerNameError('');
              }}
              placeholder = "이름을 입력해주세요."
              value={customerName}
            />
            <H.main_joinInfo_errorBox>{customerNameError}</H.main_joinInfo_errorBox>
          </H.main_joinInfoAdd>
          </>
          }

          <H.main_joinInfoAdd>
            <label htmlFor='customer_phone_number'>연락처<span> * </span> </label>
            <input 
              type='text' 
              id = 'customer_phone_number' 
              maxLength={13} 
              onChange={(event_data)=>{
                setCustomerPhoneNumber(removeWhitespacePhone(event_data.target.value));
                if(!validatePhone(removeWhitespacePhone(event_data.target.value))){
                  setCustomerPhoneNumberError('휴대폰번호 형식으로 입력해주세요.');
                }else{
                  setCustomerPhoneNumberError('');
                }
              }}
              placeholder = "연락처를 입력해 주세요."
              value={customerPhoneNumber}
            />
            <H.main_joinInfo_errorBox>{customerPhoneNumberError}</H.main_joinInfo_errorBox>
          </H.main_joinInfoAdd>

          {viewStatus === true && 
          <H.main_joinInfoAdd>
            <label htmlFor='customer_email'>이메일</label>
            <input 
              type='text' 
              id = 'customer_email' 
              maxLength={50} 
              onChange={(event_data)=>{
                setCustomerEmail(removeWhitespace(event_data.target.value));
                if(!validateEmail(event_data.target.value)){
                  setCustomerEmailError('이메일 형식으로 만들어주세요.');
                }else{
                  setCustomerEmailError('');
                }
              }}
              placeholder = "이메일을 입력해 주세요."
              value={customerEmail}
            />
            <H.main_joinInfo_errorBox>{customerEmailError}</H.main_joinInfo_errorBox>
          </H.main_joinInfoAdd>
          }

          <H.main_joinInfoAdd>
            <label htmlFor='customer_gender'>성별<span> * </span> </label>
            <select
              id='customer_gender'
              value={customerGender}
              onChange={(event_data)=>{
                setCustomerGender(event_data.target.value);
              }}
            >
              <option value='1'>남</option>
              <option value='2'>여</option>
            </select>
          </H.main_joinInfoAdd>

          <H.main_joinInfoAdd>
            <label htmlFor = "customer_zip_code">주소</label>
              <input className="main_joinInfoAdd_zipCode" type='text' id = "customer_zip_code" name='customer_zip_code' placeholder = "주소검색" maxLength={5} readOnly value={customerZipCode} onClick={addressHandle.clickButton} />
              <input className="main_joinInfoAdd_customerAddress" type='text' id = "customer_road_address" name='customer_road_address' placeholder = "클릭 시 주소를 검색할 수 있습니다." maxLength={150} readOnly value={customerRoadAddress} onClick={addressHandle.clickButton} />
              {openPostcode && 
                <DaumPostcode 
                  onComplete={addressHandle.selectAddress}  // 값을 선택할 경우 실행되는 이벤트
                  autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                />
              }
              <input className="main_joinInfoAdd_customerAddress" type='text' id = "customer_detail_address" name='customer_detail_address' placeholder = "상세주소를 입력해 주세요." maxLength={150} onChange={(e)=>{setCustomerDetailAddress(e.target.value)}} value={customerDetailAddress}/>
            <H.main_joinInfo_errorBox>{customerZipCodeError}</H.main_joinInfo_errorBox>
          </H.main_joinInfoAdd>

          <H.main_agreementWrap>
            <H.main_agreementBox isAgree={true}>
              <div>
                <input type='checkbox' id = "customer_privacy_status" name='customer_privacy_status' 
                  onChange={(e)=>{
                    setCustomerPrivacyStatus(e.target.checked);
                    setCustomerPrivacyStatusError('');
                  }} 
                />
                <label htmlFor = "customer_privacy_status"><span> [*필수]</span> 개인정보 수집동의</label>
              </div> 
              <textarea value = {privacyTerm} readOnly></textarea>
              <H.main_joinInfo_errorBox>{customerPrivacyStatusError}</H.main_joinInfo_errorBox>
            </H.main_agreementBox>

            <H.main_agreementBox isAgree={true}>
              <div>
                <input type='checkbox' id = "customer_service_agreement_status" name='customer_service_agreement_status' 
                  onChange={(e)=>{
                    setCustomerServiceAgreementStatus(e.target.checked);
                    setCustomerServiceAgreementStatusError('');
                  }} 
                />
                <label htmlFor = "customer_service_agreement_status"> <span> [*필수]</span> 서비스 이용동의</label> 
              </div>
              <textarea value = {serviceAgreementTerm} readOnly></textarea>
              <H.main_joinInfo_errorBox>{customerServiceAgreementStatusError}</H.main_joinInfo_errorBox>
            </H.main_agreementBox>

            <H.main_agreementBox isAgree={true}>
              <div>
                <input type='checkbox' id = "customer_marketing_status" name='customer_marketing_status' 
                onChange={(e)=>{setCustomerMarketingStatus(e.target.checked)}} />
                <label htmlFor = "customer_marketing_status"> [선택] 마케팅 이용동의</label>
              </div>
              <textarea value = {marketingTerm} readOnly></textarea>
            </H.main_agreementBox>
          </H.main_agreementWrap>

          <button onClick={()=>{form_check(); }}>회원가입</button>
        </H.main_joinInfoWrap>

        </div>
      </H.main_sectionBox>
      <Footer/>
    </>
  );
}

export default Join;
