//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useState, useEffect} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../Section/AdminHeader';
import {
  insert_user,
  get_user_one,
  update_user,
  get_menu_permission_status_1,
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';

export function UserAdd() {  
  //파라미터 데이터
  //파라미터 데이터는 User여기에서
  //체크박스 누르고 수정을 누르면
  //http://localhost:3000/UserAdd?user_ID=2
  //이런식으로 주소를 이동하는데 여기서 아래와 같이 쓰면
  //user_ID=2를 쓸 수 있다.
  //즉 user_ID의 고유값인 2를 쓸 수 있는 것이다.
  const params = new URLSearchParams(window.location.search);
  const user_ID_param = params.get("user_ID");

  //useState에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고  
  //입력 데이터
  const [userName, setUserName] = useState('');
  const [userStatus, setUserStatus] = useState('1');
  const [menuPermissionID, setMenuPermissionID] = useState('1');
  const [userLoginID, setUserLoginID] = useState('');
  const [userPassword, setUserPassword] = useState('');

  //셀렉트 데이터
  const [menuPermissionSelect, setMenuPermissionSelect] = useState([]);

  //에러 출력 데이터
  const [userNameError, setUserNameError] = useState('');
  const [userLoginIDError, setUserLoginIDError] = useState('');
  const [userPasswordError, setUserPasswordError] = useState('');

  //컨트롤 함수
  const user_check = () =>{
    let continue_add = true;
    //여기는 등록버튼을 눌렀을 시 값을 안넣으면 에러데이터를 뿌려주는 곳입니다.
    //그리고 에러가 발생되면 위에 continue_add를 false로 바꿔서
    if(userName === ''){setUserNameError('사용자명을 등록해 주세요.'); continue_add = false;}else{setUserNameError('');}
    if(userLoginID === ''){setUserLoginIDError('로그인ID를 등록해 주세요.'); continue_add = false;}else{setUserLoginIDError('');}
    if(user_ID_param === null){
      if(userPassword === ''){setUserPasswordError('패스워드를 등록해 주세요.'); continue_add = false;}else{setUserPasswordError('');}

    }
    //아래 false일 경우에는 등록하지 않습니다.
    if(continue_add){
      //파라미터가 있으면 저장하고
      if(user_ID_param === null){
        insert_user(
          menuPermissionID,
          userLoginID,
          userPassword,
          userName,
          userStatus,
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/UserManagement";
        });
      }else{
        //파라미터가 없으면 업데이트 한다.
        update_user(
          user_ID_param,
          menuPermissionID,
          userLoginID,
          userPassword,
          userName,
          userStatus,
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/UserManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //PT상조 종류 중 사용가능 한 데이터만 가져오는 API 자세한 내용은 src/Axios.js 파일 확인
    get_menu_permission_status_1().then((result)=>{
      setMenuPermissionSelect(result);
    });
    //만약에 파라미터를 보내지 않으면 user_ID값은 null(빈값으로 오기 떄문에)
    //아래 if조건문에서 null이 아니라면 데이터를 가져올 것이다.
    if(user_ID_param !== null){
      //특정 ID의 사용자 데이터를 가져오기 위한 함수
      get_user_one(user_ID_param).then((result) => {
        //데이터를 가져오면 데이터를 집어넣어준다.
        setUserName(result[0].user_name);
        setUserStatus(result[0].user_status);
        setMenuPermissionID(result[0].menu_permission_ID);
        setUserLoginID(result[0].user_login_ID);
      });
    }
  },[user_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>시스템 관리 - 사용자 {user_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> {/* 여기 버튼 클래스 다른거 해뒀는데 일단 기본 "등록"으로 해둘것 */}
              <H.admin_addBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{
                  user_check();
                }}
              >
                {/* 사용자 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
                {user_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='user_name'>사용자명<span> * </span></label>
              <input 
                type='text' 
                id = 'user_name' 
                maxLength={100} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setUserName(event_data.target.value);
                }} 
                placeholder = "사용자명을 입력해 주세요."
                value={userName}
              />
              <H.admin_errorBox className="marginLeft150">{userNameError}</H.admin_errorBox>
            </H.admin_labelBox>
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='menu_permission_ID_use_user'>메뉴권한<span> * </span></label>
              <select
                value={menuPermissionID}
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setMenuPermissionID(event_data.target.value);
                }}
              >
                {menuPermissionSelect.map((element, index) => 
                  <option value={element.menu_permission_ID} key={index}>
                    {element.menu_permission_name}
                  </option>
                )}
              </select>
            </H.admin_labelBox>

            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='user_login_ID'>로그인 ID<span> * </span></label>
              <input 
                type='text' 
                id = 'user_login_ID' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setUserLoginID(event_data.target.value);
                }} 
                placeholder = "로그인ID를 입력해 주세요."
                value={userLoginID}
              />
              <H.admin_errorBox className="marginLeft150">{userLoginIDError}</H.admin_errorBox>
            </H.admin_labelBox>
          
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='user_password'>비밀번호 </label>
              <input 
                type='password' 
                id = 'user_password' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setUserPassword(event_data.target.value);
                }} 
                placeholder = "아래 비밀번호 입력 안내 확인 후 비밀번호를 입력해 주세요."
                value={userPassword}
              />
              <H.admin_errorBox className="marginLeft150">{userPasswordError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.listInfoText className="listInfoTextSpace">
              <p className="listInfoTextBold">비밀번호 입력 안내</p>
              <p>1. 수정 시 비밀번호 변경이 필요하다면 변경할 비밀번호를 입력하셔서 수정하시면 됩니다.</p>
              <p>2. 수정 시 비밀번호 변경이 필요하지 않다면 빈칸으로 수정하시면 됩니다.</p>
            </H.listInfoText>

            {/* 파라미터에 따라 등록인지 수정인지 판단한다.
            수정일때는 사용 여부를 노출하고 아니면 노출하지 않는다. */}
            {user_ID_param !== null &&  
            <H.admin_labelBox>
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='user_status'>사용 여부</label>
              <select
                value={userStatus}
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setUserStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
            </H.admin_labelBox>
            }
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default UserAdd;
