import {
  insert_eitor_image,
  base_url_export,
} from "./Axios";

export const editor_init_data = {
  width: 1200,
  height: 640,
  menubar: true,
  language: 'ko_KR',
  font_family_formats: `
  Black Han Sans=Black Han Sans, sans-serif;
  Dokdo=Dokdo, cursive;
  Dongle=Dongle, sans-serif;
  East Sea Dokdo=East Sea Dokdo, cursive;
  Gothic A1=Gothic A1, sans-serif;
  Gowun Batang=Gowun Batang, serif;
  Nanum Brush Script=Nanum Brush Script, cursive;
  Nanum Gothic=Nanum Gothic, sans-serif;
  Nanum Myeongjo=Nanum Myeongjo, serif;
  Nanum Pen Script=Nanum Pen Script, cursive;
  Noto Sans KR=Noto Sans KR, sans-serif;
  Noto Serif KR=Noto Serif KR, serif;
  Sunflower=Sunflower, sans-serif;`,
  plugins: [
    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
    'insertdatetime', 'media', 'table', 'code', 'wordcount'
  ],
  toolbar1: 
    'undo redo bold italic strikethrough underline forecolor backcolor ' +
    'fontfamily fontsize lineheight alignleft aligncenter alignright alignjustify ' +
    'image removeformat',
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  images_upload_handler: async blobInfo => {
    return new Promise((resolve, reject) => {
      insert_eitor_image(blobInfo)
        .then(data => {
          resolve(base_url_export + data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
}


export const editor_init_data_mo = {
  width: 480,
  height: 640,
  menubar: true,
  language: 'ko_KR',
  font_family_formats: `
  Black Han Sans=Black Han Sans, sans-serif;
  Dokdo=Dokdo, cursive;
  Dongle=Dongle, sans-serif;
  East Sea Dokdo=East Sea Dokdo, cursive;
  Gothic A1=Gothic A1, sans-serif;
  Gowun Batang=Gowun Batang, serif;
  Nanum Brush Script=Nanum Brush Script, cursive;
  Nanum Gothic=Nanum Gothic, sans-serif;
  Nanum Myeongjo=Nanum Myeongjo, serif;
  Nanum Pen Script=Nanum Pen Script, cursive;
  Noto Sans KR=Noto Sans KR, sans-serif;
  Noto Serif KR=Noto Serif KR, serif;
  Sunflower=Sunflower, sans-serif;`,
  plugins: [
    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
    'insertdatetime', 'media', 'table', 'code', 'wordcount'
  ],
  toolbar1: 
    'undo redo bold italic strikethrough underline forecolor backcolor ' +
    'fontfamily fontsize lineheight alignleft aligncenter alignright alignjustify ' +
    'image removeformat',
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
  images_upload_handler: async blobInfo => {
    return new Promise((resolve, reject) => {
      insert_eitor_image(blobInfo)
        .then(data => {
          resolve(base_url_export + data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
}