import React, {useState, useEffect} from 'react';
import AdminHeader from '../Section/AdminHeader';
import {
  get_estimate_one
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';

export function AdminMourningClothesManagementAdd() {  
  const params = new URLSearchParams(window.location.search);
  const estimate_ID_param = params.get("estimate_ID");

  const [priceTotal, setPriceTotal] = useState('');
  const [coffinName, setCoffinName] = useState('');
  const [coffinPriceNow, setCoffinPriceNow] = useState('');
  const [shroudName, setChroudName] = useState('');
  const [shroudPriceNow, setShroudPriceNow] = useState('');
  const [vehicleName, setVehicleName] = useState('');
  const [vehiclePriceNow, setVehiclePriceNow] = useState('');
  const [helperNumber, setHelperNumber] = useState('');
  const [helperTime, setHelperTime] = useState('');
  const [helperPrice, setHelperPrice] = useState('');
  const [mourningClothesManNumber, setMourningClothesManNumber] = useState('');
  const [mourningClothesManPrice, setMourningClothesManPrice] = useState('');
  const [mourningClothesWomanNumber, setMourningClothesWomanNumber] = useState('');
  const [mourningClothesWomanPrice, setMourningClothesWomanPrice] = useState('');
  const [estimatePersonnelExpenses, setEstimatePersonnelExpenses] = useState('');
  const [estimateOtherSupplies, setEstimateOtherSupplies] = useState('');

  const [urnName, setUrnName] = useState('');
  const [urnPriceNow, setIrnPriceNow] = useState('');

  useEffect(() => {
    if(estimate_ID_param !== null){
      get_estimate_one(estimate_ID_param).then((result) => {
        setPriceTotal(result[0].price_total);
        setCoffinName(result[0].coffin_name);
        setCoffinPriceNow(result[0].coffin_price_now);
        setChroudName(result[0].shroud_name);
        setShroudPriceNow(result[0].shroud_price_now);
        setVehicleName(result[0].vehicle_name);
        setVehiclePriceNow(result[0].vehicle_price_now);
        setHelperNumber(result[0].helper_number);
        setHelperTime(result[0].helper_time);
        setHelperPrice(result[0].helper_price);
        setMourningClothesManNumber(result[0].mourning_clothes_man_number);
        setMourningClothesManPrice(result[0].mourning_clothes_man_price);
        setMourningClothesWomanNumber(result[0].mourning_clothes_woman_number);
        setMourningClothesWomanPrice(result[0].mourning_clothes_man_price);
        setEstimatePersonnelExpenses(result[0].estimate_personnel_expenses);
        setEstimateOtherSupplies(result[0].estimate_other_supplies);
        setUrnName(result[0].urn_name);
        setIrnPriceNow(result[0].urn_price_now);
      })
    }
  },[estimate_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>견적 메뉴 관리 - 견적 상세보기</p>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>

            <H.admin_labelBox>
              <label htmlFor='price_total'>총합</label>
              <input 
                type='text' 
                id = 'price_total' 
                readOnly
                value={String(priceTotal).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='coffin_name'>관 명칭</label>
              <input 
                type='text' 
                id = 'coffin_name' 
                readOnly
                value={coffinName}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='coffin_price_now'>관 가격</label>
              <input 
                type='text' 
                id = 'coffin_price_now' 
                readOnly
                value={String(coffinPriceNow).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='shroud_name'>수의 명칭</label>
              <input 
                type='text' 
                id = 'shroud_name' 
                readOnly
                value={shroudName}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='shroud_price_now'>수의 가격</label>
              <input 
                type='text' 
                id = 'shroud_price_now' 
                readOnly
                value={String(shroudPriceNow).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='vehicle_name'>차량 명칭</label>
              <input 
                type='text' 
                id = 'vehicle_name' 
                readOnly
                value={vehicleName}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='vehicle_price_now'>차량 가격</label>
              <input 
                type='text' 
                id = 'vehicle_price_now' 
                readOnly
                value={String(vehiclePriceNow).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='helper_number'>도우미 수</label>
              <input 
                type='text' 
                id = 'helper_number' 
                readOnly
                value={String(helperNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='helper_time'>도우미 시간</label>
              <input 
                type='text' 
                id = 'helper_time' 
                readOnly
                value={String(helperTime).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='helper_price'>도우미 가격</label>
              <input 
                type='text' 
                id = 'helper_price' 
                readOnly
                value={String(helperPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='mourning_clothes_man_number'>남자 상복 갯수</label>
              <input 
                type='text' 
                id = 'mourning_clothes_man_number' 
                readOnly
                value={String(mourningClothesManNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='mourning_clothes_man_price'>남자 상복 가격</label>
              <input 
                type='text' 
                id = 'mourning_clothes_man_price' 
                readOnly
                value={String(mourningClothesManPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='mourning_clothes_woman_number'>여자 상복 갯수</label>
              <input 
                type='text' 
                id = 'mourning_clothes_woman_number' 
                readOnly
                value={String(mourningClothesWomanNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='mourning_clothes_man_price'>여자 상복 가격</label>
              <input 
                type='text' 
                id = 'mourning_clothes_man_price' 
                readOnly
                value={mourningClothesWomanPrice}
              />
            </H.admin_labelBox>


            <H.admin_labelBox>
              <label htmlFor='estimate_personnel_expenses'>인건비</label>
              <input 
                type='text' 
                id = 'estimate_personnel_expenses' 
                readOnly
                value={String(estimatePersonnelExpenses).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </H.admin_labelBox>


            <H.admin_labelBox>
              <label htmlFor='estimate_other_supplies'>기타용품</label>
              <input 
                type='text' 
                id = 'estimate_other_supplies' 
                readOnly
                value={String(estimateOtherSupplies).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='urn_name'>유골함 명칭</label>
              <input 
                type='text' 
                id = 'urn_name' 
                readOnly
                value={urnName}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='urn_price_now'>유골함 가격</label>
              <input 
                type='text' 
                id = 'urn_price_now' 
                readOnly
                value={urnPriceNow}
              />
            </H.admin_labelBox>
   
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

export default AdminMourningClothesManagementAdd;
