import React, {useState, useEffect} from 'react';
import AdminHeader from '../Section/AdminHeader';
import {
  get_inquiry_one
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';

export function AdminInquiryManagementAdd() {  
  const params = new URLSearchParams(window.location.search);
  const inquiry_ID_param = params.get("inquiry_ID");

  const [inquiryCustomerName, setInquiryCustomerName] = useState('');
  const [inquiryCustomerPhoneNumber, setInquiryCustomerPhoneNumber] = useState('');
  const [inquiryAddress, setInquiryAddress] = useState('');

  useEffect(() => {
    if(inquiry_ID_param !== null){
      get_inquiry_one(inquiry_ID_param).then((result) => {
        setInquiryCustomerName(result[0].inquiry_customer_name);
        setInquiryCustomerPhoneNumber(result[0].inquiry_customer_phone_number);
        setInquiryAddress(result[0].inquiry_address);
      })
    }
  },[inquiry_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>견적 메뉴 관리 - 전화문의 상세보기</p>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>

            <H.admin_labelBox>
              <label htmlFor='inquiry_customer_name'>이름</label>
              <input 
                type='text' 
                id = 'inquiry_customer_name' 
                readOnly
                value={inquiryCustomerName}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='inquiry_customer_phone_number'>신청 전화번호</label>
              <input 
                type='text' 
                id = 'inquiry_customer_phone_number' 
                readOnly
                value={inquiryCustomerPhoneNumber}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='inquiry_address'>지역</label>
              <input 
                type='text' 
                id = 'inquiry_address' 
                readOnly
                value={inquiryAddress}
              />
            </H.admin_labelBox>
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

export default AdminInquiryManagementAdd;
