//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React from 'react';
import * as H from '../../Styles/StyleComponentHan';

export function Footer() {  

  return (
    <H.footerWrap>
      <div className="footerInner">
        <p>(주)PT상조 / 대표이사 이지훈</p>
        <p>Address 부산광역시 금정구 구서중앙로40번길 51, 1층</p>
        <p>Contact us 010-3595-4849</p>
        <p>Copyright © PT상조. All Rights Reserved.</p>
      </div>
    </H.footerWrap>

  );
}

export default Footer;