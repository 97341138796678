//여기는 프론트의 꽃인 API를 하기위한 Axios.js 파일입니다.
//import Axios from "axios"는 npm install axios를 통해서
//백엔드와 교류할 수 있는 axios 패키지를 설치한 것을 불러온 것 입니다.
//import, export에 대한 자세한 내용은 src/Admin/Login/Login.js에서 확인해주세요.
import Axios from "axios";

//테스트 서버는 자체서버를 활용하기 때문에 localhost이며 운영서버는 해당 주소를 입력한겁니다.
//작업할떄는 테스트서버를 주석을 풀고 운영서버를 주석처리하고 백엔드 키시고 작업하시면 됩니다.

//테스트서버
/* const base_url = "http://localhost:6262/";
export const base_url_export = "http://localhost:6262/";
export const base_homepage_url_export = "http://localhost:3000/"; */

//운영서버
const base_url = "https://www.pt-life.co.kr:6262/";
export const base_url_export = "https://www.pt-life.co.kr:6262/"; 
export const base_homepage_url_export = "https://www.pt-life.co.kr/";


//앞에 src/Admin/Login/Login.js에서 호출한 login_judgement에 대한 처리하는 Function입니다.
//login_judgement Function은 user_login_ID와, user_password의 매개변수로 입력받아
//data라는 FormData 변수를 만든 후 data.append를 하여 백엔드에 보낼 데이터를 집어 넣습니다.
//try{}catch{}는 try안의 내용들을 시도하다가 에러가 뜨면 catch로 이동해서 에러를 출력하게 됩니다.
//async awat는 비동기 통신이라고 하는데 이건 말로 설명하기가 너무 힘들어서 인터넷에 찾아보시면 됩니다.
//설치한 Axios 패키지를 활용하여
//Axios.post = Post 방법으로 백엔드랑 통신한다
//post는 암호화해서 보내는 방법이고 get은 주소창에 www.naver.com/ere?tab1=1&tab2=2
//이렇게 get은 ?뒤에 값들이 보내지는데
//백엔드에서 tab1은 1번이라고 인식해서 사용하고 tab2는 2번이라고 인식해서 사용하는건데
//왠만하면 post로 하는 것이 좋아요 :)
//`${base_url}login/login_judgement` 이부분은 백엔드랑 통신할 주소이며
//그밑에 data는 보낼 data
//headers, withCredentials는 인터넷 찾아보시면 됩니다.
//그리고 나서 return response.data는 받은 데이터를 return 시킨다
//즉return이라고 함은 rc/Admin/Login/Login.js에서 호출한 login_judgement에 
//백엔드에서 받은 값을 보내주는 겁니다.
//Axios는 url주소와 보내줄 데이터를 어떤걸 어디주소로 보낼테니 어떤거를 반환해달라고
//백엔드 개발자와 협조해야 되는 부분입니다. :)
export const login_judgement = async (user_login_ID, user_password) => {
  let data = new FormData();

  data.append('user_login_ID',user_login_ID);
  data.append('user_password',user_password);

  try{
      const response = await Axios.post(
          `${base_url}login/login_judgement`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//이거는 사용자가 로그인을 할때 백엔드 서버에 세션이라는거를 저장하게 되는데 
//세션이 없으면 당연히 로그인을 안한거고 로그인을 안했으면
//관리자페이지를 사용하지 못하게 만들어야 하기 때문에
//백엔드 서버에 세션이 있는지 없는지 판단해달라고 보내는 요청입니다.
export const get_session_data_admin = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}login/get_session_data_admin`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 롤링 배너를 가져오는 API입니다.
export const get_rolling_banner = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_rolling_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 사용여부를 사용으로 
//지정된 롤링 배너를 가져오는 API입니다.
export const get_rolling_banner_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_rolling_banner_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//롤링 배너 순서를 업데이트 시키는 API입니다.
export const update_rolling_banner_order = async (
  rolling_banner_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('rolling_banner_order_array',JSON.stringify(rolling_banner_order_array));
  try{
      const response = await Axios.post(
          `${base_url}main/update_rolling_banner_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//롤링 배너를 등록하는 API입니다.
export const insert_rolling_banner = async (
  rolling_banner_name,
  rolling_banner_image,
  rolling_banner_image_mobile,
  rolling_banner_link,
) => {

  let data = new FormData();
  data.append('rolling_banner_name',rolling_banner_name);
  data.append('rolling_banner_image',rolling_banner_image);
  data.append('rolling_banner_image_mobile',rolling_banner_image_mobile);
  data.append('rolling_banner_link',rolling_banner_link);

  try{
      const response = await Axios.post(
          `${base_url}main/insert_rolling_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//롤링 배너를 수정하는 API입니다.
export const update_rolling_banner = async (
  rolling_banner_ID,
  rolling_banner_name,
  rolling_banner_image,
  rolling_banner_image_mobile,
  rolling_banner_link,
  rolling_banner_status,
) => {

  let data = new FormData();
  data.append('rolling_banner_ID',rolling_banner_ID);
  data.append('rolling_banner_name',rolling_banner_name);
  data.append('rolling_banner_image',rolling_banner_image);
  data.append('rolling_banner_image_mobile',rolling_banner_image_mobile);
  data.append('rolling_banner_link',rolling_banner_link);
  data.append('rolling_banner_status',rolling_banner_status);

  try{
      const response = await Axios.post(
          `${base_url}main/update_rolling_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//롤링 배너를 삭제하는 API입니다.
export const delete_rolling_banner = async (
  rolling_banner_ID_array,
) => {

  let data = new FormData();
  data.append('rolling_banner_ID_array',rolling_banner_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}main/delete_rolling_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 롤링 배너를 가져오기 위한 API입니다.
export const get_rolling_banner_one = async (
  rolling_banner_ID
) => {
  let data = new FormData();
  data.append('rolling_banner_ID',rolling_banner_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_rolling_banner_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//에디터에 대한 이미지를 업로드 합니다.
export const insert_eitor_image = async (
  editor_file
) => {
  let data = new FormData();
  data.append('editor_file',editor_file.blob());

  try{
      const response = await Axios.post(
          `${base_url}editor/insert_eitor_image`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
  
}

//에디터 내용을 가져오는 API입니다.
export const get_editor_data = async (
  editor_division
) => {
  let data = new FormData();
  data.append('editor_division',editor_division);

  try{
      const response = await Axios.post(
          `${base_url}editor/get_editor_data`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//에디터 내용을 수정하는 API입니다.
export const update_editor_data = async (
  editor_division,
  editor_content,
  editor_content_mobile,
) => {
  let data = new FormData();
  data.append('editor_division',editor_division);
  data.append('editor_content',editor_content);
  data.append('editor_content_mobile',editor_content_mobile);

  try{
      const response = await Axios.post(
          `${base_url}editor/update_editor_data`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 뉴스 & 이벤트를 가져오는 API입니다.
export const get_news_event = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}news_event/get_news_event`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 뉴스 & 이벤트 중 사용가능으로 설정한 데이터를 가져오는 API입니다.
export const get_news_event_status = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}news_event/get_news_event_status`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//뉴스 & 이벤트 순서를 업데이트 시키는 API입니다.
export const update_news_event_order = async (
  news_event_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('news_event_order_array',JSON.stringify(news_event_order_array));
  try{
      const response = await Axios.post(
          `${base_url}news_event/update_news_event_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//뉴스 & 이벤트를 등록하는 API입니다.
export const insert_news_event = async (
  news_event_title,
  news_event_sub_title,
  news_event_main_image,
  news_event_editor,
  news_event_editor_mobile,
) => {

  let data = new FormData();
  data.append('news_event_title',news_event_title);
  data.append('news_event_sub_title',news_event_sub_title);
  data.append('news_event_main_image',news_event_main_image);
  data.append('news_event_editor',news_event_editor);
  data.append('news_event_editor_mobile',news_event_editor_mobile);

  try{
      const response = await Axios.post(
          `${base_url}news_event/insert_news_event`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//뉴스 & 이벤트를 수정하는 API입니다.
export const update_news_event = async (
  news_event_ID,
  news_event_title,
  news_event_sub_title,
  news_event_main_image,
  news_event_status,
  news_event_editor,
  news_event_editor_mobile,
) => {

  let data = new FormData();
  data.append('news_event_ID',news_event_ID);
  data.append('news_event_title',news_event_title);
  data.append('news_event_sub_title',news_event_sub_title);
  data.append('news_event_main_image',news_event_main_image);
  data.append('news_event_status',news_event_status);
  data.append('news_event_editor',news_event_editor);
  data.append('news_event_editor_mobile',news_event_editor_mobile);

  try{
      const response = await Axios.post(
          `${base_url}news_event/update_news_event`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//뉴스 & 이벤트를 삭제하는 API입니다.
export const delete_news_event = async (
  news_event_ID_array,

) => {

  let data = new FormData();
  data.append('news_event_ID_array',news_event_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}news_event/delete_news_event`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 뉴스 & 이벤트를 가져오기 위한 API입니다.
export const get_news_event_one = async (
  news_event_ID
) => {
  let data = new FormData();
  data.append('news_event_ID',news_event_ID);
  try{
      const response = await Axios.post(
          `${base_url}news_event/get_news_event_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 PT상조배너를 가져오는 API입니다.
export const get_small_banner = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}main/get_small_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//PT상조배너 순서를 업데이트 시키는 API입니다.
export const update_small_banner_order = async (
  small_banner_order_array,
) => {
  let data = new FormData();
  //여기서 좀 중요한 포인트인데
  //배열 데이터는 통신을 할 수 가 없어요.
  //즉 배열데이터를 문자열로 바꿔야 되는데
  //이때 사용하는 것이 JSON.stringfy인데
  //배열데이터를 문자열로 바꿔주고
  //역으로 백엔드에서는 JSON.parse를 하면
  //문자열의 데이터를 다시 배열로 바꿔주는 거에요
  //자세한건 인터넷 찾아보세요 어렵지 않은 개념입니다 :)
  data.append('small_banner_order_array',JSON.stringify(small_banner_order_array));
  try{
      const response = await Axios.post(
          `${base_url}main/update_small_banner_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//PT상조배너를 등록하는 API입니다.
export const insert_small_banner = async (
  small_banner_name,
  small_banner_image,
  small_banner_link,
) => {

  let data = new FormData();
  data.append('small_banner_name',small_banner_name);
  data.append('small_banner_image',small_banner_image);
  data.append('small_banner_link',small_banner_link);

  try{
      const response = await Axios.post(
          `${base_url}main/insert_small_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//PT상조배너를 수정하는 API입니다.
export const update_small_banner = async (
  small_banner_ID,
  small_banner_name,
  small_banner_image,
  small_banner_link,
) => {

  let data = new FormData();
  data.append('small_banner_ID',small_banner_ID);
  data.append('small_banner_name',small_banner_name);
  data.append('small_banner_image',small_banner_image);
  data.append('small_banner_link',small_banner_link);

  try{
      const response = await Axios.post(
          `${base_url}main/update_small_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//PT상조배너를 삭제하는 API입니다.
export const delete_small_banner = async (
  small_banner_ID_array,
) => {

  let data = new FormData();
  data.append('small_banner_ID_array',small_banner_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}main/delete_small_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 PT상조배너를 가져오기 위한 API입니다.
export const get_small_banner_one = async (
  small_banner_ID
) => {
  let data = new FormData();
  data.append('small_banner_ID',small_banner_ID);
  try{
      const response = await Axios.post(
          `${base_url}main/get_small_banner_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//고정배너 내용을 가져오는 API입니다.
export const get_big_banner = async (
  big_banner_ID
) => {
  let data = new FormData();
  data.append('big_banner_ID',big_banner_ID);

  try{
      const response = await Axios.post(
          `${base_url}main/get_big_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}


//고정배너 내용을 가져오는 API입니다.
export const update_big_banner = async (
  big_banner_ID,
  big_banner_image,
  big_banner_image_mobile,
  big_banner_link,
  big_banner_title,
) => {

  let data = new FormData();
  data.append('big_banner_ID',big_banner_ID);
  data.append('big_banner_image',big_banner_image);
  data.append('big_banner_image_mobile',big_banner_image_mobile);
  data.append('big_banner_link',big_banner_link);
  data.append('big_banner_title',big_banner_title);

  try{
      const response = await Axios.post(
          `${base_url}main/update_big_banner`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 메뉴권한을 가져오는 API입니다.
export const get_menu_permission = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}system/get_menu_permission`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 메뉴권한를 가져오기 위한 API입니다.
export const get_menu_permission_one = async (
  menu_permission_ID
) => {
  let data = new FormData();
  data.append('menu_permission_ID',menu_permission_ID);
  try{
      const response = await Axios.post(
          `${base_url}system/get_menu_permission_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 메뉴권한를 사용으로 
//지정된 메뉴권한을 가져오는 API입니다.
export const get_menu_permission_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}system/get_menu_permission_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//메뉴권한를 등록하는 API입니다.
export const insert_menu_permission = async (
  menu_permission_name,
  menu_permission_status,
  menu_permission_array,
) => {

  let data = new FormData();
  data.append('menu_permission_name',menu_permission_name);
  data.append('menu_permission_status',menu_permission_status);
  data.append('menu_permission_array',menu_permission_array);

  try{
      const response = await Axios.post(
          `${base_url}system/insert_menu_permission`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//메뉴권한를 수정하는 API입니다.
export const update_menu_permission = async (
  menu_permission_ID,
  menu_permission_name,
  menu_permission_status,
  menu_permission_array,
) => {

  let data = new FormData();
  data.append('menu_permission_ID',menu_permission_ID);
  data.append('menu_permission_name',menu_permission_name);
  data.append('menu_permission_status',menu_permission_status);
  data.append('menu_permission_array',menu_permission_array);

  try{
      const response = await Axios.post(
          `${base_url}system/update_menu_permission`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//백엔드 데이터베이스에 저장되어 있는 사용자를 가져오는 API입니다.
export const get_user = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}system/get_user`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 사용자를 가져오기 위한 API입니다.
export const get_user_one = async (
  user_ID
) => {
  let data = new FormData();
  data.append('user_ID',user_ID);
  try{
      const response = await Axios.post(
          `${base_url}system/get_user_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//사용자를 등록하는 API입니다.
export const insert_user = async (
  menu_permission_ID_use_user,
  user_login_ID,
  user_password,
  user_name,
  user_status,
) => {

  let data = new FormData();
  data.append('menu_permission_ID_use_user',menu_permission_ID_use_user);
  data.append('user_login_ID',user_login_ID);
  data.append('user_password',user_password);
  data.append('user_name',user_name);
  data.append('user_status',user_status);

  try{
      const response = await Axios.post(
          `${base_url}system/insert_user`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//사용자를 수정하는 API입니다.
export const update_user = async (
  user_ID,
  menu_permission_ID_use_user,
  user_login_ID,
  user_password,
  user_name,
  user_status,
) => {

  let data = new FormData();
  data.append('user_ID',user_ID);
  data.append('menu_permission_ID_use_user',menu_permission_ID_use_user);
  data.append('user_login_ID',user_login_ID);
  data.append('user_password',user_password);
  data.append('user_name',user_name);
  data.append('user_status',user_status);


  try{
      const response = await Axios.post(
          `${base_url}system/update_user`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_coffin = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_coffin`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_coffin_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_coffin_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_coffin_order = async (
  coffin_order_array,
) => {
  let data = new FormData();
  data.append('coffin_order_array',JSON.stringify(coffin_order_array));
  try{
      const response = await Axios.post(
          `${base_url}estimate/update_coffin_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_coffin = async (
  coffin_name,
  coffin_division,
  coffin_price,
  coffin_image,
  coffin_image_detail,
  coffin_image_detail_mobile,
) => {

  let data = new FormData();
  data.append('coffin_name',coffin_name);
  data.append('coffin_division',coffin_division);
  data.append('coffin_price',coffin_price);
  data.append('coffin_image',coffin_image);
  data.append('coffin_image_detail',coffin_image_detail);
  data.append('coffin_image_detail_mobile',coffin_image_detail_mobile);

  try{
      const response = await Axios.post(
          `${base_url}estimate/insert_coffin`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_coffin = async (
  coffin_ID,
  coffin_name,
  coffin_division,
  coffin_price,
  coffin_image,
  coffin_image_detail,
  coffin_image_detail_mobile,
  coffin_status,
) => {

  let data = new FormData();
  data.append('coffin_ID',coffin_ID);
  data.append('coffin_name',coffin_name);
  data.append('coffin_division',coffin_division);
  data.append('coffin_price',coffin_price);
  data.append('coffin_image',coffin_image);
  data.append('coffin_image_detail',coffin_image_detail);
  data.append('coffin_image_detail_mobile',coffin_image_detail_mobile);
  data.append('coffin_status',coffin_status);

  try{
      const response = await Axios.post(
          `${base_url}estimate/update_coffin`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_coffin_one = async (
  coffin_ID
) => {
  let data = new FormData();
  data.append('coffin_ID',coffin_ID);
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_coffin_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_shroud = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_shroud`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_shroud_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_shroud_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_shroud_order = async (
  shroud_order_array,
) => {
  let data = new FormData();
  data.append('shroud_order_array',JSON.stringify(shroud_order_array));
  try{
      const response = await Axios.post(
          `${base_url}estimate/update_shroud_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_shroud = async (
  shroud_name,
  shroud_price,
  shroud_image,
  shroud_image_detail,
  shroud_image_detail_mobile,
) => {

  let data = new FormData();
  data.append('shroud_name',shroud_name);
  data.append('shroud_price',shroud_price);
  data.append('shroud_image',shroud_image);
  data.append('shroud_image_detail',shroud_image_detail);
  data.append('shroud_image_detail_mobile',shroud_image_detail_mobile);

  try{
      const response = await Axios.post(
          `${base_url}estimate/insert_shroud`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_shroud = async (
  shroud_ID,
  shroud_name,
  shroud_price,
  shroud_image,
  shroud_image_detail,
  shroud_image_detail_mobile,
  shroud_status,
) => {

  let data = new FormData();
  data.append('shroud_ID',shroud_ID);
  data.append('shroud_name',shroud_name);
  data.append('shroud_price',shroud_price);
  data.append('shroud_image',shroud_image);
  data.append('shroud_image_detail',shroud_image_detail);
  data.append('shroud_image_detail_mobile',shroud_image_detail_mobile);
  data.append('shroud_status',shroud_status);

  try{
      const response = await Axios.post(
          `${base_url}estimate/update_shroud`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_shroud_one = async (
  shroud_ID
) => {
  let data = new FormData();
  data.append('shroud_ID',shroud_ID);
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_shroud_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_vehicle = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_vehicle`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_vehicle_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_vehicle_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_vehicle_order = async (
  vehicle_order_array,
) => {
  let data = new FormData();
  data.append('vehicle_order_array',JSON.stringify(vehicle_order_array));
  try{
      const response = await Axios.post(
          `${base_url}estimate/update_vehicle_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_vehicle = async (
  vehicle_name,
  vehicle_explanation,
  vehicle_price,
) => {

  let data = new FormData();
  data.append('vehicle_name',vehicle_name);
  data.append('vehicle_explanation',vehicle_explanation);
  data.append('vehicle_price',vehicle_price);


  try{
      const response = await Axios.post(
          `${base_url}estimate/insert_vehicle`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_vehicle = async (
  vehicle_ID,
  vehicle_name,
  vehicle_explanation,
  vehicle_price,
  vehicle_status,
) => {

  let data = new FormData();
  data.append('vehicle_ID',vehicle_ID);
  data.append('vehicle_name',vehicle_name);
  data.append('vehicle_explanation',vehicle_explanation);
  data.append('vehicle_price',vehicle_price);
  data.append('vehicle_status',vehicle_status);

  try{
      const response = await Axios.post(
          `${base_url}estimate/update_vehicle`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_vehicle_one = async (
  vehicle_ID
) => {
  let data = new FormData();
  data.append('vehicle_ID',vehicle_ID);
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_vehicle_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_mourning_clothes = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_mourning_clothes`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_mourning_clothes_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_mourning_clothes_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_mourning_clothes_order = async (
  mourning_clothes_order_array,
) => {
  let data = new FormData();
  data.append('mourning_clothes_order_array',JSON.stringify(mourning_clothes_order_array));
  try{
      const response = await Axios.post(
          `${base_url}estimate/update_mourning_clothes_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_mourning_clothes = async (
  mourning_clothes_name,
  mourning_clothes_price,
) => {

  let data = new FormData();
  data.append('mourning_clothes_name',mourning_clothes_name);
  data.append('mourning_clothes_price',mourning_clothes_price);


  try{
      const response = await Axios.post(
          `${base_url}estimate/insert_mourning_clothes`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_mourning_clothes = async (
  mourning_clothes_ID,
  mourning_clothes_name,
  mourning_clothes_price,
  mourning_clothes_status,
) => {

  let data = new FormData();
  data.append('mourning_clothes_ID',mourning_clothes_ID);
  data.append('mourning_clothes_name',mourning_clothes_name);
  data.append('mourning_clothes_price',mourning_clothes_price);
  data.append('mourning_clothes_status',mourning_clothes_status);

  try{
      const response = await Axios.post(
          `${base_url}estimate/update_mourning_clothes`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_mourning_clothes_one = async (
  mourning_clothes_ID
) => {
  let data = new FormData();
  data.append('mourning_clothes_ID',mourning_clothes_ID);
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_mourning_clothes_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_urn = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_urn`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_urn_status_1 = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_urn_status_1`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_urn_order = async (
  urn_order_array,
) => {
  let data = new FormData();
  data.append('urn_order_array',JSON.stringify(urn_order_array));
  try{
      const response = await Axios.post(
          `${base_url}estimate/update_urn_order`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_urn = async (
  urn_name,
  urn_division,
  urn_price,
  urn_image,
  urn_image_detail,
  urn_image_detail_mobile,
) => {

  let data = new FormData();
  data.append('urn_name',urn_name);
  data.append('urn_division',urn_division);
  data.append('urn_price',urn_price);
  data.append('urn_image',urn_image);
  data.append('urn_image_detail',urn_image_detail);
  data.append('urn_image_detail_mobile',urn_image_detail_mobile);

  try{
      const response = await Axios.post(
          `${base_url}estimate/insert_urn`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_urn = async (
  urn_ID,
  urn_name,
  urn_division,
  urn_price,
  urn_image,
  urn_image_detail,
  urn_image_detail_mobile,
  urn_status,
) => {

  let data = new FormData();
  data.append('urn_ID',urn_ID);
  data.append('urn_name',urn_name);
  data.append('urn_division',urn_division);
  data.append('urn_price',urn_price);
  data.append('urn_image',urn_image);
  data.append('urn_image_detail',urn_image_detail);
  data.append('urn_image_detail_mobile',urn_image_detail_mobile);
  data.append('urn_status',urn_status);

  try{
      const response = await Axios.post(
          `${base_url}estimate/update_urn`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_urn_one = async (
  urn_ID
) => {
  let data = new FormData();
  data.append('urn_ID',urn_ID);
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_urn_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_estimate = async (
  ) => {
  
  let data = new FormData();

  try{
      const response = await Axios.post(
          `${base_url}estimate/get_estimate`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_estimate_search = async (
  estimate_customer_name,
  estimate_customer_phone_number,
) => {
  
  let data = new FormData();

  data.append('estimate_customer_name',estimate_customer_name);
  data.append('estimate_customer_phone_number',estimate_customer_phone_number);

  try{
      const response = await Axios.post(
          `${base_url}estimate/get_estimate_search`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_estimate_one = async (
  estimate_ID
) => {  
  let data = new FormData();
  data.append('estimate_ID',estimate_ID);

  try{
      const response = await Axios.post(
          `${base_url}estimate/get_estimate_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_estimate_data_all = async (
) => {

  let data = new FormData();

  try{
      const response = await Axios.post(
          `${base_url}estimate/get_estimate_data_all`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_estimate = async (
  customer_ID_use_estimate,
  coffin_ID_use_estimate,
  coffin_price_now,
  shroud_ID_use_estimate,
  shroud_price_now,
  vehicle_ID_use_estimate,
  vehicle_price_now,
  helper_number,
  helper_time,
  helper_price,
  mourning_clothes_man_number,
  mourning_clothes_man_price,
  mourning_clothes_woman_number,
  mourning_clothes_woman_price,
  urn_ID_use_estimate,
  urn_price_now,
  estimate_customer_name,
  estimate_customer_phone_number,
  estimate_personnel_expenses,
  estimate_other_supplies
) => {

  let data = new FormData();
  data.append('customer_ID_use_estimate',customer_ID_use_estimate);
  data.append('coffin_ID_use_estimate',coffin_ID_use_estimate);
  data.append('coffin_price_now',coffin_price_now);
  data.append('shroud_ID_use_estimate',shroud_ID_use_estimate);
  data.append('shroud_price_now',shroud_price_now);
  data.append('vehicle_ID_use_estimate',vehicle_ID_use_estimate);
  data.append('vehicle_price_now',vehicle_price_now);
  data.append('helper_number',helper_number);
  data.append('helper_time',helper_time);
  data.append('helper_price',helper_price);
  data.append('mourning_clothes_man_number',mourning_clothes_man_number);
  data.append('mourning_clothes_man_price',mourning_clothes_man_price);
  data.append('mourning_clothes_woman_number',mourning_clothes_woman_number);
  data.append('mourning_clothes_woman_price',mourning_clothes_woman_price);
  data.append('urn_ID_use_estimate',urn_ID_use_estimate);
  data.append('urn_price_now',urn_price_now);
  data.append('estimate_customer_name',estimate_customer_name);
  data.append('estimate_customer_phone_number',estimate_customer_phone_number);
  data.append('estimate_personnel_expenses',estimate_personnel_expenses);
  data.append('estimate_other_supplies',estimate_other_supplies);

  try{
      const response = await Axios.post(
          `${base_url}estimate/insert_estimate`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const delete_estimate = async (
  estimate_ID_array,
) => {

  let data = new FormData();
  data.append('estimate_ID_array',estimate_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}estimate/delete_estimate`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_inquiry = async (
) => {

  let data = new FormData();

  try{
      const response = await Axios.post(
          `${base_url}estimate/get_inquiry`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_inquiry_search = async (
  inquiry_customer_name,
  inquiry_customer_phone_number,
) => {

  let data = new FormData();

  data.append('inquiry_customer_name',inquiry_customer_name);
  data.append('inquiry_customer_phone_number',inquiry_customer_phone_number);

  try{
      const response = await Axios.post(
          `${base_url}estimate/get_inquiry_search`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_inquiry_one = async (
  inquiry_ID
) => {  
  let data = new FormData();
  data.append('inquiry_ID',inquiry_ID);

  try{
      const response = await Axios.post(
          `${base_url}estimate/get_inquiry_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_inquiry = async (
  customer_ID_use_inquiry,
  inquiry_customer_name,
  inquiry_customer_phone_number,
  inquiry_address
) => {

  let data = new FormData();
  data.append('customer_ID_use_inquiry',customer_ID_use_inquiry);
  data.append('inquiry_customer_name',inquiry_customer_name);
  data.append('inquiry_customer_phone_number',inquiry_customer_phone_number);
  data.append('inquiry_address',inquiry_address);

  try{
      const response = await Axios.post(
          `${base_url}estimate/insert_inquiry`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const delete_inquiry = async (
  inquiry_ID_array,
) => {

  let data = new FormData();
  data.append('inquiry_ID_array',inquiry_ID_array);

  try{
      const response = await Axios.post(
          `${base_url}estimate/delete_inquiry`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_other_amount_one = async (
) => {
  let data = new FormData();
  data.append('other_amount_ID', '0');
  try{
      const response = await Axios.post(
          `${base_url}estimate/get_other_amount_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

//특정 ID의 롤링 배너를 가져오기 위한 API입니다.
export const update_other_amount = async (
  other_amount_helper_time,
  other_amount_helper_price,
  other_amount_mourning_clothes_price_man,
  other_amount_mourning_clothes_price_woman,
  other_amount_personnel_expenses,
  other_amount_admission_fee,
  ohter_amount_other_supplies
) => {
  let data = new FormData();
  data.append('other_amount_ID', '0');
  data.append('other_amount_helper_time',other_amount_helper_time);
  data.append('other_amount_helper_price',other_amount_helper_price);
  data.append('other_amount_mourning_clothes_price_man',other_amount_mourning_clothes_price_man);
  data.append('other_amount_mourning_clothes_price_woman',other_amount_mourning_clothes_price_woman);
  data.append('other_amount_personnel_expenses',other_amount_personnel_expenses);
  data.append('other_amount_admission_fee',other_amount_admission_fee);
  data.append('ohter_amount_other_supplies',ohter_amount_other_supplies);

  try{
      const response = await Axios.post(
          `${base_url}estimate/update_other_amount`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_customer_login_ID_check = async (
  customer_login_ID
) => {

  let data = new FormData();
  data.append('customer_login_ID',customer_login_ID);

 try{
      const response = await Axios.post(
          `${base_url}login/get_customer_login_ID_check`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_customer_one = async (
  customer_ID
) => {

  let data = new FormData();
  data.append('customer_ID',customer_ID);

 try{
      const response = await Axios.post(
          `${base_url}login/get_customer_one`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const insert_customer = async (
  customer_login_ID,
  customer_password,
  customer_name,
  customer_phone_number,
  customer_email,
  customer_gender,
  customer_zip_code,
  customer_road_address,
  customer_detail_address,
  customer_big_area,
  customer_small_area,
  customer_privacy_status,
  customer_marketing_status,
  customer_service_agreement_status,
) => {

  let data = new FormData();
  data.append('customer_login_ID',customer_login_ID);
  data.append('customer_password',customer_password);
  data.append('customer_name',customer_name);
  data.append('customer_phone_number',customer_phone_number);
  data.append('customer_email',customer_email);
  data.append('customer_gender',customer_gender);
  data.append('customer_zip_code',customer_zip_code);
  data.append('customer_road_address',customer_road_address);
  data.append('customer_detail_address',customer_detail_address);
  data.append('customer_big_area',customer_big_area);
  data.append('customer_small_area',customer_small_area);
  data.append('customer_privacy_status',customer_privacy_status);
  data.append('customer_marketing_status',customer_marketing_status);
  data.append('customer_service_agreement_status',customer_service_agreement_status);

  try{
      const response = await Axios.post(
          `${base_url}login/insert_customer`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const update_customer = async (
  customer_ID,
  customer_phone_number,
  customer_email,
  customer_gender,
  customer_zip_code,
  customer_road_address,
  customer_detail_address,
  customer_big_area,
  customer_small_area,
  customer_privacy_status,
  customer_marketing_status,
  customer_service_agreement_status,
) => {

  let data = new FormData();
  data.append('customer_ID',customer_ID);
  data.append('customer_phone_number',customer_phone_number);
  data.append('customer_email',customer_email);
  data.append('customer_gender',customer_gender);
  data.append('customer_zip_code',customer_zip_code);
  data.append('customer_road_address',customer_road_address);
  data.append('customer_detail_address',customer_detail_address);
  data.append('customer_big_area',customer_big_area);
  data.append('customer_small_area',customer_small_area);
  data.append('customer_privacy_status',customer_privacy_status);
  data.append('customer_marketing_status',customer_marketing_status);
  data.append('customer_service_agreement_status',customer_service_agreement_status);

  try{
      const response = await Axios.post(
          `${base_url}login/update_customer`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const login_judgement_customer = async (
  customer_login_ID,
  customer_password,
) => {

  let data = new FormData();
  data.append('customer_login_ID',customer_login_ID);
  data.append('customer_password',customer_password);

  try{
      const response = await Axios.post(
          `${base_url}login/login_judgement_customer`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_session_data_homepage = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
          `${base_url}login/get_session_data_homepage`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const delete_session_data_homepage = async () => {
  let data = new FormData();
  try{
      const response = await Axios.post(
        `${base_url}login/delete_session_data_homepage`,
        data,
        {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true
        },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_kakao_token = async (code) => {
  try{
      const response = await Axios.post(
        `https://kauth.kakao.com/oauth/token`,
        {},
        {
          headers: {
              "Content-Type": "application/x-www-form-urlencoded"
          },
          params:{
              grant_type: 'authorization_code',
              client_id: '12e8da5cfb72102336fff2ff7a5c8606',
              code: code,
              redirect_uri: base_homepage_url_export + 'KakaoLogin'
          }
        }
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_kakao_login_data = async (token) => {
  try{
      const response = await Axios.post(
        'https://kapi.kakao.com/v2/user/me',
        {},
        {
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
        },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const check_kakao_login = async (
  customer_kakao_ID,
  customer_email,
  customer_name
) => {

  let data = new FormData();
  data.append('customer_kakao_ID',customer_kakao_ID);
  data.append('customer_email',customer_email);
  data.append('customer_name',customer_name);

  try{
      const response = await Axios.post(
        `${base_url}login/check_kakao_login`,
        data,
        {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true
        },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_naver_token = async (code) => {
  
  let data = new FormData();
  data.append('code',code);

  try{
      const response = await Axios.post(
        `${base_url}login/get_naver_token`,
        data,
        {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true
        },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const check_google_login = async (
  customer_email,
  customer_name
) => {

  let data = new FormData();
  data.append('customer_email',customer_email);
  data.append('customer_name',customer_name);

  try{
      const response = await Axios.post(
        `${base_url}login/check_google_login`,
        data,
        {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true
        },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_customer = async (
  ) => {
  
  let data = new FormData();

  try{
      const response = await Axios.post(
          `${base_url}customer/get_customer`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_customer_search = async (
  customer_name,
  customer_phone_number,
) => {
  
  let data = new FormData();

  data.append('customer_name',customer_name);
  data.append('customer_phone_number',customer_phone_number);

  try{
      const response = await Axios.post(
          `${base_url}customer/get_customer_search`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}

export const get_customer_one_admin = async (
  customer_ID
) => {  
  let data = new FormData();
  data.append('customer_ID',customer_ID);

  try{
      const response = await Axios.post(
          `${base_url}customer/get_customer_one_admin`,
          data,
          {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              withCredentials: true
          },
      );
      return response.data;
  }catch(err){
      console.log(err);
  }
}
