import React, {useState} from 'react';//Header 컴포넌트를 가져온 것이고
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import {
  login_judgement_customer,
  base_homepage_url_export,
  check_google_login
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';
import login_kakao from '../../Images/Login/login_kakao_icon.png';
import login_naver from '../../Images/Login/login_naver_icon.png';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

function Login() {

  const [customerLoginID, setCustomerLoginID] = useState('');
  const [customerPassword, setCustomerPassword] = useState('');
 
  const check_login = () => {
    if(customerLoginID === '' || customerPassword === '' ){
      alert('아이디 및 비밀번호를 입력해주세요.');
    }else{
      login_judgement_customer(customerLoginID, customerPassword).then((result)=>{
          if(result === 0){
              alert('아이디 및 비밀번호를 확인해주세요.');
          }else{
              window.location.href = "/Estimate";
          }
      });
    }
  }

  return (
    <>
      <Header/>

      <H.main_sectionBox>
        <div className="main_sectionBoxInner">
          <H.main_loginWrap>
            <div className="main_loginTit">
              로그인
            </div>
            
            <div className="main_loginBox">
              <label htmlFor='customer_login_ID'>아이디</label>
              <input 
                type='text' 
                id = 'customer_login_ID' 
                maxLength={50} 
                onChange={(event_data)=>{
                  setCustomerLoginID(event_data.target.value);
                }} 
                placeholder = "아이디를 입력해 주세요."
              />
            </div>
            <div className="main_loginBox">
              <label htmlFor='customer_password'>비밀번호</label>
              <input 
                type='password' 
                id = 'customer_password' 
                maxLength={50}
                onChange={(event_data)=>{
                  setCustomerPassword(event_data.target.value);
                }} 
                placeholder = "비밀번호를 입력해 주세요."
                />
            </div>

            <H.main_LoginBtn onClick={()=>{
              check_login();
              }}>
                로그인
            </H.main_LoginBtn>
            
            <H.main_easyLogin>
              {/* 긴 이미지로 해주셨으면 좋겠어요 :) */}
              <a className="main_easyLogin_btn main_easyLogin_btn_kakao"
                href={`https://kauth.kakao.com/oauth/authorize?client_id=12e8da5cfb72102336fff2ff7a5c8606&redirect_uri=${base_homepage_url_export}KakaoLogin&response_type=code`}
              >
                <p className="main_easyLogin_btn_img">
                  <img src = {login_kakao} alt="카카오 로그인"/>
                </p>
                <p className="main_easyLogin_btn_txt">카카오톡 계정으로 로그인</p>
              </a>
              <a className="main_easyLogin_btn main_easyLogin_btn_naver"
                href={`https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=PgVxU4iOkKjvpsbNoVUG&state=STATE_STRING&redirect_uri=${base_homepage_url_export}NaverLogin`}
              >
                <p className="main_easyLogin_btn_img">
                  <img src = {login_naver} alt="카카오 로그인"/>
                </p>
                <p className="main_easyLogin_btn_txt">네이버 계정으로 로그인</p>
              </a>
              <GoogleLogin
                onSuccess={credentialResponse => {
                  const decodeding = jwtDecode(credentialResponse.credential);
                  check_google_login(
                    decodeding.email,
                    decodeding.name
                  ).then((check_data)=>{
                      if(check_data.customer_join_level === 1){
                          window.location.href=`/Join?customer_ID=${check_data.customer_ID}`
                      }else{
                          alert('로그인이 완료되었습니다.');
                          window.location.href=`/Estimate`
                      }
                  });
                }}
                useOneTap
                size="large"
                width="360"
              />
            </H.main_easyLogin>
          </H.main_loginWrap>

        </div>
      </H.main_sectionBox>

      <Footer/>
    </>
  );
}

export default Login;
