//import에 대한 설명은 src/Admin/Login/AdminLogin.js 파일에 자세하게 설명해났습니다. 참고하세요.
import React, {useEffect, useState, useRef} from 'react';
//AdminHeader에 대한 설명은 src/Admin/Main.js 참고
import AdminHeader from '../Section/AdminHeader';
//이거는 tabulator라는 게시판을 쉽게 만들기 위한 패키지이고
//https://tabulator.info/ <-이게 공식 홈페이지입니다.
//저는 게시판을 만들때 Tabulator를 많이사용합니다.
//설치는 npm install react-tabulator 로 설치했고요 :)
import { ReactTabulator } from 'react-tabulator';
import * as H from '../../Styles/StyleComponentHan';

//아래 함수들에 대한 자세한 설명은 Axios.js 파일을 참고하세요
import {
  get_user,
} from "../../Axios";

export function UserManagement() {  
  // useState는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Login/AdminLogin.js파일에 설명을 해났음
  // 참고로 useState([])이렇게 하면 처음 기본값은 []빈배열이라는 것이고 초기값도 저안에 값을 어떻게 하냐에 따라 다릅니다.
  const [userData, setUserData] = useState([]); //백엔드에서 가져온 사용자 데이터를 담기위한 useState

  // useRef는 React에서 제공하는 hook인데 밑에 HTML을 참고하기 위해 useRef를 사용합니다.
  // tabulatorRef이거는 아래 <ReactTabulator> 이거를  참고하기 위해 사용했으며
  // <ReactTabulator> 가면 tabulatorRef를 사용한것을 볼 수 있습니다.
  const tabulatorRef = useRef();

  //이거는 수정버튼 눌렀을 때 체크하는 함수 입니다.
  const check_user_edit = () =>{
    if(tabulatorRef.current.getSelectedData().length > 1){
      alert('수정할 데이터를 하나만 선택해주세요.');
    } else if(tabulatorRef.current.getSelectedData().length === 0){
      alert('수정할 데이터를 선택해주세요.');
    } else{
      //이건 GET방식으로 URL을 이동하는 것인데
      //?뒤에 데이터를 포함시켜 이동시킬 수 있다.
      //http://localhost:3000/UserManagementAdd?user_ID=2
      //이렇게 사용자의 고유 ID를 포함시켜서 날리는데
      //그럼 UserManagementAdd여기에서 user_ID가 2라는 것을 활용할 수 있다.
      window.location.href = `/UserManagementAdd?user_ID=${tabulatorRef.current.getSelectedData()[0].user_ID}`;
    }
  }

  // useEffect는 React에서 제공하는 hook인데 자세한 설명은 /src/Admin/Section/AdminHeader.js파일에 설명을 해났음
  useEffect(() => {
    //이거는 데이터베이스에 있는 사용자들을 가져오기 위한 Function이고 
    //자세한 내용은 src/Axios.js 파일 확인
    get_user().then(result_data=>{
      //사용자 데이터를 setUserData를 활용하여 userData에 할당
      setUserData(result_data);
    })
  },[]);
  return (
    <H.admin_MainWrap> {/* 전체박스 */}
      <AdminHeader/> {/* 헤더 */}
      <H.admin_rightWrap> {/* 우측 전체박스*/}
        <H.admin_rightTitBox>{/* 우측 박스 */}
          <H.admin_rightTitInner>{/* 우측 이너이너 */}
            <p>시스템 관리 - 사용자 관리</p>
            <div>
              <H.admin_addBtn
                //onClick event에 대한 내용은 src/Admin/Login.js 참고
                onClick={()=>{window.location.href = "/UserManagementAdd";}}>등록
              </H.admin_addBtn>
              <H.admin_changeBtn
                //수정 버튼 눌렀을 시 실행되는 함수 위에 정의함
                onClick={()=>{check_user_edit();}}>수정
              </H.admin_changeBtn>
{/*               <H.admin_deleteBtn
                //삭제 버튼 눌렀을 시 실행되는 함수 위에 정의함
                onClick={()=>{check_user_delete();}}>삭제
              </H.admin_deleteBtn> */}
            </div>
            </H.admin_rightTitInner>
          </H.admin_rightTitBox>
          
        <H.admin_rightInfoBox>{/* 우측 아래 */}
          <H.admin_rightInfoInner>
            <H.listInfoText>
              <p className="listInfoTextBold">사용 방법 안내</p>
              <p>1. 삭제는 안되며 수정 버튼을 통해 사용여부를 미사용으로 활용하시면 됩니다.</p>
            </H.listInfoText>
            {
            userData.length !== 0 ? 
            <ReactTabulator
              onRef={(r) => (tabulatorRef.current = r.current)}
              data={userData}
              //밑에 있는 colums는 tabulator에서 제공하는 형식에 맞춰서 컬럼들을 정의하는 겁니다.
              //자세한 내용은 https://tabulator.info/ 공식홈페이지 참고하시면 됩니다.
              columns={[
                {formatter:"rowSelection", titleFormatter:"rowSelection", headerSort:false, 
                  cellClick:function(e, cell){
                    cell.getRow().toggleSelect();
                  }
                },
                { title: "ID", field: "user_ID", headerSort:false},
                { title: "사용자명", field: "user_name", headerSort:false},
                { title: "로그인ID", field: "user_login_ID", headerSort:false},
                { title: "메뉴권한명", field: "menu_permission_name", headerSort:false},
                { title: "사용 여부", field: "user_status", headerSort:false},
                { title: "등록 일자", field: "user_registe", headerSort:false},
                { title: "수정 일자", field: "user_modified", headerSort:false},
              ]}
              layout={"fitData"}
              />
            : 
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            }
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>

      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default UserManagement;
