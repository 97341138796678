import styled from "styled-components";


// 폰트 사용 방법 -> font-weight : ${noto_m}; 
//const noto_eb = `font-family: 'Noto Sans KR', sans-serif;  font-weight: 900;`;
const noto_b = `font-family: 'Noto Sans KR', sans-serif;  font-weight: 700;`;
const noto_m = `font-family: 'Noto Sans KR', sans-serif;  font-weight: 500;`;
const noto_r = `font-family: 'Noto Sans KR', sans-serif;  font-weight: 400;`;
//const noto_l = `font-family: 'Noto Sans KR', sans-serif;  font-weight: 300;`;
//const noto_t = `font-family: 'Noto Sans KR', sans-serif;  font-weight: 100;`;

//컬러 지정
const color_mainBlack = "#121212"
const color_darkGray = "#4a4a4a"
const color_darkMiddleGray = "#888"
const color_middleGray = "#aaa"
const color_lightGray = "#ddd"
const color_bgGray ="#f5f5f5"
const color_white ="#fff"
const color_pointBrown = "#654c3f"
const color_pointRed = "#f00"
const color_pointOrange = "#E2440D"
const color_subMiddleGray = `#ccc`;

//사이즈 지정
//const mobileSize = '500px';







//-------------------- admin --------------------
// admin page start  :: section_admin login
export const admin_LoginWrap = styled.div` 
    width: 100%;
    height: 100vh;
    min-width:900px;
    position: relative;
    background-color:#000;
    
    & > img {
      width: 100%;
      height: 100%; 
      object-fit: cover;
      opacity:0.25;

    }
`;

export const admin_LoginInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width:1000px;
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);
`;

export const admin_LoginLogo = styled.div`
    width: 345px;
    height: 75px;
    background: url(${props => props.admin_logowhite}) no-repeat center 100%/100%;
`;

export const admin_LoginFormBox = styled.div`
    width: 460px;
    height: 580px;
    padding: 90px 50px 0; 
    box-sizing: border-box;
    background-color: ${color_white};
`;

export const admin_LoginTit = styled.div`
      height: 30px;
      line-height: 28px;   
      font-size: 28px;
      text-align: center;
      font-weight: ${noto_b};
      margin-bottom: 90px; 
`;

export const admin_LoginInputBox = styled.div`
    display: flex;    
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${color_lightGray};

    & > label {
      width: 80px;
      margin-right: 10px;
    }

    & > input{
      width: 100%;
    }
`;

export const admin_LoginBtn = styled.div`
    margin-top: 40px;
    margin-bottom: 20px; 
    line-height: 46px;
    text-align: center;
    background-color: ${color_mainBlack};
    color: ${color_white};
    cursor: pointer;
`;

export const admin_LoginSearch = styled.div`
    text-align: center; 

    & > span{
      display: inline-block; 
      width: 1px;
      height: 12px;
      background-color: ${color_darkMiddleGray};
      margin: 0 14px;
    }
`;

//section_admin header
export const admin_HeaderWrap = styled.div`
    width: 100%;
    min-width: 360px;
    position: relative;
    z-index:1000;
    overflow-y:scroll;
`;

export const admin_sideMenuBox = styled.div`
    position: fixed;
    width: 210px;
    height: 100%;
    background-color: ${color_pointBrown};
    color: ${color_white}; 
    padding-top: 21px; 
    overflow: auto;

        & > div {
            width: 100%;
            margin: 0 auto;
        }

        &::-webkit-scrollbar{
            width:5px;
            background-color:${color_darkGray}; 
        }

        &::-webkit-scrollbar-thumb{
            background-color: ${color_pointBrown};
        }

        &::-webkit-scrollbar-track{
            background-color:${color_darkGray};
        }
`;

export const admin_sideMenuLogo = styled.div`
    width: 180px;
    padding: 0 20px 30px;

    & img{
      width: 100%;
      height: auto;
      object-fit: cover;
      padding-bottom: 20px; 
      border-bottom: 1px solid ${color_middleGray}; 
    }
`;

export const admin_MenuTreeTop = styled.div`
    width: 100%;
    height: 40px;
    padding-left:18px;
    font-size: 14px;
    line-height: 40px;
`;

export const admin_MenuTreeMiddle =  styled.div`
    width: 100%;
    height: 40px;
    font-size: 12px; 
    line-height: 40px; 
    background-color: ${color_mainBlack};

    & > a {
        color: ${color_middleGray};
        line-height: 40px;
        padding-left:26px;
    }

    &:last-child{
        margin-bottom:40px; 
    }
`;


//section_admin page
export const admin_MainWrap = styled.div`
    width: 100%;
    min-width: 1400px;
    min-height:100vh;
    padding-bottom: 100px;
    position: relative;
    background-color: ${color_bgGray};
`;

export const admin_rightWrap = styled.div`
    margin-left: 200px;
`;

export const admin_rightTitBox = styled.div`
    height: 80px;
    margin-bottom: 30px; 
    background-color: ${color_white};
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

export const admin_rightTitInner = styled.div`
    width: 1200px;     
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > p{
        font-size: 20px;
        font-weight: ${noto_m}; 
    }
`;

// 등록 버튼일 경우 사용
export const admin_addBtn = styled.button`
    height: 40px;
    color: ${color_white};
    padding:0 26px;
    margin-left: 10px; 
    background-color: ${color_pointBrown};
    border: 1px solid ${color_middleGray};
    cursor: pointer;
`;

export const admin_changeBtn = styled.button`
    height: 40px;
    color: ${color_white};
    padding:0 26px;
    margin-left: 10px; 
    background-color: ${color_darkGray};
    border: 1px solid ${color_middleGray};
    cursor: pointer;
`;

export const admin_deleteBtn = styled.button`
    height: 40px;
    padding:0 26px;
    margin-left: 10px; 
    background-color: ${color_bgGray};
    border: 1px solid ${color_middleGray};
    cursor: pointer;
`;

export const admin_rightInfoBox = styled.div`
    
`;

export const admin_rightInfoInner = styled.div`
    width: 1200px; 
    margin: 0 auto;

    & button{
        display:block; 
        color: ${color_white};
        padding:16px 60px;
        margin: 30px auto;
        background-color: ${color_pointBrown};
        border: 1px solid ${color_middleGray};
        cursor: pointer;
    }
`;

export const listInfoText = styled.div`
    line-height: 24px; 
    color: ${color_pointBrown};
    margin-bottom: 30px;
    
    .listInfoTextBold{
        font-weight: ${noto_b};
    }

    & span{
        color: ${color_pointRed};
    }
`;

export const admin_editorBoxTit = styled.div`
    font-size: 24px; 
    line-height: 30px;
    font-weight: ${noto_b};
    color: ${color_pointBrown};
    margin: 40px 0 10px; 

    & span{
        font-size:16px;
        margin-left:10px; 
    }
`;

export const admin_labelBox = styled.div`
    padding: 20px;
    background-color: ${color_white};
    border:1px solid ${color_lightGray};
    margin-bottom: 20px; 

    & label{
        display: inline-block;
        width: 150px; 
        font-size: 16px;
        font-weight: ${noto_m};
    }

    & input{
        font-size: 14px; 
        width: 70%;
    }

    & .inputDate{
        width: 51%;
        padding:6px;
        background-color: ${color_bgGray};
        position:relative;
    }

    & .inputDate::-webkit-calendar-picker-indicator {
        position: absolute;
        right: 0;
        top: 5px;
        width:20px;
        height:20px; 
        transform: translateX(-10px);
        padding-left: 3000px;
        cursor: pointer;
    }

    & select{
        width: 150px;
        height: 28px;
        padding: 0 6px;
        border: 1px solid ${color_lightGray};
    }

    & span{
        color: ${color_pointRed};
    }

`;


export const admin_topSearch = styled.div`
    width:1200px;
    margin: 40px auto;

    & .admin_topSearchInner{
        width: 780px;
        display:flex;
        align-items: start;
        justify-content: space-between;
    }

    & .admin_topSearchLabel{
        padding: 15px 15px;
        border:1px solid ${color_lightGray};
        background-color:${color_bgGray};
    }

    & label{
        display:inline-block; 
        width:90px; 
    }

    & span{
        color: ${color_pointRed};
    }

    & input{
        width:220px; 
    }

    & button{
        background-color:${color_pointBrown};
        color:${color_bgGray};
        padding: 16px 16px;
    }

`;


export const admin_errorBox = styled.p`
    font-size: 12px;
    line-height: 30px; 
    color: ${color_pointBrown};
    transition: all 0.3s;
`;

//admin label img all > 이미지 첨부하는 label일 경우 사용
export const admin_labelImg = styled.div`
    background-color: ${color_white};
    border:1px solid ${color_lightGray};

    & .labelImgTit {
        font-size: 16px;
        font-weight: ${noto_m};
        padding: 20px; 
        border-bottom: 1px solid ${color_lightGray};
        margin-bottom: 20px;   
    }

    & > div{
        margin-bottom: 20xp;
        border-bottom: 1px solid ${color_lightGray}; 
        padding: 20px;
    }

    & .labelImgFirst{
        display: flex;
    }

    & .labelImgSecond{
        width: 150px;
    }

    & .labelImgInfo{
        font-size: 14px; 
        color: ${color_darkMiddleGray};
        margin: 4px 0; 
    }

    & p > span{
        color: ${color_pointRed}; 
    }

    & label{
        display: inline-block;
        width: 106px;
        height: 40px; 
        margin-top: 6px; 
        background-color: ${color_bgGray};
        border:1px solid ${ color_lightGray};
        text-align: center;
        line-height: 40px;
        cursor: pointer;
    }

    & img{
        width: 700px;
        height: 297px;
        object-fit: cover;
        border:1px solid ${color_lightGray}; 
    }

    & .admin_mobileImg{
        width: 340px;
        height: 272px;
    }

    & .admin_newsImg{
        width: 400px;
        height: 400px;
    }

    & .admin_estimateDetailMobile{
        width: 400px;
        height: 667px;
    }

    & .admin_estimateDetailPc{
        width: 400px;
        height: 400px;
    }

    & .admin_ourstoryPC{
        width:700px;
        height:375px;
    }

    & .admin_ourstoryMo{
        width:394px;
        height:375px;
    }

    & .admin_optimumToolsPC{
        width:800px;
        height:300px;
    }

    & .admin_optimumToolsMo{
        width:600px;
        height:300px;
    }


`;



// 권한관리 체크박스포함
export const admin_menuPermissionBox = styled.div`
    background-color:${color_white};
    border: 1px solid ${color_lightGray};
    margin-bottom:20px; 
    

    & div{
        position: relative;
        padding: 12px 20px; 
        font-size:16px;
        line-height:16px; 
        font-weight: ${noto_b};
        color:${color_pointBrown};
        border-bottom: 1px solid ${color_lightGray};
    }

    & div:hover{
        background-color:${color_bgGray};
    }

    & div:last-child{
        border-bottom:none;
    }

    & div em{
        display: inline-block;
        position: absolute;
        top:13px;
        left:21px;
        width:21px;
        height:21px;
        border:1px solid ${color_middleGray};
    }

    & .admin_menuPermissionSub em{
        display: inline-block;
        position: absolute;
        top:13px;
        left:44px;
        width:21px;
        height:21px;
        border:1px solid ${color_middleGray};
    }

    & input[type="checkbox"]{
        display: none;
    }

    & label{
        padding-left:34px;
        line-height:24px; 
    }

    & input[type="checkbox"] + label{
        display: inline-block;
        position: relative;
        width:98%;
        height:24px; 
        margin-right:10px; 
        cursor: pointer;
    }


    & input[type="checkbox"]:checked + label::after{
        content:'✓';
        position: absolute;
        left: 0;
        top:0;
        width: 24px;
        height: 24px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: ${color_bgGray};
        background-color:${color_pointBrown};
    }
      
    & .admin_menuPermissionSub{
        font-size:14px;
        font-weight: ${noto_r};
        padding-left:44px; 
    }
`;








//-------------------- homepage --------------------
//---------- header ----------
export const headerDiv = styled.div`
    min-width: 360px;
    height: 64px;
`;

export const headerInner = styled.div`
    width:100%;
    height: 64px;
    background-color: ${color_bgGray};
    position: fixed;
    z-index: 99999;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);

    & button{
        display: inline-block;
        position: absolute;
        top: 0;
        right: 20px;
        padding: 0 10px;
        font-size: 16px;
        line-height: 64px;
        color: ${color_darkGray};
    }

    & .blackScream{
        visibility: visible;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        right: 0;
        background-color:rgba(0,0,0,0.8);
        transition: all 0.3s;
    }

    & .sideMenu{
        position: fixed;
        top: 0;
        right: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        background-color: ${color_white};
        box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
        overflow-x: scroll;
        transition: all 0.4s ease-out;
    }

    & .sideMenuOpen{
        right:-400px;
    }

    & .sideMenuInner{
        display:block;
    }

    & .sideMenuTop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5%; 
        height: 64px;
        border-bottom: 1px solid ${color_middleGray};
    }

    & .sideMenuTop img{
        width: 190px;
        height: 30px;
        object-fit: cover;
    }

    @media screen and (min-width:400px){
        .sideMenu{
            width: 360px;
        }
    }
`;

export const headerMainLogo = styled.div`
    padding-top: 12px;
    margin-left: 20px;

    & a{
        display: inline-block;
        line-height: 40px; 
        width: 184px;
        height: 40px;
    }

    & img{
        width: 255px;
        height: 40px;
        object-fit: cover;
    }
`;

export const gnbMenu = styled.div`
    margin: 15px 20px;
    font-size: 20px;
    line-height: 20px; 
    font-weight: ${noto_m};
    border-bottom: 1px solid ${color_middleGray};

    & > div{
        margin: 6px 0;
        padding: 12px 0;
    }

    & > div > a{
        display: inline-block;
        width: 100%;
    }

    & .sideMenuFlex{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    & .sideMenuFlex img{
        width: 16px;
        height: 16px;
        padding: 7px 0px;
        object-fit: cover;
        transition: all 0.3s;
    }

    & .sideMenuSub{
        display: block;
        padding-left: 20px;
        margin-top: 20px; 
        transition: all 0.3s;
    }

    & .sideMenuSub a{
        display: inline-block;
        width: 100%;
        font-size: 16px; 
        color: ${color_darkMiddleGray};
        font-weight:${noto_r};
        margin:5px 0; 
    }

    & .gnbMenuIntranet{
        border-top: 1px solid ${color_middleGray};
        padding:19px 0 14px; 
    }

    & .gnbMenuIntranet a{
        font-size:14px;
        color:${color_darkMiddleGray};
    }


    & .gnbMenuIntranet .gnbMenuIntranetLogout{
        margin: 5px 0 14px; 
    }

    & .gnbMenuJoin{
        border-top: 1px solid ${color_middleGray};
        display:flex;

    }

    & .gnbMenuJoin a{
        color:${color_darkGray};
        font-size:14px;
        line-height:20px;
        width:70px; 
        margin-right:5px; 
    }

    & .gnbMenuJoin a:nth-child(2)::after{
        content:'';
        display:block; 
        float:left;
        width:1px;
        height:20px;
        background-color:${color_subMiddleGray};
        margin-right:25px;
    }

    & .gnbMenuSns{
        display:flex; 
        border-top: 1px solid ${color_middleGray};
        padding: 20px 0; 
    }

    & .gnbMenuSns a{
        display:inline-block; 
        width: 24px; 
        height:24px;
        margin-right:14px; 
    }
        
`;


//---------- footer ----------
export const footerWrap = styled.footer`
    background-color:${color_darkGray}; 
    width:100%; 
    min-width: 360px;
    padding: 0 0 ;

    @media screen and (max-width: 479px) {
        padding-bottom:100px;
    }

    & .footerInner{
        width:90%; 
        margin: 0 auto; 
        padding: 40px 20px ; 

        font-size: 12px;
        line-height:20px;
        color: ${color_lightGray}; 
        text-align: center; 
    }
`;




//---------- main section ----------
export const home_Wrap = styled.div`
    width:100%; 
    min-width:360px; 
`;

export const home_mainSliderWrap = styled.div`
    width:100%;
    position:relative;

    & .home_mainSliderBox{
        width:100%;
        border: none;
        transition: all 0.3s; 
    }

    @media screen and (min-width: 1200px){
        & .home_mainSliderBox{
            width:100%;
        }
    }
`;

export const home_mainSliderBtns = styled.div`
    position:absolute;
    z-index:30;
    top:50%;
    width:20px;
    height:40px;
    opacity: 1;

    &.slider_left_button{
        left: 3%;
    }

    &.slider_right_button{
        right: 3%; 
    }

    & img{
        width:20px;
        height: 40px;
    }
`;



//반응형에 맞게 이미지를 다른거를 불러온다. 
export const SlideImg = styled.img`
    width:100%;
    height:60vh; 
    border: none;
    background-image: url(${props => props.rolling_banner_image_mobile});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: all 0.3s; 
    object-fit: cover;

    @media screen and (min-width: 700px) {
        height:90vh; 
    }

    @media screen and (min-width: 1000px) {
        height: 60vh ;
        background-image: url(${props => props.rolling_banner_image});
    }

    @media screen and (min-width: 1440px) {
        height: 815px ;
    } 
`; 




// -------------- 메인 섹션 시작 --------------
export const main_sectionBox = styled.section`
    width:100%;
    min-width:360px;
    transition: all 0.3s;

    & .main_sectionBoxInner{
        width:100%;
        padding:15% 0;
        margin: 0 auto;
        transition: all 0.3s;
    }

    &.printNonViewWrap{
        display:none;
        transition: all 0.3s;
    }

    @media screen and (min-width: 700px){
        & .main_sectionBoxInner{
            width: 700px;
            padding:80px 0;
        }
    }

    @media screen and (min-width: 1200px){

        &.printNonViewWrap{
            display:block; 
        }

        & .main_sectionBoxInner{
            width:1200px;
        }
    }
`;

export const main_sectionTit = styled.h2`
    font-size:6vw;
    line-height:1.4em;
    font-weight: ${noto_b};
    text-align:center;
    color:#333; 
    margin-bottom: 8%;

    @media screen and (min-width: 700px){
        font-size:36px;
        line-height:36px;
        margin-bottom: 60px;
    }
`;

export const main_sectionSliderWrap = styled.div`
    width: 100%;
    display:flex;
    flex-wrap:wrap;

    @media screen and (min-width: 1200px){
        width: 100%;
        overflow: hidden; 
        overflow-x: scroll;
        flex-wrap:nowrap;
        justify-content: space-between;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

export const main_sectionSliderBox = styled.div`
    width:44%;
    height:44%;
    margin:0 3% 5%;
    transition: all 0.3s;

    & .main_sectionSliderTit{
        font-size: 4vw;
        line-height: 1.4em;
        font-weight: ${noto_m};
        text-align: center; 
        margin-bottom: 3%; 
    }

    & .main_sectionSliderImg{
        width:100%;
        height:100%; 
        overflow:hidden;
        object-fit: cover;
        opacity:1;
        transition:all 0.5s;
    }

    & .main_sectionSliderImg:hover{
        opacity:0.6;
    }

    & img{
        width:100%;
        height:100%;
        max-height: 100%;
    }



    @media screen and (min-width: 700px){

        & .main_sectionSliderTit{
            font-size: 16px;
            line-height: 24px;
            
            margin-bottom: 10px; 
        }
    }

    @media screen and (min-width: 1200px){
        width: 290px;
        margin:0 0% 0%;

        & .main_sectionSliderImg{
            width:290px;
            height:290px; 
        }
    }
`;



export const main_sectionStory = styled.div`
    width:100%;
    padding:5%; 
    margin: 0 auto; 

    & img{
        width:100%;
        height:auto; 
        object-fit: cover;
    }

    @media screen and (min-width: 1200px){
        width: 1200px;
        padding: 0;
    }

`;


export const main_sectionQuick = styled.div`
    width:80%; 
    padding-bottom:10%;
    margin: 0 auto; 

    & a{
        font-size: 3.8vw;
        margin:0 auto; 
        padding: 0 5%;
        display:flex;
        align-items: center;
        justify-content: space-between;
        height:16vw;
        border-top: 1px solid #aaa;
        color:#494948;
    }

    & a:last-child{
        border-bottom: 1px solid #aaa; 
    }

    & img{
        width: 8px;
        height:14px; 
    }

    @media screen and (min-width: 700px){
        width:700px; 
        display: flex;
        flex-wrap: wrap;

        & a{
            width: 300px; 
            font-size: 14px;
            padding: 0 10px;
            height:60px;
        }

        & a:nth-child(3){
            border-bottom: 1px solid #aaa; 
        }
    }
`;



//---------- main section - login ----------
export const main_loginWrap = styled.div`
    width: 360px; 
    padding: 40px 0; 
    margin:0 auto;

    & .main_loginTit{
        font-size: 28px;
        line-height: 30px;
        text-align:center;
        color: ${color_darkGray};
        margin-bottom: 40px; 
    }
    
    & .main_loginBox{
        border: 1px solid ${color_lightGray};
        padding:14px;
        margin-bottom:10px;  
    }

    & .main_loginBox label{
        display:inline-block;
        width:80px; 
    }

    & input{
        width:70%;
    }
`;


export const main_LoginBtn = styled.div`
    margin-top: 40px;
    margin-bottom: 20px; 
    line-height: 46px;
    text-align: center;
    background-color: ${color_mainBlack};
    color: ${color_white};
    cursor: pointer;
`;

export const main_easyLogin = styled.div`

    & .main_easyLogin_btn{
        display:inline-block;
        width:360px;
        height:40px;
        line-height:40px; 
        margin-bottom:10px;
        display:flex;
        box-sizing: border-box;
    }

    & .main_easyLogin_btn.main_easyLogin_btn_kakao{
        background-color:#ffeb00;
    }

    & .main_easyLogin_btn.main_easyLogin_btn_naver{
        background-color:#00d151;
    }

    & .main_easyLogin_btn_txt{
        width:310px;
        text-align:center; 
    }

    & .main_easyLogin_btn_img{
        padding:10px;
        width:38px;
        height:38px;
    }

    & .main_easyLogin_btn_img img{
        width:20px;
        height:20px;
    }

`;







//---------- main section - editorView ----------
export const editorView_mainWrap = styled.div`
    width: 100%;
    min-width: 360px;
    padding: 60px 0;

    & .editorView_mainInner{
        width:90%;
        margin: 0 auto;
        overflow:hidden; 
    }

    & .editorView_mobile{
        display:block;
    }

    & .editorView_mobile img{
        width:100%;
        height:auto;
    }

    & .editorView_pc{
        display:none;
    }


    @media screen and (min-width: 900px){
        & .editorView_mobile{
            display:none;
        }
    
        & .editorView_pc{
            display:block;
            width:100%;
            height:auto; 
        }
    }

    @media screen and (min-width: 1200px){
        & .editorView_mainInner{
            width:1200px;
        }
    }

`;






//---------- main section - news&event main ----------
export const newsEventMain = styled.div`
    transition: all 0.3s;

    & .newsEventMainTit{
        font-size: 26px; 
        line-height: 26px; 
        text-align: center;
        margin-bottom: 60px; 
    }

    .newsEventMainBox{
        width: 100%;
        margin-bottom: 12%; 
        transition: all 0.3s;
    }

    & .newsEventMainBoxImg{
        width: 100%;
        height: 90vw;
        transition: all 0.3s;
    }

    & .newsEventMainBoxImg img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    & .newsEventMainBoxTit{
        font-size: 5.5vw;
        line-height: 1.4em;
        font-weight: ${noto_m};
        margin: 20px 0 10px;
        transition: all 0.3s;
    }

    & .newsEventMainBoxSubTit{
        font-size: 4.4vw;
        line-height: 1.4em;
        color: ${color_pointBrown};
        transition: all 0.3s;
    }


    @media screen and (min-width:700px){
        & .newsEventMainWrap{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        & .newsEventMainBox{
            width: 46%;
            margin:0 2% 14%;
        }

        & .newsEventMainBoxImg{
            width: 100%;
            height: 41.4vw;
        }

        & .newsEventMainBoxImg img{
            width: 100%;
            height: 100%;
        }

        & .newsEventMainBoxTit{
            font-size:28px;
            line-height:36px;
        }

        & .newsEventMainBoxSubTit{
            font-size: 16px;
            line-height: 22px;
        }
    }

    @media screen and (min-width:1200px){

        & .newsEventMainBox{
            width: 380px;
            margin:0 10px 50px; 
        }

        & .newsEventMainBoxImg{
            width: 380px;
            height: 380px;
        }
    }
`;


export const newsEventDetail = styled.div`
    & .newsEventDetailBox{
        margin-bottom: 40px;
        text-align: center; 
    }

    & .newsEventDetailRegiste{
        font-size: 12px;
        line-height: 1.4em;
        color: ${color_darkMiddleGray};
    }

    & .newsEventDetailTit{
        font-size: 28px;
        line-height: 1.4em;
        margin: 20px 0; 
    }

    & .newsEventDetailSubTit{
        font-size: 18px;
        line-height: 1.4em;
        color: ${color_middleGray}; 
    }

    @media screen and (min-width:1200px){
        & .newsEventDetailTit{
            font-size: 32px;
            line-height: 40px;
        }
    }
`;





//----- 견적내기 -----
//---------- main section - Estimate ----------
export const estimate_mainWrap = styled.div`
    width: 100%;
    min-width: 360px;
    padding: 60px 0;

    & .estimate_mainInner{
        width:100%;
        height:auto;
        margin: 0 auto;
        padding:5%;
    }

    & .estimate_mainTit{
        font-size: 26px; 
        line-height: 26px;
        margin-bottom:5%; 
    }

    & .estimate_sectionBox{
        padding:5% 0; 
        border-top: 1px solid ${color_darkMiddleGray};
    }

    & .estimate_sectionBox:last-child{
        border-bottom: 1px solid ${color_darkMiddleGray};

    }

    & .estimate_sectionTit{
        font-size:20px;
        line-height:24px;
        font-weight:${noto_b};
        margin-bottom:1.5%; 
    }

    & .estimate_chooseBoxInfotxt{
        font-size:14px;
        color:${color_darkMiddleGray};
        margin-bottom:2%;
    }


    & .estimate_chooseBoxButton{
        display: inline-block;
        width:340px;
        margin: 0 2px 10px;
        padding: 6% 2%;
        box-sizing: border-box;
        font-size:14px;
        color:${color_darkGray};
        text-align:center;
        border-radius:6px; 
        border: 1px solid ${color_middleGray};
        transition: all 0.3s;
    }

    & .button_active{
        color: ${color_pointOrange};
        background-color:#F4E6E1;
        border: 1px solid ${color_pointOrange};
    }

    @media screen and (min-width: 801px){
        & .estimate_sectionBox{
            padding:2% 0; 
        }

        & .estimate_chooseBoxButton{
            width:800px;
            padding: 3% 4%;
            font-size:14px;
        }
    }


    @media screen and (min-width: 1200px){
        & .estimate_mainInner{
            width:1200px;
            padding:0;
        }

        & .estimate_flexBox{
            width:1200px;
            height:auto; 
            position:relative;
        }

        & .estimate_flexBox .estimate_leftBox{
            width:820px;
            height:auto; 
        }

        & .estimate_flexBox .estimate_rightBox{
            width:350px;
            height:auto; 
            position: sticky;
            top:0;
            left:80%;
            right:0;
            bottom:1%; 
        }

        & .estimate_chooseBoxButton{
            font-size:14px;
            margin:3% 0;
        }
    }

`;



export const estimate_chooseBoxMain = styled.div`
    & .extimate_chooseBoxInfoBox{
        margin-bottom:2%;
        font-size:12px;
        line-height:20px;
        color:${color_darkMiddleGray};
    };

    & .extimate_chooseBoxTit{
        font-size:16px;
        font-weight: ${noto_m};
        color:${color_mainBlack};
        margin-bottom:2%;
    }

    & .extimate_chooseBoxTitInfo{
        font-size:12px;
        color:${color_mainBlack};
    }


    & .estimate_chooseBox{
        width:100%;
        background-color:#fff;
        display:flex;
        flex-wrap: wrap;
        margin-bottom:6%; 
    }

    & .estimate_carBox{
        background-color:#fff;
        flex-wrap: wrap;
    }

    & .estimate_chooseBox_noFlex{
        width:100%;
        background-color:#fff;
        margin-bottom:10%; 
    }

    @media screen and (min-width: 1200px){
        & .extimate_chooseBoxInfotxt{
            font-size:14px;
            margin-bottom:10px;
        }
    
        & .extimate_chooseBoxTit{
            font-size:16px;
            margin-bottom:14px;
        }
    
        & .estimate_chooseBox_noFlex{
            display:flex;
        }
    }

`;



// 이미지를 선택하는 라벨에 사용
export const estimate_chooseImg = styled.div`
    & input[type=radio] {
        display: none;
    }

    & label{
        display: block;
        width:104px;
        margin: 0 2px 10px; 
        padding: 3%;
        border-radius:6px; 
        box-sizing: border-box;
        text-align:center;
    }

    & label .chooseTit{
        font-size:11px;
        font-weight:600;
        line-height: 16px;
        margin:2% 0;
    }

    & label .priceText{
        font-size:11px;
        line-height:16px; 
        margin-bottom:3%;
    }
     
    & label img{
        display:inline-block;
        width:90px;
        height:90px;
        border-radius: 4px;
        overflow: hidden;
        object-fit: cover;
    }

    & input[type=radio] + label {
        border: 1px solid ${color_middleGray};
        color:${color_darkGray};
        transition: all 0.3s;
    }
  
    & input[type=radio]:checked + label {
        color: ${color_pointOrange};
        background-color:#F4E6E1;
        border: 1px solid ${color_pointOrange};
    }

    @media screen and (min-width: 1200px){
        & label{
            width:180px;
            margin: 0 10px 10px 2px;
        }

        & label .chooseTit{
            font-size:14px;
            margin:3% 0;
        }

        & label .priceText{
            font-size:14px;
            margin-bottom:3%;
        }
         
        & label img{
            width:160px;
            height:160px;
        }
    }
`;

// 글자를 선택하는 라벨에 사용
export const estimate_chooseText = styled.div`
    width:100%;
    margin-bottom:10px;


    & input[type=radio] {
        display: none;
    }

    & label{
        display: block;
        width:100%;
        padding: 6% 2%;
        box-sizing: border-box;
        border-radius:6px;
        text-align:center; 
    }

    & label .chooseTit{
        font-size:16px;
        font-weight:${noto_b};
    }

    & label .priceText{
        font-size:11px;
    }

    & .subText{
        display:inline-block;
        margin: 2% 0 3%;
        color:#666;
        text-align:center;
    }
     

    & input[type=radio] + label {
        border: 1px solid ${color_middleGray};
        color:${color_darkGray};
        transition: all 0.3s;
    }
  
    & input[type=radio]:checked + label {
        color: ${color_pointOrange};
        background-color:#F4E6E1;
        border: 1px solid ${color_pointOrange};
    }


    @media screen and (min-width: 801px){
        & label{
            width:800px; 
            padding: 20px;
        }

        & label .chooseTit{
            font-size:14px;
        }

        & label .priceText{
            font-size:14px;
        }
    }
`;


// 글자+서브글자를 선택하는 라벨에 사용 (차량 선택)
export const estimate_chooseTextDetail = styled.div`
    width:100%;
    margin-bottom:10px;
    display:flex;
    align-items:center;

    & input[type=radio] {
        display: none;
    }

    & label{
        display: block;
        width:28%;
        min-width:120px; 
        padding: 5% 2%;
        margin-right:2%;
        box-sizing: border-box;
        border-radius:6px;
        text-align:center; 
    }

    & label .chooseTit{
        font-size:16px;
        font-weight:${noto_b};
    }

    & label .priceText{
        font-size:11px;
    }

    & .subText{
        color:${color_darkMiddleGray};
    }
     
    & input[type=radio] + label {
        border: 1px solid ${color_middleGray};
        color:${color_darkGray};
        transition: all 0.3s;
    }
  
    & input[type=radio]:checked + label {
        color: ${color_pointOrange};
        background-color:#F4E6E1;
        border: 1px solid ${color_pointOrange};
    }


    @media screen and (min-width: 801px){
        & label{
            width:180px; 
            padding: 20px;
        }

        & label .chooseTit{
            font-size:14px;
        }

        & label .priceText{
            font-size:14px;
        }
    }
`;

// +- 숫자 선택을 하는 라벨에 사용 (도우미, 상복)
export const estimate_chooseOneWrite = styled.div`
    width:340px; 
    padding:5% 3%;
    margin-bottom:3%;
    border-radius:6px; 
    border:1px solid ${color_middleGray};
    display:flex;
    align-items:center;

    & label{
        width:120px; 
        font-size:16px;
    }

    .plus_minus_div{
        display:flex;
        margin-left: 10px;
    }

    .plus_minus_div button{
        width:34px;
        height:34px;
    }

    .plus_minus_div button img{
        width:100%;
        height:auto;
        object-fit:cover; 
    }


  



    .plus_minus_div input{
        text-align:center;
        font-size:16px; 
    }

    & input{
        color:${color_pointOrange};
        width:60px;
    }

    @media screen and (min-width: 801px){
        width:800px; 
        padding:20px 15px;
        margin-bottom:15px;

        & label{
            font-size:14px;
        }
    } 

`;


export const estimate_resultCheck = styled.div`
    min-width:320px;
    margin:5% auto;

    & .estimate_resultTit{
        width:100%;
        height: 60px;
        padding:0 5%;
        font-size:22px;
        font-weight:${noto_m};
        line-height:60px;
        color:${color_white};
        text-align:center; 
        background-color:${color_darkGray};
        border-top-left-radius:20px;
        border-top-right-radius:20px;
    }

    & ul{
        border-left: 1px solid ${color_lightGray};
        border-right: 1px solid ${color_lightGray};
        border-bottom: 1px solid ${color_lightGray};
        border-bottom-left-radius:20px;
        border-bottom-right-radius:20px;
    }

    & li{
        padding:5% 0 ; 
        margin:0 5%;
        display:flex;
        justify-content: space-between;
        border-bottom:1px solid ${color_lightGray};
    }

    & .estimate_resultSum{
        font-size: 18px;
        font-weight: ${noto_b};
        border-bottom:none;
    }

    & .estimate_resultSum span{
        font-size: 22px;
        color:${color_pointOrange};
    }


    @media screen and (min-width: 700px){
        & li{
            padding:2.5% 0 ; 
        }
    }

    @media screen and (min-width: 1200px){
        & li{
            padding:5% 0 ; 
        }
    }



`;



export const estimate_resultSend = styled.div`
    min-width:320px;
    margin:5% auto;

    & .estimate_resultTit{
        width:100%;
        height: 60px;
        padding:0 5%;
        font-size:22px;
        font-weight:${noto_m};
        line-height:60px;
        color:${color_white};
        text-align:center; 
        background-color:${color_darkGray};
        border-top-left-radius:20px;
        border-top-right-radius:20px;
    }

    & div{
        border-left: 1px solid ${color_lightGray};
        border-right: 1px solid ${color_lightGray};
        border-bottom: 1px solid ${color_lightGray};
        border-bottom-left-radius:20px;
        border-bottom-right-radius:20px;
    }

    & div .estimate_resultSendWrite{
        display:flex;
        padding:5% 0;
        margin: 0 5%;
        border-bottom: 1px solid ${color_lightGray}; 
    }

    & div .estimate_resultSendWrite label{
        width:20%;
        padding:2%; 
        font-weight: ${noto_m};
    }

    & div .estimate_resultSendWrite input{
        width:100%;
        padding:2%; 
    }

    & .resultSendBtn{
        width:30%;
        padding: 5%;
        margin: 20px auto;
        display: block;
        color:${color_pointOrange};
        border: 1px solid ${color_pointOrange};
        border-radius:10px; 
    }

    @media screen and (min-width: 700px){
        & .resultSendBtn{
            padding: 3%;
        }

        & div .estimate_resultSendWrite{
            padding:2% 0;
        }
    }

    @media screen and (min-width: 1200px){
        & .resultSendBtn{
            padding: 4%;
        }

        & div .estimate_resultSendWrite{
            padding:4% 0;
        }
    }

`;

// 모달청
// 모달창 이미지 보이는 것은 han.css에 있음
export const modalStyle = {
    content: {
        position:'fixed',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:'60%',
        height: '75%',
    },
};

export const modalTitBox = styled.div`
    display:flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: 2% 0;
    margin-bottom: 4%; 
    border-bottom: 1px solid ${color_subMiddleGray}; 

    & .modalTit{
        font-size: 18px;
    }

    & button img{
        width:16px;
        height:16px;
        object-fit: cover;
    }

    @media screen and (min-width: 1200px){
        & .modalTit{
            font-size: 24px;
        }
    
        & button img{
            width:24px;
            height:24px;
            object-fit: cover;
        }
    }
`;

export const modalImage = styled.div`
    width:100%;
    height:auto;  

    & img{
        width:100%;
        height:auto;
        transition:all 0.3s; 
    }

    & .estimate_modal_pc{
        display:none;
    }

    @media screen and (min-width: 480px){
        & .estimate_modal_mo{
            display:none;
        }

        & .estimate_modal_pc{
            display:block;
        }

        display:flex;
        justify-content: center;
        align-items: center;
        align-content: center;   
        & img{
            width:50%;
            height:auto;
            transition:all 0.3s; 
        }
    }

`;

// 모달창 내부 선택 버튼
export const estimate_modal_button = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    & button{
        display:inline-block; 
        font-size: 14px;
        margin:5px;
        padding:10px;
        color:${color_darkGray};
        text-align: center;
        width:100px;
        border: 1px solid ${color_middleGray};
        border-radius:6px; 
    }
`;

// 견적내기 밑에 고정
export const estimate_fix = styled.div`
    width:100%;
    min-width:360px; 
    display:flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    
    position:sticky;
    left: 0;
    right: 0;
    bottom: 0;
    padding:20px 35px; 
    background-color: #f5f5f5;
    box-shadow:0 -3px 12px 0 rgba(0, 0, 0, 0.3);

    & .estimate_resultSum{
        font-size: 16px;
        font-weight: ${noto_b};
        border-bottom:none;
    }

    & .estimate_resultSum span{
        font-size: 20px;
        color:${color_pointOrange};
    }

    @media screen and (min-width: 1200px){
        display:none;
    }
`;





// main - 회원가입
export const main_joinTitBox = styled.div`
    padding: 30px;
    text-align: center;

    & .printMainTit{
        font-size: 26px; 
        line-height: 26px;
        margin: 0 auto 10px;
        font-weight: ${noto_m};
    }

    & .printMainSubTit{
        font-size: 14px;
        color: ${color_darkGray};
    }
`;

export const main_joinInfoWrap = styled.div`
    width:320px; 
    margin:3% auto; 
    transition:all 0.3s; 

    & button{
        display:block; 
        color: ${color_white};
        padding:16px 60px;
        margin: 30px auto;
        background-color: ${color_pointOrange};
        cursor: pointer;
    }

    @media screen and (min-width: 801px){
        width:800px;
    }
`;

export const main_joinInfoAdd = styled.div`
    padding-left:2%;
    margin-bottom:4%;
    padding-bottom:4%;
    border-bottom:1px solid ${color_middleGray};

    & label{
        display:inline-block;
        width:27.5%;
        padding:6px 0;
        margin-right:1%;
        font-weight: ${noto_m};
    }

    & label span{
        color: ${color_pointRed};
        font-style: normal;
    }

    & input{
        display:inline-block;
        width:70%;
        color: ${color_darkGray};
        padding:8px;
        background-color:${color_bgGray};
        box-sizing: border-box;
    }

    & .main_joinInfoAdd_zipCode{
        margin-bottom:1.2%;
    }

    & .main_joinInfoAdd_customerAddress{
        margin-left:28.5%;
        margin-bottom:1.2%;
    }

    & select{
        width:70%;
        padding:8px;
        color: ${color_darkGray};
        border:none; 
        background-color:${color_bgGray};
    }

`;

export const main_joinInfo_errorBox = styled.p`
    font-size: 12px;
    line-height: 30px; 
    color: ${color_pointOrange};
    transition: all 0.3s;
`;




/* main - 개인정보 동의박스 */
export const main_agreementWrap = styled.div`
    margin-top:10%; 
    padding: 3%;
`;

export const main_agreementBox = styled.div`
    margin-bottom:5%; 

    & div{}

    & div input{}

    & div label{
        font-size:14px;
    }

    & div label span{
        color:${color_pointRed};
    }

    & textarea{
        display:inline-block;
        width:100%;
        height:140px;
        border:none;
        padding:2%;
        box-sizing: border-box;
        resize:none;
        margin:1% 0;
        background-color: ${color_bgGray};
        
    }
`;



//상단 스크롤
export const PositionContainer = styled.div`
    position: fixed;
    width: 100%;
    bottom:14%;
    z-index: 1000;

    // 데스크탑
    @media screen and (min-width: 480px) {
        display:none;
    }
`;

export const btn_top = styled.button.attrs(() => ({
    type: 'button',
    title: '맨 위로 가기',
    'aria-label': '맨 위로 가기'
  }))`
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: transparent;
  
    // 아이콘 스타일링
    img {
      width:100px;
      height:100px;
      opacity: .7;
      filter: drop-shadow(2px 2px 10px var(--color-gray2));
  
      rect {
        fill: var(--color-body);
      }
  
      line {
        fill: none;
        stroke: var(--color-text);
        opacity: 1;
      }
    }
`;


//퀵메뉴
export const quickContainer_btn = styled.button.attrs(() => ({
    type: 'button',
    title: '메뉴 바로가기',
    'aria-label': '메뉴 바로가기'
  }))`
    position:fixed; 
    bottom:3%;
    right:50%;
    transform: translateX(50%);
    z-index:10000;
    width:86%;
    height:80px;
    border-radius:200px; 
    background-color:${color_white};
    box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.3);
    transition: all 0.3s;

    & p{
        width:80%;
        margin:0 auto; 
        display:flex;
        justify-content: space-between;
    }

    & img{
        width:74px;
        height:auto;
        transition:all 0.3s; 
    }

    .icon-default_mo{
        display:block;
    }

    .icon-default_pc{
        display:none;
    }




    @media screen and (min-width: 700px){
        right:2%;
        transform: translateX(0);
        width:70px;
        height:auto;
        background-color:${color_darkGray};
        box-shadow: 1px 3px 5px 2px rgba(0, 0, 0, 0.3);
        border-radius:0px; 

        & p{
            width:100%;
            flex-direction: column;
            justify-content: center;
        }

        .icon-default_mo{
            display:none;
        }
    
        .icon-default_pc{
            display:block;
        }

        & .icon-estimate:hover, .icon-inquiry:hover{
            opacity:0.85;
        }

        & .icon-estimate{
            border-top:1px solid ${color_lightGray};
            border-bottom:1px solid ${color_lightGray};
        }
    } 
`;

//----- 전화문의 -----
export const inquiry_mainWrap = styled.div`
    width: 100%;
    min-width: 360px;
    margin: 60px 0;

    & .inquiry_mainInner{
        width:100%;
        margin: 0 auto;
        padding:5%;
    }

    @media screen and (min-width: 700px){
        max-width:600px; 
        margin: 120px auto;

        & .inquiry_mainInner{
            width:100%;
            padding:0;
        }
    }
`;

//----- 전화문의 - 상세내용 -----
export const inquiry_resultSend = styled.div`
    & .inquiry_resultTit{
        width:100%;
        background-color:${color_darkGray};
        font-size:20px;
        font-weight:${noto_m};
        line-height:60px;
        color:${color_white};
        text-align:center;
        border-top-left-radius: 20px; 
        border-top-right-radius: 20px; 
    }

    & div{
        width: 100%;
        border-left: 1px solid ${color_darkMiddleGray};
        border-right: 1px solid ${color_darkMiddleGray};
        border-bottom: 1px solid ${color_darkMiddleGray};
        border-bottom-left-radius: 20px; 
        border-bottom-right-radius: 20px; 
    }

    & div .inquiry_resultSendWrite{
        display:flex;
        padding:4% 0;
        margin: 0 5%;
        border-bottom: 1px solid ${color_middleGray}; 
    }

    & div .inquiry_resultSendWrite label{
        width:18%;
        padding:2%; 
        font-weight: ${noto_m};
    }

    & div .inquiry_resultSendWrite input{
        width: 78%;
        padding:2%;
    }

    & .resultSendBtn{
        width:50%;
        padding: 4%;
        margin: 5% auto;
        display: block;
        color:${color_pointOrange};
        border: 1px solid ${color_pointOrange};
        border-radius:10px; 
    }

    @media screen and (min-width: 700px){

        & .inquiry_resultTit{
            font-size:24px;
            border-top-left-radius: 14px; 
            border-top-right-radius: 14px; 
        }

        & div{
            border-bottom-left-radius: 14px; 
            border-bottom-right-radius: 14px; 
        }

        & div .inquiry_resultSendWrite{
            padding:14px 0;
            margin: 0 14px;
        }

        & div .inquiry_resultSendWrite label{
            width:80px;
            padding:10px; 
            font-weight: ${noto_m};
        }

        & div .inquiry_resultSendWrite input{
            width: 460px;
            padding:10px;
        }

        & .resultSendBtn{
            width:300px;
            padding: 16px;
            margin: 20px auto;
            border-radius:8px;
            transition:all 0.3s; 
        }

        & .resultSendBtn:hover{
            background-color:${color_pointOrange};
            color:${color_white};

        }
    }


`;