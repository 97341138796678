import React from 'react';
import {
    get_kakao_token,
    get_kakao_login_data,
    check_kakao_login
} from "../../Axios";

function KakaoLogin() {

    const code = new URL(window.location.href).searchParams.get("code");
    
    get_kakao_token(code).then((result)=>{
        get_kakao_login_data(result).then((result_login_data)=>{
            check_kakao_login(
                result_login_data.id,
                result_login_data.kakao_account.email,
                result_login_data.properties.nickname
            ).then((check_data)=>{
                if(check_data.customer_join_level === 1){
                    window.location.href=`/Join?customer_ID=${check_data.customer_ID}`
                }else{
                    alert('로그인이 완료되었습니다.');
                    window.location.href=`/Estimate`
                }
            });
        });
    });

  return (
    <>
    </>
  );
}

export default KakaoLogin;
