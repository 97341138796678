import React, {useEffect, useState, useRef} from 'react';
import AdminHeader from '../Section/AdminHeader';
import { ReactTabulator } from 'react-tabulator';
import * as H from '../../Styles/StyleComponentHan';
import {
  get_coffin,
  update_coffin_order,
} from "../../Axios";

export function AdminCoffinManagement() {  

  const [coffinData, setCoffinData] = useState([]); 

  const tabulatorRef = useRef();

  const row_moved_function = (e, row) => {  

    let temp_coffin_order_array = [];
    let coffin_order= 0;

    tabulatorRef.current.optionsList.table.rowManager.activeRows.forEach(element =>{
      temp_coffin_order_array.push({
        coffin_order: coffin_order,
        coffin_ID: element.data.coffin_ID,
      })
      coffin_order++;
    });
    update_coffin_order(temp_coffin_order_array).then(result=>{
      if(result === undefined){
        alert('순서가 변경되지 않았습니다. 관리자한테 확인해주세요.');
      }
    })    
  };

  const check_coffin_edit = () =>{
    if(tabulatorRef.current.getSelectedData().length > 1){
      alert('수정할 데이터를 하나만 선택해주세요.');
    } else if(tabulatorRef.current.getSelectedData().length === 0){
      alert('수정할 데이터를 선택해주세요.');
    } else{
      window.location.href = `/AdminCoffinManagementAdd?coffin_ID=${tabulatorRef.current.getSelectedData()[0].coffin_ID}`;
    }
  }

  useEffect(() => {
    get_coffin().then(result_data=>{
      setCoffinData(result_data);
    })
  },[]);

  return (
    <H.admin_MainWrap>
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>견적 메뉴 관리 - 관 관리</p>
            <div>
              <H.admin_addBtn
                onClick={()=>{window.location.href = "/AdminCoffinManagementAdd";}}>등록
              </H.admin_addBtn>
              <H.admin_changeBtn
                onClick={()=>{check_coffin_edit();}}>수정
              </H.admin_changeBtn>
            </div>
            </H.admin_rightTitInner>
          </H.admin_rightTitBox>
          
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.listInfoText>
              <p className="listInfoTextBold">사용 방법 안내</p>
              <p>1. 리스트 중 상단이 정렬 우선순위가 높습니다.</p>
              <p>2. 왼쪽 3선 작대기를 클릭 ➡️ 드래그하여 위/아래로 이동할 수 있습니다.</p>
              <p>3. 삭제는 안되며 수정만 가능합니다.</p>
            </H.listInfoText>
            {
            coffinData.length !== 0 ? 
            <ReactTabulator
              onRef={(r) => (tabulatorRef.current = r.current)}
              data={coffinData}
              columns={[
                {rowHandle:true, formatter:"handle", headerSort:false, frozen:true, width:30, minWidth:30},
                {formatter:"rowSelection", titleFormatter:"rowSelection", headerSort:false, 
                  cellClick:function(e, cell){
                    cell.getRow().toggleSelect();
                  }
                },
                { title: "ID", field: "coffin_ID", headerSort:false},
                { title: "구분", field: "coffin_division", headerSort:false},
                { title: "관 명칭", field: "coffin_name", headerSort:false},
                { title: "가격", field: "coffin_price", headerSort:false, formatter:"money", 
                  formatterParams:
                  {
                    decimal:",",
                    thousand:",",
                    symbol:"원",
                    symbolAfter:"p",
                    negativeSign:true,
                    precision:false
                  },
                },
                { title: "사용 여부", field: "coffin_status", headerSort:false},
                { title: "등록 일자", field: "coffin_registe", headerSort:false},
                { title: "수정 일자", field: "coffin_modified", headerSort:false},
              ]}
              layout={"fitData"}
              options={{
                movableRows: true,
              }}
              events={{
                rowMoved:row_moved_function
              }}
              />
            : 
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            }
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>

      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

export default AdminCoffinManagement;
