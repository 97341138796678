import React, {useEffect, useState} from 'react';
import * as H from '../../Styles/StyleComponentHan';
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import Spinner from '../../CommonSection/Spiner';
import {
  insert_inquiry,
  get_session_data_homepage
} from "../../Axios";
import {
  removeWhitespacePhone,
  validatePhone
} from "../../Validation";

function Inquiry() {
  
  const [customerID, setCustomerID] = useState(0); 
  const [inquiryCustomerName, setInquiryCustomerName] = useState('');
  const [inquiryCustomerPhoneNumber, setInquiryCustomerPhoneNumber] = useState('');
  const [inquiryAddress, setInquiryAddress] = useState('');

  //Status Data
  const [spinnerLoadingStatus, setSpinnerLoadingStatus] = useState(false);
 
  const inquiryAdd = () =>{
    if(inquiryCustomerName === ''){alert('이름을 입력해 주세요.');}
    else if(inquiryCustomerPhoneNumber === ''){alert('연락처를 입력해 주세요.');}
    else if(inquiryAddress === 0){alert('지역을 입력해 주세요.');}
    else{
      setSpinnerLoadingStatus(true);
      insert_inquiry(
        customerID,
        inquiryCustomerName,
        inquiryCustomerPhoneNumber,
        inquiryAddress
      ).then(result_data=>{   
        alert('등록이 완료되었습니다. 확인 후 담당자가 연락을 진행할 예정입니다.');
        window.location.href = '/Inquiry';
      })    
    }
  }

  useEffect(() => {
    get_session_data_homepage().then((result)=>{
      if(result.customer_ID !== '' && result.customer_ID !== undefined){
        setCustomerID(result.customer_ID);
        setInquiryCustomerName(result.customer_name);
        setInquiryCustomerPhoneNumber(result.customer_phone_number);
      }
    });
  },[]);

  
  return (
    <>
      <Header/>
      <Spinner status = {spinnerLoadingStatus}/>
      <H.inquiry_mainWrap>
        <div className="inquiry_mainInner">
          <H.inquiry_resultSend>
            <p className="inquiry_resultTit">전화문의</p>
            <div>
              <p className="inquiry_resultSendWrite">
                <label htmlFor={`inquiry_customer_name`}>이름</label>
                <input 
                  id = 'inquiry_customer_name' 
                  name = 'inquiry_customer_name' 
                  placeholder = "이름을 입력해 주세요." 
                  type = 'text' 
                  maxLength={10}
                  onChange={(e)=>{
                    setInquiryCustomerName(e.target.value);
                  }}
                  value={inquiryCustomerName}
                />
              </p>

              <p className="inquiry_resultSendWrite">
                <label htmlFor={`inquiry_customer_phone_number`}>연락처</label>
                <input 
                  id = 'inquiry_customer_phone_number' 
                  name = 'inquiry_customer_phone_number' 
                  placeholder = "휴대폰 형식으로 번호를 입력해 주세요." 
                  type = 'text' 
                  maxLength={13}
                  onChange={(e)=>{
                    setInquiryCustomerPhoneNumber(removeWhitespacePhone(e.target.value))
                  }} 
                  onBlur={(e)=>{
                    if(!validatePhone(e.target.value)){
                      alert('연락처는 휴대폰 형식으로 입력해 주세요.')
                      setInquiryCustomerPhoneNumber(''); 
                    }
                  }}
                  value={inquiryCustomerPhoneNumber}
                />
              </p>

              <p className="inquiry_resultSendWrite">
                <label htmlFor={`inquiry_address`}>지역</label>
                <input 
                  id = 'inquiry_address' 
                  name = 'inquiry_address' 
                  placeholder = "지역을 입력해 주세요." 
                  type = 'text' 
                  maxLength={250}
                  onChange={(e)=>{
                    setInquiryAddress(e.target.value);
                  }}
                  value={inquiryAddress}
                />
              </p>
              <button className = "resultSendBtn" onClick={()=>{inquiryAdd()}}>문의하기</button>
            </div>
          </H.inquiry_resultSend>
            
        </div>
      </H.inquiry_mainWrap>
      <Footer/>
    </>
  );
}

export default Inquiry;
