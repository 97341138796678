import React, {useState, useEffect} from 'react';
import AdminHeader from '../Section/AdminHeader';
import {
  get_other_amount_one,
  update_other_amount,
} from "../../Axios";
import {validateNum} from "../../Validation";
import * as H from '../../Styles/StyleComponentHan';

export function AdminOtherAmountAdd() {  

  const [otherAmountHelperTime, setOtherAmountHelperTime] = useState('');
  const [otherAmountHelperPrice, setOtherAmountHelperPrice] = useState('');
  const [otherAmountMourningClothesPriceMan, setOtherAmountMourningClothesPriceMan] = useState('');
  const [otherAmountMourningClothesPriceWoan, setOtherAmountMourningClothesPriceWoan] = useState('');
  const [otherAmountPersonnelExpenses, setOtherAmountPersonnelExpenses] = useState('');
  const [otherAmountAdmissionFee, setOtherAmountAdmissionFee] = useState('');
  const [ohterAmountOtherSupplies, setOhterAmountOtherSupplies] = useState('');

  const [otherAmountHelperTimeError, setOtherAmountHelperTimeError] = useState('');
  const [otherAmountHelperPriceError, setOtherAmountHelperPriceError] = useState('');
  const [otherAmountMourningClothesPriceManError, setOtherAmountMourningClothesPriceManError] = useState('');
  const [otherAmountMourningClothesPriceWoanError, setOtherAmountMourningClothesPriceWoanError] = useState('');
  const [otherAmountPersonnelExpensesError, setOtherAmountPersonnelExpensesError] = useState('');
  const [otherAmountAdmissionFeeError, setOtherAmountAdmissionFeeError] = useState('');
  const [ohterAmountOtherSuppliesError, setOhterAmountOtherSuppliesError] = useState('');

  const other_amount_check = () =>{
    let continue_add = true;
    if(otherAmountHelperTime === ''){setOtherAmountHelperTimeError('도우미 최소시간을 입력해 주세요.'); continue_add = false;}else{setOtherAmountHelperTimeError('');}
    if(otherAmountHelperPrice === ''){setOtherAmountHelperPriceError('도우미 시간당 가격을 입력해 주세요.'); continue_add = false;}else{setOtherAmountHelperPriceError('');}
    if(otherAmountMourningClothesPriceMan === ''){setOtherAmountMourningClothesPriceManError('남자상복 가격을 입력해 주세요.'); continue_add = false;}else{setOtherAmountMourningClothesPriceManError('');}
    if(otherAmountMourningClothesPriceWoan === ''){setOtherAmountMourningClothesPriceWoanError('여자상복 가격을 입력해 주세요.'); continue_add = false;}else{setOtherAmountMourningClothesPriceWoanError('');}
    if(otherAmountPersonnelExpenses === ''){setOtherAmountPersonnelExpensesError('장례지도사 가격을 입력해 주세요.'); continue_add = false;}else{setOtherAmountPersonnelExpensesError('');}
    if(otherAmountAdmissionFee === ''){setOtherAmountAdmissionFeeError('입관비 가격을 입력해 주세요.'); continue_add = false;}else{setOtherAmountAdmissionFeeError('');}
    if(ohterAmountOtherSupplies === ''){setOhterAmountOtherSuppliesError('기타용품 가격을 입력해 주세요.'); continue_add = false;}else{setOhterAmountOtherSuppliesError('');}

  if(continue_add){
      update_other_amount(
        otherAmountHelperTime.replace(/,/g, ""),
        otherAmountHelperPrice.replace(/,/g, ""),
        otherAmountMourningClothesPriceMan.replace(/,/g, ""),
        otherAmountMourningClothesPriceWoan.replace(/,/g, ""),
        otherAmountPersonnelExpenses.replace(/,/g, ""),
        otherAmountAdmissionFee.replace(/,/g, ""),
        ohterAmountOtherSupplies.replace(/,/g, ""),
      ).then(()=>{
        alert('수정이 완료되었습니다.');
        });
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  useEffect(() => {
    get_other_amount_one().then((result) => {
      setOtherAmountHelperTime(result[0].other_amount_helper_time.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      setOtherAmountHelperPrice(result[0].other_amount_helper_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      setOtherAmountMourningClothesPriceMan(result[0].other_amount_mourning_clothes_price_man.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      setOtherAmountMourningClothesPriceWoan(result[0].other_amount_mourning_clothes_price_woman.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      setOtherAmountPersonnelExpenses(result[0].other_amount_personnel_expenses.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      setOtherAmountAdmissionFee(result[0].other_amount_admission_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      setOhterAmountOtherSupplies(result[0].ohter_amount_other_supplies.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    })
  },[]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>견적 메뉴 관리 - 기타금액 입력</p>
            <div> 
              <H.admin_addBtn
                onClick={()=>{
                  other_amount_check();
                }}
              >
                수정
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>

            <H.admin_labelBox>
              <label htmlFor='other_amount_helper_time'>도우미 최소 시간<span> * </span></label>
              <input 
                type='text' 
                id = 'other_amount_helper_time' 
                maxLength={2} 
                onChange={(e)=>{
                  if(!validateNum(e.target.value.replace(/,/g, ""))){
                    if(e.target.value > 20){
                      alert('20시간 이후는 입력할 수 없습니다.')
                      setOtherAmountHelperTime('')
                    }else{
                      setOtherAmountHelperTime(
                        e.target.value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      );
                    }
                  }} 
                }
                placeholder = "도우미 최소시간을 입력해 주세요."
                value={otherAmountHelperTime}
              />
              <H.admin_errorBox className="marginLeft150">{otherAmountHelperTimeError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='other_amount_helper_price'>도우미 시간 당 가격<span> * </span></label>
              <input 
                type='text' 
                id = 'other_amount_helper_price' 
                maxLength={10} 
                onChange={(e)=>{
                  if(!validateNum(e.target.value.replace(/,/g, ""))){
                    setOtherAmountHelperPrice(
                      e.target.value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  }} 
                }
                placeholder = "도우미 시간당 가격을 입력해 주세요."
                value={otherAmountHelperPrice}
              />
              <H.admin_errorBox className="marginLeft150">{otherAmountHelperPriceError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='other_amount_mourning_clothes_price_man'>남자상복 기본 가격<span> * </span></label>
              <input 
                type='text' 
                id = 'other_amount_mourning_clothes_price_man' 
                maxLength={10} 
                onChange={(e)=>{
                  if(!validateNum(e.target.value.replace(/,/g, ""))){
                    setOtherAmountMourningClothesPriceMan(
                      e.target.value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  }} 
                }
                placeholder = "남자상복 기본 가격을 입력해 주세요."
                value={otherAmountMourningClothesPriceMan}
              />
              <H.admin_errorBox className="marginLeft150">{otherAmountMourningClothesPriceManError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='other_amount_mourning_clothes_price_woman'>여자상복 기본 가격<span> * </span></label>
              <input 
                type='text' 
                id = 'other_amount_mourning_clothes_price_woman' 
                maxLength={10} 
                onChange={(e)=>{
                  if(!validateNum(e.target.value.replace(/,/g, ""))){
                    setOtherAmountMourningClothesPriceWoan(
                      e.target.value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  }} 
                }
                placeholder = "여자상복 기본 가격을 입력해 주세요."
                value={otherAmountMourningClothesPriceWoan}
              />
              <H.admin_errorBox className="marginLeft150">{otherAmountMourningClothesPriceWoanError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='other_amount_personnel_expenses'>장례지도사 인건비<span> * </span></label>
              <input 
                type='text' 
                id = 'other_amount_personnel_expenses' 
                maxLength={10} 
                onChange={(e)=>{
                  if(!validateNum(e.target.value.replace(/,/g, ""))){
                    setOtherAmountPersonnelExpenses(
                      e.target.value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  }} 
                }
                placeholder = "장레지도사 인건비 기본 가격을 입력해 주세요."
                value={otherAmountPersonnelExpenses}
              />
              <H.admin_errorBox className="marginLeft150">{otherAmountPersonnelExpensesError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='other_amount_admission_fee'>입관비 기본 가격<span> * </span></label>
              <input 
                type='text' 
                id = 'other_amount_admission_fee' 
                maxLength={10} 
                onChange={(e)=>{
                  if(!validateNum(e.target.value.replace(/,/g, ""))){
                    setOtherAmountAdmissionFee(
                      e.target.value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  }} 
                }
                placeholder = "입관비 기본 가격을 입력해 주세요."
                value={otherAmountAdmissionFee}
              />
              <H.admin_errorBox className="marginLeft150">{otherAmountAdmissionFeeError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='ohter_amount_other_supplies'>기타용품 기본 가격<span> * </span></label>
              <input 
                type='text' 
                id = 'ohter_amount_other_supplies' 
                maxLength={10} 
                onChange={(e)=>{
                  if(!validateNum(e.target.value.replace(/,/g, ""))){
                    setOhterAmountOtherSupplies(
                      e.target.value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  }} 
                }
                placeholder = "기타용품 기본 가격을 입력해 주세요."
                value={ohterAmountOtherSupplies}
              />
              <H.admin_errorBox className="marginLeft150">{ohterAmountOtherSuppliesError}</H.admin_errorBox>
            </H.admin_labelBox>

          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default AdminOtherAmountAdd;
