import React, {useEffect, useState, useRef} from 'react';
import AdminHeader from '../Section/AdminHeader';
import { ReactTabulator } from 'react-tabulator';
import * as H from '../../Styles/StyleComponentHan';
import {
  get_inquiry,
  get_inquiry_search,
  delete_inquiry,
} from "../../Axios";
import detail_icon from '../../Images/Etc/detail_icon.png';
import {
  removeWhitespacePhone,
} from "../../Validation";


export function AdminInquiryManagement() {  

  const [inquiryData, setInquiryData] = useState([]); 

  //검색 데이터
  const [inquiryCustomerNameSearch, setInquiryCustomerNameSearch] = useState('');
  const [inquiryCustomerPhoneNumberSearch, setInquiryCustomerPhoneNumberSearch] = useState('');

  //테이블 데이터
  const tabulatorRef = useRef();

  //이거는 삭제버튼 눌렀을 때 체크하는 함수 입니다.
  const check_inquiry_delete = () =>{
    if(tabulatorRef.current.getSelectedData().length === 0){
      alert('삭제할 데이터를 선택해주세요.');
    } else{
      //삭제할 롤링 배너 고유ID를 담을 임시배열
      let temp_delete_array = [];
      //forEact에 대한 설명은 위에 설명함
      tabulatorRef.current.getSelectedData().forEach((element)=>{
        temp_delete_array.push(element.inquiry_ID);
      })
      delete_inquiry(temp_delete_array).then((result)=>{
        //result === undefined 이라는 것은 백엔드에서 update 처리가 안될시 결과가 undefiend로 결과가 오는데 
        //그때 alert을 출력합니다. 
        if(result === undefined){
          alert('삭제가 되지 않았습니다. 관리자한테 확인해주세요.');
        }else{
          alert('삭제가 완료 되었습니다.');
          window.location.reload();
        }
      })
    }
  }

  //엑셀 다운로드
  const download_excel = () => {
    tabulatorRef.current.download("xlsx", "전화문의 관리.xlsx", {
      sheetName: "전화문의 관리"
    });
  }; 

  // 테이블에 사용하는 아이콘
  const detailIcon = function(){
    return `
<img 
  src=${detail_icon} 
  alt="디테일 이미지"
  style="width:15px;heihgt:15px"
/>`
  };

  //컨트롤 함수
  const form_check = () =>{
    get_inquiry_search(
      inquiryCustomerNameSearch,
      inquiryCustomerPhoneNumberSearch,
    ).then((result)=>{
      if(result.length === 0){
        alert('검색된 데이터가 없습니다.');
      }else{
        setInquiryData(result);
      }
    });
  }

  useEffect(() => {
    get_inquiry().then(result_data=>{
      setInquiryData(result_data);
    })
  },[]);

  return (
    <H.admin_MainWrap>
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>견적 메뉴 관리 - 고객 전화문의 관리</p>
            <div>
              <H.admin_changeBtn className='display_none'
                //삭제 버튼 눌렀을 시 실행되는 함수 위에 정의함
                onClick={()=>{check_inquiry_delete();}}>삭제
              </H.admin_changeBtn>
              <H.admin_deleteBtn
                //수정 버튼 눌렀을 시 실행되는 함수 위에 정의함
                onClick={()=>{download_excel();}}>엑셀 다운
              </H.admin_deleteBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>

        <H.admin_topSearch>
          <div className="admin_topSearchInner">
            <div className="admin_topSearchLabel">
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='inquiry_customer_name'>신청 고객명</label>
              <input 
                type='text' 
                id = 'inquiry_customer_name' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setInquiryCustomerNameSearch(event_data.target.value);
                }} 
                placeholder = "신청 고객명을 입력해 주세요."
                value={inquiryCustomerNameSearch}
              />
            </div>
          
            <div className="admin_topSearchLabel">
              {/* htmlFor에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고*/}
              <label htmlFor='inquiry_customer_phone_number'>신청 전화번호</label>
              <input 
                type='text' 
                id = 'inquiry_customer_phone_number' 
                maxLength={20} 
                //onChange에 대한 내용은 src/Admin/Login/AdminLogin.js 파일 참고
                onChange={(event_data)=>{
                  setInquiryCustomerPhoneNumberSearch(removeWhitespacePhone(event_data.target.value));
                }} 
                placeholder = "신청 전화번호를 입력해 주세요."
                value={inquiryCustomerPhoneNumberSearch}
              />
            </div>
            <button
              //onClick event에 대한 내용은 src/Admin/Login.js 참고
              onClick={()=>{form_check(); }}>
              {/* 보증서 파라미터가 있는지 없는지에 따라 등록인지 수정인지 판단 */}
              검색
            </button>
          </div>
        </H.admin_topSearch>
          
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>
            <H.listInfoText>
            </H.listInfoText>
            {
            inquiryData.length !== 0 ? 
            <ReactTabulator
              onRef={(r) => (tabulatorRef.current = r.current)}
              data={inquiryData}
              columns={[
                {formatter:"rowSelection", titleFormatter:"rowSelection", headerSort:false, 
                  cellClick:function(e, cell){
                    cell.getRow().toggleSelect();
                  }
                },
                { title: "상세보기", formatter:detailIcon, hozAlign:"center", headerSort:false,
                cellClick:function(e, cell){
                  window.location.href = `/AdminInquiryManagementAdd?inquiry_ID=${cell.getRow().getData().inquiry_ID}`;
                }},
                { title: "ID", field: "inquiry_ID", headerSort:false},
                { title: "신청 고객명", field: "inquiry_customer_name", headerSort:false},
                { title: "신청 전화번호", field: "inquiry_customer_phone_number", headerSort:false},
                { title: "지역", field: "inquiry_address", headerSort:false},
                { title: "등록 일자", field: "inquiry_registe", headerSort:false},
                { title: "수정 일자", field: "inquiry_modified", headerSort:false},
              ]}
              options={{ 
                paginationSize:30,
                pagination:'local',
                paginationButtonCount:5, // 밑에 표시될 페이지 갯수
              }}
              layout={"fitData"}
              />
            : 
            <div>
              <p>데이터가 없습니다.</p>  
            </div>
            }
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap>
  );
}

export default AdminInquiryManagement;
