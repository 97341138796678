import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

/* 홈페이지 영역 */
import HomepageMain from './Homepage/Main/Main';
import OurStory from './Homepage/Service/OurStory';
import ServiceIntroduce from './Homepage/Service/ServiceIntroduce';
import FuneralEasy from './Homepage/Funeral/FuneralEasy';
import FuneralService from './Homepage/Funeral/FuneralService';
import FuneralDom from './Homepage/Funeral/FuneralDom';
import OurDo from './Homepage/Funeral/OurDo';
import FuneralAfter from './Homepage/Funeral/FuneralAfter';
import Estimate from './Homepage/Estimate/Estimate';
import Inquiry from './Homepage/Inquiry/Inquiry';
import WhyPtLife from './Homepage/WhyPT/WhyPtLife';
import Continue from './Homepage/WhyPT/Continue';
import NewsEvent from './Homepage/NewsEvent/NewsEvent';
import NewsEventDetail from './Homepage/NewsEvent/NewsEventDetail';
import Join from './Homepage/Customer/Join';
import Login from './Homepage/Customer/Login';
import KakaoLogin from './Homepage/Customer/KakaoLogin';
import NaverLogin from './Homepage/Customer/NaverLogin';

/* 관리자 영역 */
import AdminLogin from './Admin/Login/AdminLogin';
import AdminMain from './Admin/Main/AdminMain';
import MenuPermissionManagement from './Admin/SystemManagement/MenuPermissionManagement';
import MenuPermissionManagementAdd from './Admin/SystemManagement/MenuPermissionManagementAdd';
import UserManagement from './Admin/SystemManagement/UserManagement';
import UserManagementAdd from './Admin/SystemManagement/UserManagementAdd';
import AdminCustomerManagement from './Admin/Customer/AdminCustomerManagement';
import AdminCustomerManagementAdd from './Admin/Customer/AdminCustomerManagementAdd';
import AdminRollingBannerManagement from './Admin/MainManagement/AdminRollingBannerManagement';
import AdminRollingBannerManagementAdd from './Admin/MainManagement/AdminRollingBannerManagementAdd';
import AdminSmallBannerManagement from './Admin/MainManagement/AdminSmallBannerManagement';
import AdminSmallBannerManagementAdd from './Admin/MainManagement/AdminSmallBannerManagementAdd';
import AdminBigBannerManagement from './Admin/MainManagement/AdminBigBannerManagement';
import AdminOurStoryManagement from './Admin/ServiceManagement/AdminOurStoryManagement';
import AdminServiceIntroduceManagement from './Admin/ServiceManagement/AdminServiceIntroduceManagement';
import AdminFuneralEasyManagement from './Admin/FuneralManagement/AdminFuneralEasyManagement';
import AdminFuneralServiceManagement from './Admin/FuneralManagement/AdminFuneralServiceManagement';
import AdminFuneralDomManagement from './Admin/FuneralManagement/AdminFuneralDomManagement';
import AdminOurDoManagement from './Admin/FuneralManagement/AdminOurDoManagement';
import AdminFuneralAfterManagement from './Admin/FuneralManagement/AdminFuneralAfterManagement';
import AdminCoffinManagement from './Admin/EstimateManagement/AdminCoffinManagement';
import AdminCoffinManagementAdd from './Admin/EstimateManagement/AdminCoffinManagementAdd';
import AdminShroudManagement from './Admin/EstimateManagement/AdminShroudManagement';
import AdminShroudManagementAdd from './Admin/EstimateManagement/AdminShroudManagementAdd';
import AdminVehicleManagement from './Admin/EstimateManagement/AdminVehicleManagement';
import AdminVehicleManagementAdd from './Admin/EstimateManagement/AdminVehicleManagementAdd';
import AdminMourningClothesManagement from './Admin/EstimateManagement/AdminMourningClothesManagement';
import AdminMourningClothesManagementAdd from './Admin/EstimateManagement/AdminMourningClothesManagementAdd';
import AdminUrnManagement from './Admin/EstimateManagement/AdminUrnManagement';
import AdminUrnManagementAdd from './Admin/EstimateManagement/AdminUrnManagementAdd';
import AdminOtherAmountAdd from './Admin/EstimateManagement/AdminOtherAmountAdd';
import AdminEstimateManagement from './Admin/EstimateManagement/AdminEstimateManagement';
import AdminEstimateManagementAdd from './Admin/EstimateManagement/AdminEstimateManagementAdd';
import AdminInquiryManagement from './Admin/EstimateManagement/AdminInquiryManagement';
import AdminInquiryManagementAdd from './Admin/EstimateManagement/AdminInquiryManagementAdd';
import AdminWhyPtLifeManagement from './Admin/WhyPTManagement/AdminWhyPtLifeManagement';
import AdminContinueManagement from './Admin/WhyPTManagement/AdminContinueManagement';
import AdminNewsEventManagement from './Admin/NewsEventManagement/AdminNewsEventManagement';
import AdminNewsEventManagementAdd from './Admin/NewsEventManagement/AdminNewsEventManagementAdd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider 
    clientId={'750775844-p2i5vlpihbi4ao00rmtj1akkbv6929ht.apps.googleusercontent.com'}
  >
    <BrowserRouter>
      <Routes>
        {/* 홈페이지 영역*/}
        {/* 경로를 /라고 치면 HomepageMain으로 이동함 */}
        <Route path="/" element={<HomepageMain />} />{/* 홈페이지 메인화면 */}
        <Route path="/OurStory" element={<OurStory />} />{/* 홈페이지 우리들 이야기화면 */}
        <Route path="/ServiceIntroduce" element={<ServiceIntroduce />} />{/* 홈페이지 서비스 소개화면 */}
        <Route path="/FuneralEasy" element={<FuneralEasy />} />{/* 홈페이지 장례식 이제는 PT 받는 시대화면 */}
        <Route path="/FuneralService" element={<FuneralService />} />{/* 홈페이지 상조비교화면 */}
        <Route path="/FuneralDom" element={<FuneralDom />} />{/* 홈페이지 장례진행 절차화면 */}
        <Route path="/OurDo" element={<OurDo />} />{/* 홈페이지 우리가 하는 일화면 */}
        <Route path="/FuneralAfter" element={<FuneralAfter />} />{/* 홈페이지 장례후 행정 절차화면 */}
        <Route path="/Estimate" element={<Estimate />} />{/* 홈페이지 견적내기 화면 */}
        <Route path="/Inquiry" element={<Inquiry />} />{/* 홈페이지 전화문의 화면 */}
        <Route path="/WhyPtLife" element={<WhyPtLife />} />{/* 홈페이지 Why? PT상조화면 */}
        <Route path="/Continue" element={<Continue />} />{/* 홈페이지 지속가능성과 차별점화면 */}
        <Route path="/NewsEvent" element={<NewsEvent />} />{/* 홈페이지 뉴스 & 이벤트 화면 */}
        <Route path="/NewsEventDetail" element={<NewsEventDetail />} />{/* 홈페이지 뉴스 & 이벤트 상세화면 */}
        <Route path="/Join" element={<Join />} />{/* 홈페이지 회원가입화면 */}
        <Route path="/Login" element={<Login />} />{/* 홈페이지 로그인 화면 */}
        <Route path="/KakaoLogin" element={<KakaoLogin />} />{/* 홈페이지 카카오 로그인 화면 */}
        <Route path="/NaverLogin" element={<NaverLogin />} />{/* 홈페이지 네이버 로그인 화면 */}

        {/* 관리자페이지 영역*/}
        {/* 경로를 /AdminLogin이라고 치면 AdminLogin으로 이동함 */}
        <Route path="/AdminLogin" element={<AdminLogin />} /> {/* 관리자페이지 로그인화면 */}
        <Route path="/AdminMain" element={<AdminMain />} /> {/* 관리자페이지 메인화면 */}
        <Route path="/MenuPermissionManagement" element={<MenuPermissionManagement />} /> {/* 관리자페이지 메뉴권한 관리 */}
        <Route path="/MenuPermissionManagementAdd" element={<MenuPermissionManagementAdd />} /> {/* 관리자페이지 메뉴권한 등륵/수정 관리 */}
        <Route path="/UserManagement" element={<UserManagement />} /> {/* 관리자페이지 사용자 관리 */}
        <Route path="/UserManagementAdd" element={<UserManagementAdd />} /> {/* 관리자페이지 사용자 등륵/수정 관리 */}
        <Route path="/AdminCustomerManagement" element={<AdminCustomerManagement />} /> {/* 관리자페이지 회원 관리 */}
        <Route path="/AdminCustomerManagementAdd" element={<AdminCustomerManagementAdd />} /> {/* 관리자페이지 희원 상세보기 */}
        <Route path="/AdminRollingBannerManagement" element={<AdminRollingBannerManagement />} /> {/* 관리자페이지 롤링 배너관리 */}
        <Route path="/AdminRollingBannerManagementAdd" element={<AdminRollingBannerManagementAdd />} /> {/* 관리자페이지 롤링 배너등록/수정 메뉴 */}
        <Route path="/AdminSmallBannerManagement" element={<AdminSmallBannerManagement />} /> {/* 관리자페이지 PT상조 배너 */}
        <Route path="/AdminSmallBannerManagementAdd" element={<AdminSmallBannerManagementAdd />} /> {/* 관리자페이지 PT상조 배너등록/수정 메뉴 */}
        <Route path="/AdminBigBannerManagement" element={<AdminBigBannerManagement />} />{/* 관리자페이지 대형 이미지 배너 관리 */}
        <Route path="/AdminOurStoryManagement" element={<AdminOurStoryManagement />} /> {/* 관리자페이지 우리들 이야기 관리 */}
        <Route path="/AdminServiceIntroduceManagement" element={<AdminServiceIntroduceManagement />} /> {/* 관리자페이지 서비스 소개 관리 */}
        <Route path="/AdminFuneralEasyManagement" element={<AdminFuneralEasyManagement />} /> {/* 관리자페이지 장례식 이제는 PT 받는 시대 관리 */}
        <Route path="/AdminFuneralServiceManagement" element={<AdminFuneralServiceManagement />} /> {/* 관리자페이지 상조비교 관리 */}
        <Route path="/AdminFuneralDomManagement" element={<AdminFuneralDomManagement />} /> {/* 관리자페이지 장례진행 절차 관리 */}
        <Route path="/AdminOurDoManagement" element={<AdminOurDoManagement />} /> {/* 관리자페이지 우리가 하는 일 관리 */}
        <Route path="/AdminFuneralAfterManagement" element={<AdminFuneralAfterManagement />} /> {/* 관리자페이지 장례후 행정 절차 관리 */}
        <Route path="/AdminCoffinManagement" element={<AdminCoffinManagement />} /> {/* 관리자페이지 관 관리 */}
        <Route path="/AdminCoffinManagementAdd" element={<AdminCoffinManagementAdd />} /> {/* 관리자페이지 관 등록/수정 관리 */}
        <Route path="/AdminShroudManagement" element={<AdminShroudManagement />} /> {/* 관리자페이지 수의 관리 */}
        <Route path="/AdminShroudManagementAdd" element={<AdminShroudManagementAdd />} /> {/* 관리자페이지 수의 등록/수정 관리 */}
        <Route path="/AdminVehicleManagement" element={<AdminVehicleManagement />} /> {/* 관리자페이지 차량 관리 */}
        <Route path="/AdminVehicleManagementAdd" element={<AdminVehicleManagementAdd />} /> {/* 관리자페이지 차량 등록/수정 관리 */}
        <Route path="/AdminMourningClothesManagement" element={<AdminMourningClothesManagement />} /> {/* 관리자페이지 상복대여 관리 */}
        <Route path="/AdminMourningClothesManagementAdd" element={<AdminMourningClothesManagementAdd />} /> {/* 관리자페이지 상복대여 등록/수정 관리 */}
        <Route path="/AdminUrnManagement" element={<AdminUrnManagement />} /> {/* 관리자페이지 유골함 관리 */}
        <Route path="/AdminUrnManagementAdd" element={<AdminUrnManagementAdd />} /> {/* 관리자페이지 유골함 등록/수정 관리 */}
        <Route path="/AdminOtherAmountAdd" element={<AdminOtherAmountAdd />} /> {/* 관리자페이지 기타금액 관리 */}
        <Route path="/AdminEstimateManagement" element={<AdminEstimateManagement />} /> {/* 관리자페이지 견적 관리 */}
        <Route path="/AdminEstimateManagementAdd" element={<AdminEstimateManagementAdd />} /> {/* 관리자페이지 견적 상세보기 */}
        <Route path="/AdminInquiryManagement" element={<AdminInquiryManagement />} /> {/* 관리자페이지 전화문의 관리 */}
        <Route path="/AdminInquiryManagementAdd" element={<AdminInquiryManagementAdd />} /> {/* 관리자페이지 전화문의 상세보기 */}
        <Route path="/AdminWhyPtLifeManagement" element={<AdminWhyPtLifeManagement />} /> {/* 관리자페이지 Why? PT상조 관리 */}
        <Route path="/AdminContinueManagement" element={<AdminContinueManagement />} /> {/* 관리자페이지 지속가능성과 차별점 관리 */}
        <Route path="/AdminNewsEventManagement" element={<AdminNewsEventManagement />} /> {/* 관리자페이지 뉴스 & 이벤트 관리 */}
        <Route path="/AdminNewsEventManagementAdd" element={<AdminNewsEventManagementAdd />} /> {/* 관리자페이지 뉴스 & 이벤트 등륵/수정 관리 */}

      </Routes>
    </BrowserRouter>
  </GoogleOAuthProvider>

);
