import React from 'react';
import * as H from '../../Styles/StyleComponentHan';

import quickIconPc from '../../Images/Etc/quick_icon_new_pc.jpeg';
import quickIconMobile from '../../Images/Etc/quick_icon_new_mo.jpeg';
import quickEstimateIcon from '../../Images/Etc/quick_estimate_icon_new.png';
import quickInquiryIcon from '../../Images/Etc/quick_inquiry_icon_new.png';


function QuickEstimate() {
  return (
    <H.quickContainer_btn>
      <p>
        <img className="icon-default_mo" src={quickIconMobile} alt="퀵메뉴"/>
        <img className="icon-default_pc" src={quickIconPc} alt="퀵메뉴"/>
        <img className="icon-estimate" src={quickEstimateIcon} alt="견적내기" onClick={()=>{window.location.href='/Estimate';}}/>
        <img className="icon-inquiry" src={quickInquiryIcon} alt="전화문의" onClick={() => {window.location.href = '/Inquiry';}}/>
      </p>
    </H.quickContainer_btn>
  );
}

export default QuickEstimate;
