import React, {useState, useEffect} from 'react';
import AdminHeader from '../Section/AdminHeader';
import {
  insert_coffin,
  get_coffin_one,
  update_coffin,
  base_url_export,
} from "../../Axios";
import {validateNum} from "../../Validation";
import * as H from '../../Styles/StyleComponentHan';
import no_image from '../../Images/Etc/no_image.gif';

export function AdminCoffinManagementAdd() {  
  const params = new URLSearchParams(window.location.search);
  const coffin_ID_param = params.get("coffin_ID");

  const [coffinName, setCoffinName] = useState('');
  const [coffinDivision, setCoffinDivision] = useState('1');
  const [coffinStatus, setCoffinStatus] = useState('1');
  const [coffinPrice, setCoffinPrice] = useState('');
  const [coffinImage, setCoffinImage] = useState('');
  const [coffinImageURL, setCoffinImageURL] = useState('');
  const [coffinImageDetail, setCoffinImageDetail] = useState('');
  const [coffinImageDetailURL, setCoffinImageDetailURL] = useState('');
  const [coffinImageDetailMobile, setCoffinImageDetailMobile] = useState('');
  const [coffinImageDetailMobileURL, setCoffinImageDetailMobileURL] = useState('');
  const [coffinImageError, setCoffinImageError] = useState('');
  const [coffinImageDetailError, setCoffinImageDetailError] = useState('');
  const [coffinImageDetailMobileError, setCoffinImageDetailMobileError] = useState('');

  const [coffinNameError, setCoffinNameError] = useState('');
  const [coffinPriceError, setCoffinPriceError] = useState('');

  const coffin_check = () =>{
    let continue_add = true;
    if(coffinName === ''){setCoffinNameError('관 명칭을 입력해 주세요.'); continue_add = false;}else{setCoffinNameError('');}
    if(coffinPrice === ''){setCoffinPriceError('관 가격을 입력해 주세요.'); continue_add = false;}else{setCoffinPriceError('');}
    if(coffinImageURL === ''){setCoffinImageError('관의 이미지를 등록해 주세요.'); continue_add = false;}else{setCoffinImageError('');}
    if(coffinImageDetailURL === ''){setCoffinImageDetailError('관의 상세이미지(PC)를 등록해 주세요.'); continue_add = false;}else{setCoffinImageDetailError('');}
    if(coffinImageDetailMobileURL === ''){setCoffinImageDetailMobileError('관의 상세이미지(모바일)를 등록해 주세요.'); continue_add = false;}else{setCoffinImageDetailMobileError('');}

    if(continue_add){
      if(coffin_ID_param === null){
        insert_coffin(
          coffinName,
          coffinDivision,
          coffinPrice.replace(/,/g, ""),
          coffinImage,
          coffinImageDetail,
          coffinImageDetailMobile,
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/AdminCoffinManagement";
        });
      }else{
        update_coffin(
          coffin_ID_param,
          coffinName,
          coffinDivision,
          coffinPrice.replace(/,/g, ""),
          coffinImage,
          coffinImageDetail,
          coffinImageDetailMobile,
          coffinStatus,
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/AdminCoffinManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  useEffect(() => {
    if(coffin_ID_param !== null){
      get_coffin_one(coffin_ID_param).then((result) => {
        setCoffinName(result[0].coffin_name);
        setCoffinDivision(result[0].coffin_division);
        setCoffinStatus(result[0].coffin_status);
        setCoffinPrice(result[0].coffin_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        setCoffinImageURL(base_url_export + result[0].coffin_image);
        setCoffinImageDetailURL(base_url_export + result[0].coffin_image_detail);
        setCoffinImageDetailMobileURL(base_url_export + result[0].coffin_image_detail_mobile);
      })
    }
  },[coffin_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>견적 메뉴 관리 - 관 {coffin_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> 
              <H.admin_addBtn
                onClick={()=>{
                  coffin_check();
                }}
              >
                {coffin_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>

          <H.admin_labelBox>
              <label htmlFor='coffin_division'>관 구분<span> * </span></label>
              <select
                value={coffinDivision}
                onChange={(event_data)=>{
                  setCoffinDivision(event_data.target.value);
                }}
              >
                <option value='1'>화장용</option>
                <option value='2'>매장용</option>
              </select>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='coffin_name'>관 명칭<span> * </span></label>
              <input 
                type='text' 
                id = 'coffin_name' 
                maxLength={10} 
                onChange={(event_data)=>{
                  setCoffinName(event_data.target.value);
                }} 
                placeholder = "관 명칭을 입력해 주세요.(최대 10자)"
                value={coffinName}
              />
              <H.admin_errorBox className="marginLeft150">{coffinNameError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='coffin_price'>관 가격<span> * </span></label>
              <input 
                type='text' 
                id = 'coffin_price' 
                maxLength={10} 
                onChange={(e)=>{
                  if(!validateNum(e.target.value.replace(/,/g, ""))){
                    setCoffinPrice(
                      e.target.value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  }} 
                }
                placeholder = "관 가격을 입력해 주세요."
                value={coffinPrice}
              />
              <H.admin_errorBox className="marginLeft150">{coffinPriceError}</H.admin_errorBox>
            </H.admin_labelBox>

            {coffin_ID_param !== null &&  
            <H.admin_labelBox>
              <label htmlFor='coffin_status'>사용 여부</label>
              <select
                value={coffinStatus}
                onChange={(event_data)=>{
                  setCoffinStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
            </H.admin_labelBox>
            }

            <H.admin_labelImg>
              <p className="labelImgTit">관 이미지</p>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>이미지<span> * </span></p>
                    <p className="labelImgInfo">사이즈 630 x 630px</p>
                    <label htmlFor='coffin_image'>
                      {coffin_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'coffin_image'                         
                    accept='image/gif, image/jpeg, image/png'
                    onChange={(event_data)=>{
                      if(event_data.target.files[0] !== undefined){
                        setCoffinImage(event_data.target.files[0]);
                        const reader = new FileReader();
                        reader.readAsDataURL(event_data.target.files[0]);
                        reader.onloadend = () => {
                          setCoffinImageURL(reader.result);
                        };
                      }
                    }} 
                  />
                  <img className="admin_newsImg" 
                    src={
                      coffinImageURL === '' ?
                      no_image : coffinImageURL
                    } 
                    alt="관 이미지"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{coffinImageError}</H.admin_errorBox>
              </div>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>상세 이미지(PC)<span> * </span></p>
                    <p className="labelImgInfo">사이즈 1200 x 1200px</p>
                    <label htmlFor='coffin_image_detail'>
                      {coffin_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'coffin_image_detail'                         
                    accept='image/gif, image/jpeg, image/png'
                    onChange={(event_data)=>{
                      if(event_data.target.files[0] !== undefined){
                        setCoffinImageDetail(event_data.target.files[0]);
                        const reader = new FileReader();
                        reader.readAsDataURL(event_data.target.files[0]);
                        reader.onloadend = () => {
                          setCoffinImageDetailURL(reader.result);
                        };
                      }
                    }} 
                  />
                  <img className="admin_estimateDetailPc" 
                    src={
                      coffinImageDetailURL === '' ?
                      no_image : coffinImageDetailURL
                    } 
                    alt="관 상세이미지(PC)"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{coffinImageDetailError}</H.admin_errorBox>
              </div>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>상세 이미지(모바일)<span> * </span></p>
                    <p className="labelImgInfo">사이즈 900 x 1500px</p>
                    <label htmlFor='coffin_image_detail_mobile'>
                      {coffin_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'coffin_image_detail_mobile'                         
                    accept='image/gif, image/jpeg, image/png'
                    onChange={(event_data)=>{
                      if(event_data.target.files[0] !== undefined){
                        setCoffinImageDetailMobile(event_data.target.files[0]);
                        const reader = new FileReader();
                        reader.readAsDataURL(event_data.target.files[0]);
                        reader.onloadend = () => {
                          setCoffinImageDetailMobileURL(reader.result);
                        };
                      }
                    }} 
                  />
                  <img className="admin_estimateDetailMobile" 
                    src={
                      coffinImageDetailMobileURL === '' ?
                      no_image : coffinImageDetailMobileURL
                    } 
                    alt="관 상세이미지(모바일)"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{coffinImageDetailMobileError}</H.admin_errorBox>
              </div>
            </H.admin_labelImg>

          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

//export default에 대한 내용은 src/Admin/Section/AdminHeader.js에 설명해났습니다.
export default AdminCoffinManagementAdd;
