export const privacyTerm = `
개인정보 수집동의 약관

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.
`;

export const marketingTerm = `
마케팅 이용동의 약관

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.
`;

export const serviceAgreementTerm = `
서비스 이용동의 약관

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.

1. 내용이 필요합니다.
`;