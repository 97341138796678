import React, {useState, useEffect} from 'react';
import AdminHeader from '../Section/AdminHeader';
import {
  insert_mourning_clothes,
  get_mourning_clothes_one,
  update_mourning_clothes,
} from "../../Axios";
import {validateNum} from "../../Validation";
import * as H from '../../Styles/StyleComponentHan';

export function AdminMourningClothesManagementAdd() {  
  const params = new URLSearchParams(window.location.search);
  const mourning_clothes_ID_param = params.get("mourning_clothes_ID");

  const [mourningClothesName, setMourningClothesName] = useState('');
  const [mourningClothesStatus, setMourningClothesStatus] = useState('1');
  const [mourningClothesPrice, setMourningClothesPrice] = useState('');

  const [mourningClothesNameError, setMourningClothesNameError] = useState('');
  const [mourningClothesPriceError, setMourningClothesPriceError] = useState('');


  const mourning_clothes_check = () =>{
    let continue_add = true;
    if(mourningClothesName === ''){setMourningClothesNameError('상복대여 명칭을 입력해 주세요.'); continue_add = false;}else{setMourningClothesNameError('');}
    if(mourningClothesPrice === ''){setMourningClothesPriceError('상복대여 가격을 입력해 주세요.'); continue_add = false;}else{setMourningClothesPriceError('');}

    if(continue_add){
      if(mourning_clothes_ID_param === null){
        insert_mourning_clothes(
          mourningClothesName,
          mourningClothesPrice.replace(/,/g, ""),
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/AdminMourningClothesManagement";
        });
      }else{
        update_mourning_clothes(
          mourning_clothes_ID_param,
          mourningClothesName,
          mourningClothesPrice.replace(/,/g, ""),
          mourningClothesStatus,
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/AdminMourningClothesManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  useEffect(() => {
    if(mourning_clothes_ID_param !== null){
      get_mourning_clothes_one(mourning_clothes_ID_param).then((result) => {
        setMourningClothesName(result[0].mourning_clothes_name);
        setMourningClothesStatus(result[0].mourning_clothes_status);
        setMourningClothesPrice(result[0].mourning_clothes_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      })
    }
  },[mourning_clothes_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>견적 메뉴 관리 - 상복대여 {mourning_clothes_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> 
              <H.admin_addBtn
                onClick={()=>{
                  mourning_clothes_check();
                }}
              >
                {mourning_clothes_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>

            <H.admin_labelBox>
              <label htmlFor='mourning_clothes_name'>상복대여 명칭<span> * </span></label>
              <input 
                type='text' 
                id = 'mourning_clothes_name' 
                maxLength={100} 
                onChange={(event_data)=>{
                  setMourningClothesName(event_data.target.value);
                }} 
                placeholder = "상복대여 명칭을 입력해 주세요."
                value={mourningClothesName}
              />
              <H.admin_errorBox className="marginLeft150">{mourningClothesNameError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='mourning_clothes_price'>상복대여 가격<span> * </span></label>
              <input 
                type='text' 
                id = 'mourning_clothes_price' 
                maxLength={10} 
                onChange={(e)=>{
                  if(!validateNum(e.target.value.replace(/,/g, ""))){
                    setMourningClothesPrice(
                      e.target.value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  }} 
                }
                placeholder = "상복대여 가격을 입력해 주세요."
                value={mourningClothesPrice}
              />
              <H.admin_errorBox className="marginLeft150">{mourningClothesPriceError}</H.admin_errorBox>
            </H.admin_labelBox>

            {mourning_clothes_ID_param !== null &&  
            <H.admin_labelBox>
              <label htmlFor='mourning_clothes_status'>사용 여부</label>
              <select
                value={mourningClothesStatus}
                onChange={(event_data)=>{
                  setMourningClothesStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
            </H.admin_labelBox>
            }            

          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

export default AdminMourningClothesManagementAdd;
