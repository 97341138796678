import React, {useState, useEffect} from 'react';
import AdminHeader from '../Section/AdminHeader';
import {
  insert_shroud,
  get_shroud_one,
  update_shroud,
  base_url_export,
} from "../../Axios";
import {validateNum} from "../../Validation";
import * as H from '../../Styles/StyleComponentHan';
import no_image from '../../Images/Etc/no_image.gif';

export function AdminShroudManagementAdd() {  
  const params = new URLSearchParams(window.location.search);
  const shroud_ID_param = params.get("shroud_ID");

  const [shroudName, setShroudName] = useState('');
  const [shroudStatus, setShroudStatus] = useState('1');
  const [shroudPrice, setShroudPrice] = useState('');
  const [shroudImage, setShroudImage] = useState('');
  const [shroudImageURL, setShroudImageURL] = useState('');
  const [shroudImageDetail, setShroudImageDetail] = useState('');
  const [shroudImageDetailURL, setShroudImageDetailURL] = useState('');
  const [shroudImageDetailMobile, setShroudImageDetailMobile] = useState('');
  const [shroudImageDetailMobileURL, setShroudImageDetailMobileURL] = useState('');
  const [shroudImageError, setShroudImageError] = useState('');
  const [shroudImageDetailError, setShroudImageDetailError] = useState('');
  const [shroudImageDetailMobileError, setShroudImageDetailMobileError] = useState('');
  const [shroudNameError, setShroudNameError] = useState('');
  const [shroudPriceError, setShroudPriceError] = useState('');

  const shroud_check = () =>{
    let continue_add = true;
    if(shroudName === ''){setShroudNameError('수의 명칭을 입력해 주세요.'); continue_add = false;}else{setShroudNameError('');}
    if(shroudPrice === ''){setShroudPriceError('수의 가격을 입력해 주세요.'); continue_add = false;}else{setShroudPriceError('');}
    if(shroudImageURL === ''){setShroudImageError('수의 이미지를 등록해 주세요.'); continue_add = false;}else{setShroudImageError('');}
    if(shroudImageDetailURL === ''){setShroudImageDetailError('수의 상세이미지(PC)를 등록해 주세요.'); continue_add = false;}else{setShroudImageDetailError('');}
    if(shroudImageDetailMobileURL === ''){setShroudImageDetailMobileError('수의 상세이미지(모바일)를 등록해 주세요.'); continue_add = false;}else{setShroudImageDetailMobileError('');}

    if(continue_add){
      if(shroud_ID_param === null){
        insert_shroud(
          shroudName,
          shroudPrice.replace(/,/g, ""),
          shroudImage,
          shroudImageDetail,
          shroudImageDetailMobile,
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/AdminShroudManagement";
        });
      }else{
        update_shroud(
          shroud_ID_param,
          shroudName,
          shroudPrice.replace(/,/g, ""),
          shroudImage,
          shroudImageDetail,
          shroudImageDetailMobile,
          shroudStatus,
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/AdminShroudManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  useEffect(() => {
    if(shroud_ID_param !== null){
      get_shroud_one(shroud_ID_param).then((result) => {
        setShroudName(result[0].shroud_name);
        setShroudStatus(result[0].shroud_status);
        setShroudPrice(result[0].shroud_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        setShroudImageURL(base_url_export + result[0].shroud_image);
        setShroudImageDetailURL(base_url_export + result[0].shroud_image_detail);
        setShroudImageDetailMobileURL(base_url_export + result[0].shroud_image_detail_mobile);
      })
    }
  },[shroud_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>견적 메뉴 관리 - 수의 {shroud_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> 
              <H.admin_addBtn
                onClick={()=>{
                  shroud_check();
                }}
              >
                {shroud_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>

            <H.admin_labelBox>
              <label htmlFor='shroud_name'>수의 명칭<span> * </span></label>
              <input 
                type='text' 
                id = 'shroud_name' 
                maxLength={10} 
                onChange={(event_data)=>{
                  setShroudName(event_data.target.value);
                }} 
                placeholder = "수의 명칭을 입력해 주세요.(최대 10자)"
                value={shroudName}
              />
              <H.admin_errorBox className="marginLeft150">{shroudNameError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='shroud_price'>수의 가격<span> * </span></label>
              <input 
                type='text' 
                id = 'shroud_price' 
                maxLength={10} 
                onChange={(e)=>{
                  if(!validateNum(e.target.value.replace(/,/g, ""))){
                    setShroudPrice(
                      e.target.value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  }} 
                }
                placeholder = "수의 가격을 입력해 주세요."
                value={shroudPrice}
              />
              <H.admin_errorBox className="marginLeft150">{shroudPriceError}</H.admin_errorBox>
            </H.admin_labelBox>

            {shroud_ID_param !== null &&  
            <H.admin_labelBox>
              <label htmlFor='shroud_status'>사용 여부</label>
              <select
                value={shroudStatus}
                onChange={(event_data)=>{
                  setShroudStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
            </H.admin_labelBox>
            }

            <H.admin_labelImg>
              <p className="labelImgTit">수의 이미지</p>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>이미지<span> * </span></p>
                    <p className="labelImgInfo">사이즈 630 x 630px</p>
                    <label htmlFor='shroud_image'>
                      {shroud_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'shroud_image'                         
                    accept='image/gif, image/jpeg, image/png'
                    onChange={(event_data)=>{
                      if(event_data.target.files[0] !== undefined){
                        setShroudImage(event_data.target.files[0]);
                        const reader = new FileReader();
                        reader.readAsDataURL(event_data.target.files[0]);
                        reader.onloadend = () => {
                          setShroudImageURL(reader.result);
                        };
                      }
                    }} 
                  />
                  <img className="admin_newsImg" 
                    src={
                      shroudImageURL === '' ?
                      no_image : shroudImageURL
                    } 
                    alt="수의 이미지"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{shroudImageError}</H.admin_errorBox>
              </div>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>상세 이미지(PC)<span> * </span></p>
                    <p className="labelImgInfo">사이즈 1200 x 1200px</p>
                    <label htmlFor='shroud_imag_detail'>
                      {shroud_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'shroud_imag_detail'                         
                    accept='image/gif, image/jpeg, image/png'
                    onChange={(event_data)=>{
                      if(event_data.target.files[0] !== undefined){
                        setShroudImageDetail(event_data.target.files[0]);
                        const reader = new FileReader();
                        reader.readAsDataURL(event_data.target.files[0]);
                        reader.onloadend = () => {
                          setShroudImageDetailURL(reader.result);
                        };
                      }
                    }} 
                  />
                  <img className="admin_estimateDetailPc" 
                    src={
                      shroudImageDetailURL === '' ?
                      no_image : shroudImageDetailURL
                    } 
                    alt="수의 상세이미지(PC)"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{shroudImageDetailError}</H.admin_errorBox>
              </div>
              <div>
                <div className="labelImgFirst">
                  <div className="labelImgSecond">
                    <p>상세 이미지(모바일)<span> * </span></p>
                    <p className="labelImgInfo">사이즈 900 x 1500px</p>
                    <label htmlFor='shroud_imag_detail_mobile'>
                      {shroud_ID_param === null ? ' 추가 ' : ' 변경 '}
                    </label>
                  </div>
                  <input 
                    className='display_none'
                    type='file' 
                    id = 'shroud_imag_detail_mobile'                         
                    accept='image/gif, image/jpeg, image/png'
                    onChange={(event_data)=>{
                      if(event_data.target.files[0] !== undefined){
                        setShroudImageDetailMobile(event_data.target.files[0]);
                        const reader = new FileReader();
                        reader.readAsDataURL(event_data.target.files[0]);
                        reader.onloadend = () => {
                          setShroudImageDetailMobileURL(reader.result);
                        };
                      }
                    }} 
                  />
                  <img className="admin_estimateDetailMobile" 
                    src={
                      shroudImageDetailMobileURL === '' ?
                      no_image : shroudImageDetailMobileURL
                    } 
                    alt="수의 상세이미지(모바일)"
                  />
                </div>
                <H.admin_errorBox className="marginLeft150">{shroudImageDetailMobileError}</H.admin_errorBox>
              </div>
            </H.admin_labelImg>
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

export default AdminShroudManagementAdd;
