import React, {useState, useEffect} from 'react';
import AdminHeader from '../Section/AdminHeader';
import {
  insert_vehicle,
  get_vehicle_one,
  update_vehicle,
} from "../../Axios";
import {validateNum} from "../../Validation";
import * as H from '../../Styles/StyleComponentHan';

export function AdminVehicleManagement() {  
  const params = new URLSearchParams(window.location.search);
  const vehicle_ID_param = params.get("vehicle_ID");

  const [vehicleName, setVehicleName] = useState('');
  const [vehicleExplanation, setVehicleExplanation] = useState('');
  const [vehicleStatus, setVehicleStatus] = useState('1');
  const [vehiclePrice, setVehiclePrice] = useState('');

  const [vehicleNameError, setVehicleNameError] = useState('');
  const [vehicleExplanationError, setVehicleExplanationError] = useState('');
  const [vehiclePriceError, setVehiclePriceError] = useState('');


  const vehicle_check = () =>{
    let continue_add = true;
    if(vehicleName === ''){setVehicleNameError('차량 명칭을 입력해 주세요.'); continue_add = false;}else{setVehicleNameError('');}
    if(vehicleExplanation === ''){setVehicleExplanationError('차량 설명을 입력해 주세요.'); continue_add = false;}else{setVehicleExplanationError('');}
    if(vehiclePrice === ''){setVehiclePriceError('차량 가격을 입력해 주세요.'); continue_add = false;}else{setVehiclePriceError('');}

    if(continue_add){
      if(vehicle_ID_param === null){
        insert_vehicle(
          vehicleName,
          vehicleExplanation,
          vehiclePrice.replace(/,/g, ""),
        ).then(()=>{
          alert('등록이 완료되었습니다.');
          window.location.href = "/AdminVehicleManagement";
        });
      }else{
        update_vehicle(
          vehicle_ID_param,
          vehicleName,
          vehicleExplanation,
          vehiclePrice.replace(/,/g, ""),
          vehicleStatus,
        ).then(()=>{
          alert('수정이 완료되었습니다.');
          window.location.href = "/AdminVehicleManagement";
        });
      }
    }else{
      alert('필수 입력값을 확인해주세요.');
    }
  }

  useEffect(() => {
    if(vehicle_ID_param !== null){
      get_vehicle_one(vehicle_ID_param).then((result) => {
        setVehicleName(result[0].vehicle_name);
        setVehicleExplanation(result[0].vehicle_explanation);
        setVehicleStatus(result[0].vehicle_status);
        setVehiclePrice(result[0].vehicle_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      })
    }
  },[vehicle_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>견적 메뉴 관리 - 차량 {vehicle_ID_param === null ? ' 등록 ' : ' 수정 '}</p>
            <div> 
              <H.admin_addBtn
                onClick={()=>{
                  vehicle_check();
                }}
              >
                {vehicle_ID_param === null ? ' 등록 ' : ' 수정 '}
              </H.admin_addBtn>
            </div>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>

            <H.admin_labelBox>
              <label htmlFor='vehicle_name'>차량 명칭<span> * </span></label>
              <input 
                type='text' 
                id = 'vehicle_name' 
                maxLength={100} 
                onChange={(event_data)=>{
                  setVehicleName(event_data.target.value);
                }} 
                placeholder = "차량 명칭을 입력해 주세요."
                value={vehicleName}
              />
              <H.admin_errorBox className="marginLeft150">{vehicleNameError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='vehicle_explanation'>차량 설명<span> * </span></label>
              <input 
                type='text' 
                id = 'vehicle_explanation' 
                maxLength={50} 
                onChange={(event_data)=>{
                  setVehicleExplanation(event_data.target.value);
                }} 
                placeholder = "차량 명칭을 입력해 주세요."
                value={vehicleExplanation}
              />
              <H.admin_errorBox className="marginLeft150">{vehicleExplanationError}</H.admin_errorBox>
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='vehicle_price'>차량 가격<span> * </span></label>
              <input 
                type='text' 
                id = 'vehicle_price' 
                maxLength={10} 
                onChange={(e)=>{
                  if(!validateNum(e.target.value.replace(/,/g, ""))){
                    setVehiclePrice(
                      e.target.value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  }} 
                }
                placeholder = "차량 가격을 입력해 주세요."
                value={vehiclePrice}
              />
              <H.admin_errorBox className="marginLeft150">{vehiclePriceError}</H.admin_errorBox>
            </H.admin_labelBox>

            {vehicle_ID_param !== null &&  
            <H.admin_labelBox>
              <label htmlFor='vehicle_status'>사용 여부</label>
              <select
                value={vehicleStatus}
                onChange={(event_data)=>{
                  setVehicleStatus(event_data.target.value);
                }}
              >
                <option value='1'>사용</option>
                <option value='2'>미사용</option>
              </select>
            </H.admin_labelBox>
            }            

          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

export default AdminVehicleManagement;
