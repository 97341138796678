import React, {useEffect, useState} from 'react';
import * as H from '../../Styles/StyleComponentHan';
import Header from '../Section/Header';
import Footer from '../Section/Footer';
import Spinner from '../../CommonSection/Spiner';
import {
  get_estimate_data_all,
  insert_estimate,
  get_session_data_homepage
} from "../../Axios";
import {
  validateNum,
  removeWhitespacePhone,
  validatePhone
} from "../../Validation";
import Modal from 'react-modal';
import btn_top from '../../Images/Etc/top_button.png';
import btn_minus from '../../Images/Etc/img_btn_minus.png';
import btn_plus from '../../Images/Etc/img_btn_plus.png';



function Estimate() {
  
  const [customerID, setCustomerID] = useState(0); 
  const [coffinData, setCoffinData] = useState(0); 
  const [shroudData, setShroudData] = useState(0);
  const [vehicleData, setVehicleData] = useState(0);
  const [helperNumberData, setHelperNumberData] = useState(0);
  const [helperTimeData, setHelperTimeData] = useState(0);
  const [helperPriceData, setHelperPriceData] = useState(0);
  const [mourningClothesManNumberData, setMourningClothesManNumberData] = useState(0);
  const [mourningClothesMaPriceData, setMourningClothesMaPriceData] = useState(0);
  const [mourningClothesWomanNumberData, setMourningClothesWomanNumberData] = useState(0);
  const [mourningClothesWomaPriceData, setMourningClothesWomaPriceData] = useState(0);
  const [urnData, setUrnData] = useState(0);
  const [estimateCustomerName, setEstimateCustomerName] = useState('');
  const [estimateCustomerPhoneNumber, setEstimateCustomerPhoneNumber] = useState('');
  const [coffinPriceData, setCoffinPriceData] = useState(0); 
  const [shroudPriceData, setShroudPriceData] = useState(0);
  const [vehiclePriceData, setVehiclePriceData] = useState(0);
  const [urnPriceData, setUrnPriceData] = useState(0);
  const [estimatePersonnelExpenses, setEstimatePersonnelExpenses] = useState(0);
  const [estimateOtherSupplies, setEstimateOtherSupplies] = useState(0);

  //Array Data
  const [coffinArrayData, setCoffinArrayData] = useState([]); 
  const [shroudArrayData, setShroudArrayData] = useState([]);
  const [vehicleArrayData, setVehicleArrayData] = useState([]);
  const [urnArrayData, setUrnArrayData] = useState([]);

  //Status Data
  const [spinnerLoadingStatus, setSpinnerLoadingStatus] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalID, setModalID] = useState(0);
  const [modalPrice, setModalPrice] = useState(0);
  const [modalDivision, setModalDivision] = useState(0);
  const [modalTitle, setModalTitle] = useState('');
  const [modalImgURL, setModalImgURL] = useState('');
  const [modalImgDetailURL, setModalImgDetailURL] = useState('');
  const [otheAmountData, setOtheAmountData] = useState([]);
  const [personButtonState, setPersonButtonState] = useState(false);
  const [otherButtonState, setOtherAmountData] = useState(false);


  const estimateAdd = () =>{
    if(estimateCustomerName === ''){alert('이름을 입력해 주세요.');}
    else if(estimateCustomerPhoneNumber === ''){alert('연락처를 입력해 주세요.');}
    else if(estimatePersonnelExpenses === 0){alert('인건비 선택은 필수입니다.');}
    else if(estimateOtherSupplies === 0){alert('기타용품 선택을 필수입니다.');}

    else{
      setSpinnerLoadingStatus(true);
      insert_estimate(
        customerID,
        coffinData,
        coffinPriceData,
        shroudData,
        shroudPriceData,
        vehicleData,
        vehiclePriceData,
        helperNumberData,
        helperTimeData,
        helperPriceData,
        mourningClothesManNumberData,
        mourningClothesMaPriceData,
        mourningClothesWomanNumberData,
        mourningClothesWomaPriceData,
        urnData,
        urnPriceData,
        estimateCustomerName,
        estimateCustomerPhoneNumber,
        estimatePersonnelExpenses,
        estimateOtherSupplies
      ).then(result_data=>{   
        alert('등록이 완료되었습니다. 확인 후 담당자가 연락을 진행할 예정입니다.');
        window.location.href = '/Estimate';
      })    
    }
  }

  const handleScroll = e => {
    if (!window.scrollY) return;
    // 현재 위치가 이미 최상단일 경우 return
  
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  
  };

  useEffect(() => {
    get_session_data_homepage().then((result)=>{
      if(result.customer_ID !== '' && result.customer_ID !== undefined){
        setCustomerID(result.customer_ID);
        setEstimateCustomerName(result.customer_name);
        setEstimateCustomerPhoneNumber(result.customer_phone_number);
      }
      get_estimate_data_all().then(result_data=>{
        setCoffinArrayData(result_data.coffin);
        setShroudArrayData(result_data.shroud);
        setVehicleArrayData(result_data.vehicle);
        setUrnArrayData(result_data.urn);
        setOtheAmountData(result_data.other_amount);
        setHelperTimeData(result_data.other_amount[0].other_amount_helper_time)
      });
    });
  },[]);

  
  return (
    <>
      <H.PositionContainer>
        <H.btn_top onClick={handleScroll}>
          <img src={btn_top} alt="위로"/>
        </H.btn_top>
      </H.PositionContainer>
      <Modal 
        isOpen={modalOpen} 
        onRequestClose={()=>{setModalOpen(!modalOpen)}} 
        ariaHideApp={false}
        className='modalWrap'
        /* style={H.modalStyle} */
      >
        <H.modalTitBox>
          <div className="modalTit">
            {modalTitle}
          </div>
        </H.modalTitBox>
        <H.modalImage>
          {/* 모바일용 이미지 */}
          <img className='estimate_modal_mo' src={modalImgDetailURL} alt="상세이미지(모바일)"/>
          {/* pc용 이미지 */}
          <img className='estimate_modal_pc' src={modalImgURL} alt="상세이미지(PC)"/>
        </H.modalImage>
        <H.estimate_modal_button>
            <button
              onClick={()=>{
                if(modalDivision === 1){
                  setCoffinData(0);
                  setCoffinPriceData(0);
                }else if(modalDivision === 2){
                  setShroudData(0);
                  setShroudPriceData(0);
                }else if(modalDivision === 3){
                  setUrnData(0);
                  setUrnPriceData(0);
                }
                setModalOpen(false)
              }}
            >
              선택안함
            </button>
            <button
              onClick={()=>{
                if(modalDivision === 1){
                  setCoffinData(modalID);
                  setCoffinPriceData(modalPrice);
                }else if(modalDivision === 2){
                  setShroudData(modalID);
                  setShroudPriceData(modalPrice);
                }else if(modalDivision === 3){
                  setUrnData(modalID);
                  setUrnPriceData(modalPrice);
                }
                setModalOpen(false)
              }}
            >
              선택
            </button>
          </H.estimate_modal_button>
      </Modal>

      <Header/>
      <Spinner status = {spinnerLoadingStatus}/>

      <H.estimate_mainWrap>
        <div className="estimate_mainInner">
          <h1 className="estimate_mainTit">견적내기</h1>


          {/* pc버전에서 왼쪽은 견적, 오른쪽은 견적 확인으로 할라면 섹션 2개로 나눠야함!! */}
          <div className="estimate_flexBox">
            <div className="estimate_leftBox">
              <div className="estimate_sectionBox">
                <h2 className="estimate_sectionTit">1 관 선택</h2>
                {coffinArrayData.length !== 0 && (
                <H.estimate_chooseBoxMain>
                  <div className="extimate_chooseBoxInfoBox">
                    <p>- 화장용관과 매장용관 중 원하는 관을 선택해 주세요</p>
                  </div>
                  <p className="extimate_chooseBoxTit">
                    1-1 화장용 
                    <span className="extimate_chooseBoxTitInfo"> (화장용 매장용 중 택 1)</span>
                  </p>

                  <div className="estimate_chooseBox"> {/* 선택 전체 박스 */}
                    {coffinArrayData.map((element, index) => 
                    element.coffin_division === 1 && 
                    <H.estimate_chooseImg key={index}>
                      <input 
                        id = {`coffin_${element.coffin_ID}`} 
                        name = 'coffin' 
                        type = 'radio'
                        checked={coffinData === element.coffin_ID} 
                        onChange={()=>{
                          setModalID(element.coffin_ID);
                          setModalPrice(element.coffin_price);
                          setModalDivision(1);
                          setModalTitle(element.coffin_name);
                          setModalImgURL(element.coffin_image_detail);
                          setModalImgDetailURL(element.coffin_image_detail_mobile);
                          setModalOpen(true);
                        }}
                      />
                      <label htmlFor={`coffin_${element.coffin_ID}`}>
                        <p className="chooseTit">{element.coffin_name}</p> {/* 선택 타이틀 */}
                        <p className="priceText">{String(element.coffin_price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                        <img 
                        src = {element.coffin_image} 
                        alt = {`관 사진${element.coffin_ID}`}
                        />
                      </label>
                    </H.estimate_chooseImg>
                    )}
                  </div>

                  <p className="extimate_chooseBoxTit">
                    1-2 매장용 
                    <span className="extimate_chooseBoxTitInfo"> (화장용 매장용 중 택 1)</span>
                  </p>
                  <div className='estimate_chooseBox'> {/* 선택 전체 박스 */}
                    {coffinArrayData.map((element, index) => 
                    element.coffin_division === 2 && 
                    <H.estimate_chooseImg key={index}> {/* 개별 1개 박스 */}
                      <input 
                        id = {`coffin_${element.coffin_ID}`} 
                        name = 'coffin' 
                        type = 'radio' 
                        checked={coffinData === element.coffin_ID} 
                        onChange={()=>{
                          setModalID(element.coffin_ID);
                          setModalPrice(element.coffin_price);
                          setModalDivision(1);
                          setModalTitle(element.coffin_name);
                          setModalImgURL(element.coffin_image_detail);
                          setModalImgDetailURL(element.coffin_image_detail_mobile);
                          setModalOpen(true);
                        }}
                      />
                      <label htmlFor={`coffin_${element.coffin_ID}`}>
                        <p className="chooseTit">{element.coffin_name}</p>
                        <p className="priceText">{String(element.coffin_price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                        <img 
                        src = {element.coffin_image} 
                        alt = {`관 사진${element.coffin_ID}`}
                        />
                      
                      </label>
                    </H.estimate_chooseImg>
                    )}
                  </div>
                </H.estimate_chooseBoxMain>
                )}
              </div>

              <div className="estimate_sectionBox">
                <h2 className="estimate_sectionTit">2 수의 선택</h2>
                {shroudArrayData.length !== 0 && (

                <H.estimate_chooseBoxMain>
                  <div className="extimate_chooseBoxInfoBox">
                    <p>- 원하는 수의를 선택해 주세요</p>
                  </div>
                  <div className="estimate_chooseBox">
                    {shroudArrayData.map((element, index) => 
                    <H.estimate_chooseImg key={index}>
                      <input 
                        id = {`shroud_${element.shroud_ID}`} 
                        name = 'shroud' 
                        type = 'radio'
                        checked={shroudData === element.shroud_ID} 
                        onChange={()=>{
                          setModalID(element.shroud_ID);
                          setModalPrice(element.shroud_price);
                          setModalDivision(2);
                          setModalTitle(element.shroud_name);
                          setModalImgURL(element.shroud_image_detail);
                          setModalImgDetailURL(element.shroud_image_detail_mobile);
                          setModalOpen(true);
                        }}
                      />
                      <label htmlFor={`shroud_${element.shroud_ID}`}>
                        <p className="chooseTit">{element.shroud_name}</p>
                        <p className="priceText">{String(element.shroud_price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                        <img 
                          src = {element.shroud_image} 
                          alt = {`수의 사진${element.shroud_ID}`}
                        />
                        
                      </label> 
                    </H.estimate_chooseImg>
                    )}
                  </div>
                </H.estimate_chooseBoxMain>
                )}
              </div>

              <div className="estimate_sectionBox">
                <h2 className="estimate_sectionTit">3 차량 선택</h2>
                {vehicleArrayData.length !== 0 && (

                <H.estimate_chooseBoxMain>
                  <div className="extimate_chooseBoxInfoBox">
                    <p>- 기본 왕복 기준 : 100Km (*100km 초과 시 1km x 1,500원 추가)</p>
                  </div>
                  {vehicleArrayData.map((element, index) => 
                  <div className="estimate_carBox" key={index}> 
                    <>
                    <H.estimate_chooseTextDetail >
                      <input 
                        id = {`vehicle_${index}`} 
                        name = 'vehicle' 
                        type = 'radio' 
                        onChange={()=>{
                          setVehicleData(element.vehicle_ID);
                          setVehiclePriceData(element.vehicle_price);
                        }}
                      />
                      <label htmlFor={`vehicle_${index}`}>
                        <span className="chooseTit">{element.vehicle_name}</span> 
                      </label>
                      <span className="subText">{element.vehicle_explanation}</span>
                    </H.estimate_chooseTextDetail>
                    </>
                  </div>
                  )}
                  <div className='marginBottom5p'/>
                </H.estimate_chooseBoxMain>
                )}
              </div>

              <div className="estimate_sectionBox">
                <h2 className="estimate_sectionTit">4 도우미 선택</h2>

                <H.estimate_chooseBoxMain>
                 <div className="extimate_chooseBoxInfoBox">
                  <p>
                    - 도우미 1인당 기본 {otheAmountData.length !== 0 && otheAmountData[0].other_amount_helper_time}시간 / 
                    시간당 {String(otheAmountData.length !== 0 && otheAmountData[0].other_amount_helper_price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원
                  </p>
                  </div>

                  <div className="estimate_chooseBox">
                    <H.estimate_chooseOneWrite>
                      <label htmlFor={`helper_number`}>
                        도우미 지원 인원
                      </label>
                      <div className='plus_minus_div'>
                        <button
                          onClick={()=>{
                            if(helperNumberData === 0 || helperNumberData === ''){
                              alert('0미만는 입력할 수 없습니다.');
                            }else{
                              setHelperNumberData((helperNumberData - 1) * 1)
                              setHelperPriceData((helperNumberData - 1) * helperTimeData *  otheAmountData[0].other_amount_helper_price)
                            }
                          }}
                        >
                          <img src={btn_minus} alt="뺴기"/>
                        </button>
                        <input 
                          id = 'helper_number' 
                          name = 'helper_number'
                          type = 'text' 
                          maxLength={3}
                          onChange={(e)=>{
                            if(!validateNum(e.target.value)){
                              setHelperNumberData(e.target.value); 
                              setHelperPriceData(e.target.value * helperTimeData *  otheAmountData[0].other_amount_helper_price)
                            }
                          }}
                          value={helperNumberData}
                        /> 
                        <button
                          onClick={()=>{
                            setHelperNumberData((helperNumberData + 1) * 1)
                            setHelperPriceData((helperNumberData + 1) * helperTimeData *  otheAmountData[0].other_amount_helper_price)
                          }}
                        >
                          <img src={btn_plus} alt="추가"/>
                        </button>
                      </div>
                    </H.estimate_chooseOneWrite>

                    {/* <H.estimate_chooseOneWrite>
                      <label htmlFor={`helper_time`}>
                        도우미 지원 시간
                      </label>
                      <div className='plus_minus_div'>
                        <button
                          onClick={()=>{
                            if(helperTimeData <= otheAmountData[0].other_amount_helper_time){
                              alert(`${otheAmountData[0].other_amount_helper_time}미만은 입력할 수 없습니다.`);
                            }else{
                              setHelperTimeData((helperTimeData - 1) * 1)
                              setHelperPriceData(helperNumberData * (helperTimeData - 1) * otheAmountData[0].other_amount_helper_price);
                            }
                          }}
                        >
                          -
                        </button>
                        <input 
                          id = 'helper_time'
                          name='helper_time'
                          placeholder = "지원 시간을 숫자로 입력해 주세요." 
                          type = 'text' 
                          maxLength={2}
                          onChange={(e)=>{
                            if(!validateNum(e.target.value)){
                              setHelperTimeData(e.target.value); 
                              setHelperPriceData(helperNumberData * e.target.value * otheAmountData[0].other_amount_helper_price);
                            }
                          }}
                          onBlur={(e)=>{
                            if(e.target.value < otheAmountData[0].other_amount_helper_time && e.target.value !== ''){
                              alert('최소 시간은 6시간입니다. 다시한번 입력해주세요.')
                              setHelperTimeData(''); 
                              setHelperPriceData(0);
                            }
                          }}
                          value={helperTimeData}
                        />
                        <button
                          onClick={()=>{
                            setHelperTimeData((helperTimeData + 1) * 1)
                            setHelperPriceData(helperNumberData * (helperTimeData + 1) * otheAmountData[0].other_amount_helper_price);
                          }}
                        >
                          +
                        </button>
                      </div>
                    </H.estimate_chooseOneWrite> */}
                  </div>
                </H.estimate_chooseBoxMain>
              </div>

              <div className="estimate_sectionBox">
                <h2 className="estimate_sectionTit">5 상복 선택</h2>
                <H.estimate_chooseBoxMain>
                  <div className="extimate_chooseBoxInfoBox">
                    <p>
                      - 성별에 따른 상복과 수량을 선택해주세요.(초등학생 동일)
                    </p>
                    <p>
                      - 남자 : 와이셔츠, 넥타이, 정장, 완장 포함 (1벌 당 {String(otheAmountData.length !== 0 && otheAmountData[0].other_amount_mourning_clothes_price_man).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원)
                    </p>
                    <p>
                      - 여자 : 개량 한복 (1벌 당 {String(otheAmountData.length !== 0 && otheAmountData[0].other_amount_mourning_clothes_price_woman).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원)
                    </p>
                  </div>

                  <div className="estimate_chooseBox">
                    <H.estimate_chooseOneWrite>
                      <label htmlFor={`mourning_clothes_man_number`}>
                        남자
                      </label>

                      <div className='plus_minus_div'>
                        <button
                          onClick={()=>{
                            if(mourningClothesManNumberData === 0 || mourningClothesManNumberData === ''){
                              alert('0미만는 입력할 수 없습니다.');
                            }else{
                              setMourningClothesManNumberData((mourningClothesManNumberData - 1) * 1)
                              setMourningClothesMaPriceData((mourningClothesManNumberData - 1) * otheAmountData[0].other_amount_mourning_clothes_price_man)
                            }
                          }}
                        >
                          <img src={btn_minus} alt="뺴기"/>
                        </button>
                        <input 
                          id = 'mourning_clothes_man_number' 
                          name = 'mourning_clothes_man_number'
                          type = 'text' 
                          maxLength={3}
                          onChange={(e)=>{
                            if(!validateNum(e.target.value)){
                              setMourningClothesManNumberData(e.target.value); 
                              setMourningClothesMaPriceData(e.target.value *  otheAmountData[0].other_amount_mourning_clothes_price_man);
                            }
                          }}
                          value={mourningClothesManNumberData}
                        /> 
                        <button
                          onClick={()=>{
                            setMourningClothesManNumberData((mourningClothesManNumberData + 1) * 1);
                            setMourningClothesMaPriceData((mourningClothesManNumberData + 1) *  otheAmountData[0].other_amount_mourning_clothes_price_man);
                          }}
                        >
                          <img src={btn_plus} alt="삭제"/>
                        </button>
                      </div>
                    </H.estimate_chooseOneWrite>

                    <H.estimate_chooseOneWrite>
                      <label htmlFor={`mourning_clothes_woman_number`}>
                        여자
                      </label>
                      <div className='plus_minus_div'>
                        <button
                          onClick={()=>{
                            if(mourningClothesWomanNumberData === 0 || mourningClothesWomanNumberData === ''){
                              alert('0미만는 입력할 수 없습니다.');
                            }else{
                              setMourningClothesWomanNumberData((mourningClothesWomanNumberData - 1) * 1);
                              setMourningClothesWomaPriceData((mourningClothesWomanNumberData - 1) * otheAmountData[0].other_amount_mourning_clothes_price_woman);
                            }
                          }}
                        >
                          <img src={btn_minus} alt="뺴기"/>
                        </button>
                        <input 
                          id = 'mourning_clothes_woman_number'
                          name='mourning_clothes_woman_number'
                          type = 'text' 
                          maxLength={3}
                          onChange={(e)=>{
                            if(!validateNum(e.target.value)){
                              setMourningClothesWomanNumberData(e.target.value); 
                              setMourningClothesWomaPriceData(e.target.value *  otheAmountData[0].other_amount_mourning_clothes_price_woman);
                            }
                          }}
                          value={mourningClothesWomanNumberData}
                        />
                        <button
                          onClick={()=>{
                            setMourningClothesWomanNumberData((mourningClothesWomanNumberData + 1) * 1);
                            setMourningClothesWomaPriceData((mourningClothesWomanNumberData + 1) *  otheAmountData[0].other_amount_mourning_clothes_price_woman);
                          }}
                        >
                          <img src={btn_plus} alt="추가"/>
                        </button>
                      </div>
                    </H.estimate_chooseOneWrite>
                  </div>
                </H.estimate_chooseBoxMain>

              </div>

              <div className="estimate_sectionBox">
                <h2 className="estimate_sectionTit">6 인건비 (필수)</h2>
                <H.estimate_chooseBoxMain>
                  <div className="extimate_chooseBoxInfoBox">
                    <p>
                      - 장례지도사 인건비 ({String(otheAmountData.length !== 0 && otheAmountData[0].other_amount_personnel_expenses).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원)
                    </p>
                    <p>
                      - 장례 컨설팅부터 장지 마무리까지 장례행사 진행 및 안내
                    </p>
                    <p>
                      - 입관비 ({String(otheAmountData.length !== 0 && otheAmountData[0].other_amount_admission_fee).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원)
                    </p>
                    <p>
                      - 2인 1조 고인 염습 및 입관
                    </p>
                  </div>
                  <button
                    className={personButtonState ? "estimate_chooseBoxButton button_active" : "estimate_chooseBoxButton"}
                    onClick={()=>{
                      if(personButtonState === false){
                        setEstimatePersonnelExpenses(
                          otheAmountData[0].other_amount_personnel_expenses + 
                          otheAmountData[0].other_amount_admission_fee
                        );
                        setPersonButtonState(true);
                      }else{
                        setEstimatePersonnelExpenses(0);
                        setPersonButtonState(false);
                      }
                    }}
                  >
                    선택 (필수)
                  </button>
                </H.estimate_chooseBoxMain>
              </div>

              <div className="estimate_sectionBox">
                <h2 className="estimate_sectionTit">7 기타용품 (필수)</h2>
                <H.estimate_chooseBoxMain>
                  <div className="extimate_chooseBoxInfoBox">
                    <p>
                      - 기타용품 ({String(otheAmountData.length !== 0 && otheAmountData[0].ohter_amount_other_supplies).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원)
                    </p>
                    <p>
                      - 입관시 필요한 관보, 명정, 결관바, 한지, 탈지면, 알콜 등등 기타 필수 용품
                    </p>
                  </div>
                  <button
                    className={otherButtonState ? "estimate_chooseBoxButton button_active" : "estimate_chooseBoxButton"}
                    onClick={()=>{
                      if(otherButtonState === false){
                        setEstimateOtherSupplies(otheAmountData[0].ohter_amount_other_supplies)
                        setOtherAmountData(true);
                      }else{
                        setEstimateOtherSupplies(0);
                        setOtherAmountData(false);
                      }
                    }}
                  >
                    선택 (필수)
                  </button>
                </H.estimate_chooseBoxMain>
              </div>


              <div className="estimate_sectionBox display_none">
                <h2 className="estimate_sectionTit">8 유골함 선택</h2>          
                {urnArrayData.length !== 0 && (

                <H.estimate_chooseBoxMain>
                  <p className="extimate_chooseBoxInfotxt">원하는 유골함을 선택해 주세요</p>
                  <p className="extimate_chooseBoxTit">
                    8-1 기능성 유골함
                    <span className="extimate_chooseBoxTitInfo"> (기능성 유골함, 일반함 중 택 1)</span>
                  </p>

                  <div className="estimate_chooseBox">
                    {urnArrayData.map((element, index) => 
                    element.urn_division === 1 && 
                    <H.estimate_chooseImg key={index}>
                      <input 
                        id = {`urn_${element.urn_ID}`} 
                        name = 'urn' 
                        type = 'radio' 
                        onChange={()=>{
                          setModalID(element.urn_ID);
                          setModalPrice(element.urn_price);
                          setModalDivision(3);
                          setModalTitle(element.urn_name);
                          setModalImgURL(element.urn_image_detail);
                          setModalImgDetailURL(element.urn_image_detail_mobile);
                          setModalOpen(true);
                        }}
                      />
                      <label htmlFor={`urn_${element.urn_ID}`}>
                        <p className="chooseTit">{element.urn_name}</p>
                        <p className="priceText">{String(element.urn_price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                        <img 
                          src = {element.urn_image} 
                          alt = {`유골함 사진${element.urn_ID}`}
                        />
                        
                      </label>
                    </H.estimate_chooseImg>
                    )}
                  </div>

                  <p className="extimate_chooseBoxTit">
                    8-2 일반함
                    <span className="extimate_chooseBoxTitInfo"> (기능성 유골함, 일반함 중 택 1)</span>
                  </p>

                  <div className='estimate_chooseBox'>
                    {urnArrayData.map((element, index) => 
                    element.urn_division === 2 && 
                    <H.estimate_chooseImg key={index}>
                      <input 
                        id = {`urn_${element.urn_ID}`} 
                        name = 'urn' 
                        type = 'radio' 
                        onChange={()=>{
                          setModalID(element.urn_ID);
                          setModalPrice(element.urn_price);
                          setModalDivision(3);
                          setModalOpen(true);
                          setModalImgURL(element.urn_image_detail);
                          setModalImgDetailURL(element.urn_image_detail_detail);
                        }}
                      />
                      <label htmlFor={`urn_${element.urn_ID}`}>
                        <p className="chooseTit">{element.urn_name}</p>
                        <p className="priceText">{String(element.urn_price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                        <img 
                          src = {element.urn_image} 
                          alt = {`유골함 사진${element.urn_ID}`}
                        />
                        
                      </label>
                    </H.estimate_chooseImg>
                    )}
                  </div>
                </H.estimate_chooseBoxMain>
                )}
              </div>
            </div>

            <div className="estimate_rightBox">
              <H.estimate_resultCheck>
                <p className="estimate_resultTit">견적 금액 확인</p>
                <ul>
                  <li>
                    <p>관</p>
                    <p>{coffinPriceData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                  </li>
                  <li>
                    <p>수의</p>
                    <p>{shroudPriceData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                  </li>
                  <li>
                    <p>차량</p>
                    <p>{vehiclePriceData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                  </li>
                  <li>
                    <p>도우미</p>
                    <p>{helperPriceData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                  </li>
                  <li>
                    <p>상복</p>
                    <p>{(mourningClothesMaPriceData+mourningClothesWomaPriceData).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                  </li>
                  <li>
                    <p>인건비</p>
                    <p>{String(estimatePersonnelExpenses).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                  </li>
                  <li>
                    <p>기타용품</p>
                    <p>{String(estimateOtherSupplies).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                  </li>
                  {/*
                  <li className = 'display_none'>
                    <p>유골함</p>
                    <p>{urnPriceData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원</p>
                  </li>
                  */}
                  <li className="estimate_resultSum">
                    <p>총합</p>
                    <p>
                      <span>
                      {
                        (
                          coffinPriceData + 
                          shroudPriceData + 
                          vehiclePriceData + 
                          helperPriceData + 
                          urnPriceData +
                          mourningClothesMaPriceData +
                          mourningClothesWomaPriceData +
                          estimatePersonnelExpenses +
                          estimateOtherSupplies
                        ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      </span>원
                    </p>
                  </li>
                </ul>
              </H.estimate_resultCheck>

              <H.estimate_resultSend>
                <p className="estimate_resultTit">견적 보내기</p>
                <div>
                  <p className="estimate_resultSendWrite">
                    <label htmlFor={`estimate_customer_name`}>이름</label>
                    <input 
                      id = 'estimate_customer_name' 
                      name = 'estimate_customer_name' 
                      placeholder = "이름을 입력해 주세요." 
                      type = 'text' 
                      maxLength={10}
                      onChange={(e)=>{
                        setEstimateCustomerName(e.target.value);
                      }}
                      value={estimateCustomerName}
                    />
                  </p>

                  <p className="estimate_resultSendWrite">
                    <label htmlFor={`helper_number`}>연락처</label>
                    <input 
                      id = 'helper_number' 
                      name = 'helper_number' 
                      placeholder = "휴대폰 형식으로 번호를 입력해 주세요." 
                      type = 'text' 
                      maxLength={13}
                      onChange={(e)=>{
                        setEstimateCustomerPhoneNumber(removeWhitespacePhone(e.target.value))
                      }} 
                      onBlur={(e)=>{
                        if(!validatePhone(e.target.value)){
                          alert('연락처는 휴대폰 형식으로 입력해 주세요.')
                          setEstimateCustomerPhoneNumber(''); 
                        }
                      }}
                      value={estimateCustomerPhoneNumber}
                    />
                  </p>
                
                  <button className = "resultSendBtn" onClick={()=>{estimateAdd()}}>보내기</button>
                </div>
              </H.estimate_resultSend>
            </div>
            
          </div>
        </div>

        <H.estimate_fix>
          <p className="estimate_resultSum">총합</p>
          <p className="estimate_resultSum">
            <span>
            {
              (
                coffinPriceData + 
                shroudPriceData + 
                vehiclePriceData + 
                helperPriceData + 
                urnPriceData +
                mourningClothesMaPriceData +
                mourningClothesWomaPriceData +
                estimatePersonnelExpenses +
                estimateOtherSupplies
              ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
              </span>원
          </p>
        </H.estimate_fix>


      </H.estimate_mainWrap>

      
      <Footer/>
    </>
  );
}

export default Estimate;
