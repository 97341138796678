import React from 'react';
import {
    get_naver_token
} from "../../Axios";

function NaverLogin() {

    const code = new URL(window.location.href).searchParams.get("code");
    
    get_naver_token(code).then((check_data)=>{
        if(check_data.customer_join_level === 1){
            window.location.href=`/Join?customer_ID=${check_data.customer_ID}`
        }else{
            alert('로그인이 완료되었습니다.');
            window.location.href=`/Estimate`
        }
    });

  return (
    <>
    </>
  );
}

export default NaverLogin;
