import styled from "styled-components";

//반응형에 맞게 이미지를 다른거를 불러온다.
export const SlideImg = styled.img`
    background-image:url(${props => props.rolling_banner_image});
    width: 100vw;
    height: 100vh;
    @media screen and (max-width: 700px) {
      background-image:url(${props => props.rolling_banner_image_mobile});
      width: 100vw;
      height: 100vh;
    }
`;

//spinner_section
export const SpinnerBackgroundDiv =  styled.div`
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const SpinnerDiv =  styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
