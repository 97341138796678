import React, {useState, useEffect} from 'react';
import AdminHeader from '../Section/AdminHeader';
import {
  get_customer_one_admin
} from "../../Axios";
import * as H from '../../Styles/StyleComponentHan';

export function AdminCustomerManagementAdd() {  
  const params = new URLSearchParams(window.location.search);
  const customer_ID_param = params.get("customer_ID");

  const [customerArray, setCustomerArray] = useState([]);

  useEffect(() => {
    if(customer_ID_param !== null){
      get_customer_one_admin(customer_ID_param).then((result) => {
        setCustomerArray(result[0]);
      })
    }
  },[customer_ID_param]);

  return (
    <H.admin_MainWrap> 
      <AdminHeader/>
      <H.admin_rightWrap>
        <H.admin_rightTitBox>
          <H.admin_rightTitInner>
            <p>회원 관리 - 회원 상세보기</p>
          </H.admin_rightTitInner>
        </H.admin_rightTitBox>
      
        <H.admin_rightInfoBox>
          <H.admin_rightInfoInner>

            <H.admin_labelBox>
              <label htmlFor='customer_division'>가입구분</label>
              <input 
                type='text' 
                id = 'customer_division' 
                readOnly
                value={customerArray.customer_division_name || ""}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='customer_login_ID'>로그인 ID</label>
              <input 
                type='text' 
                id = 'customer_login_ID' 
                readOnly
                value={customerArray.customer_login_ID || ""}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='customer_name'>고객명</label>
              <input 
                type='text' 
                id = 'customer_name' 
                readOnly
                value={customerArray.customer_name || ""}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='customer_phone_number'>고객 전화번호</label>
              <input 
                type='text' 
                id = 'customer_phone_number' 
                readOnly
                value={customerArray.customer_phone_number || ""}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='customer_email'>고객 이메일</label>
              <input 
                type='text' 
                id = 'customer_email' 
                readOnly
                value={customerArray.customer_email || ""}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='customer_gender'>성별</label>
              <input 
                type='text' 
                id = 'customer_gender' 
                readOnly
                value={customerArray.customer_gender_name || ""}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='customer_zip_code'>우편번호</label>
              <input 
                type='text' 
                id = 'customer_zip_code' 
                readOnly
                value={customerArray.customer_zip_code || ""}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='customer_road_address'>도로명 주소</label>
              <input 
                type='text' 
                id = 'customer_road_address' 
                readOnly
                value={customerArray.customer_road_address || ""}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='customer_detail_address'>상세 주소</label>
              <input 
                type='text' 
                id = 'customer_detail_address' 
                readOnly
                value={customerArray.customer_detail_address || ""}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='customer_activate'>활성화</label>
              <input 
                type='text' 
                id = 'customer_activate' 
                readOnly
                value={customerArray.customer_activate || ""}
              />
            </H.admin_labelBox>

            <H.admin_labelBox>
              <label htmlFor='customer_marketing_status'>마케팅 동의여부</label>
              <input 
                type='text' 
                id = 'customer_marketing_status' 
                readOnly
                value={customerArray.customer_marketing_status_name || ""}
              />
            </H.admin_labelBox>
  
          </H.admin_rightInfoInner>
        </H.admin_rightInfoBox>
      </H.admin_rightWrap>
    </H.admin_MainWrap> 
  );
}

export default AdminCustomerManagementAdd;
